export default {
	login: {
		input: {
			username: 'Kullanıcı Adı',
			password: 'Parola',
			email: 'E-mail Adresi',
			newpassword: 'Yeni parolanız',
			newpassword2: 'Yeni parolanızı tekrar giriniz'
		},
		buttonlogin: 'GİRİŞ YAP',
		rememberme: 'Beni Hatırla',
		forgotpassword: 'Parolamı Unuttum',
		sendlink: 'Sıfırlama Maili Al',
		forgotmessage: 'Sıfırlama linki mail adresinize gönderildi.',
		expired_token: 'Sıfırlama linki süresi doldu.',
		approve: 'Onayla',
		changepasswordmessage: 'Parola değiştirildi.'
	},
	navigation: {
		home: 'ANA SAYFA',
		dealer: 'BAYİLER',
		dealer_list: 'TÜM BAYİLER',
		order: 'MÜŞTERİLER',
		order_list: 'TÜM MÜŞTERİLER',
		order_detail: 'MÜŞTERİ DETAYI',
		bill: 'ÖDEMELER',
		bill_list: 'TÜM ÖDEMELER',
		bill_detail: 'ÖDEME DETAYI',
		distributor: 'DİSTRİBÜTÖRLER',
		distributor_list: 'TÜM DİSTRİBÜTÖRLER',
		distributor_detail: 'DİSTRİBÜTÖR DETAYI',
		user: 'KULLANICILAR',
		user_list: 'TÜM KULLANICILAR',
		user_detail: 'KULLANICI DETAYI',
		/* distributor: 'DİSTRİBÜTÖRLER',
		demo_requests: 'DEMO TALEPLERİ',
		license_requests: 'LİSANS TALEPLERİ',
		current_information: 'CARİ BİLGİLER',
		products: 'ÜRÜNLER',
		settings: 'AYARLAR',*/
		profile: 'Profil',
		distributor_profile: 'Distribütör',
		logout: 'Çıkış'
	},
	home: {
		title: {
			activite_graffic: 'AKTİVİTE GRAFİĞİ',
			license_status: 'LİSANSLAR',
			license_type: 'LİSANS DAĞILIMI',
			dealer: 'BAYİ DAĞILIMI',
			recent_histories: 'SON İŞLEMLER'
		},
		tab: {
			bill: 'SATIŞ',
			license: 'LİSANS'
		},
		license_chart: {
			expired: 'Lisansı Bitmiş Müşteriler',
			expiring: 'Lisansı Yakında Bitecek Müşteriler',
			pending: 'Onay Bekleyen Müşteriler',
			cancelled: 'İptal Edilen Müşteriler',
			active: 'Aktif Müşteriler',
			deactive: 'Pasif Müşteriler',
			total: 'Toplam Müşteriler'
		}
	},
	dealer: {
		title: {
			list: 'BAYİLER',
			add: 'BAYİ EKLEME EKRANI',
			edit: 'BAYİ DÜZENLEME ALANI'
		},
		action: {
			savemessage: 'Bayi kaydedildi.',
			savemultiupdatemessage: 'Seçilen bayiler için yapılan değişiklikler kaydedildi.',
			statuschanged: 'Bayi durumu güncellendi.',
			listfilter: 'Firma veya yetkili ismi ara',
			adddealer: 'Bayi Ekle',
			movedealerheader: 'Bayi Taşı',
			movedealers: 'Seçili Bayileri Taşı',
			moveddealers: 'Taşınacak Bayiler'
		},
		button: {
			customer_list: 'Müşteri Listesi',
			distributor: 'Distribütörü'
		},
		error: {
			unexpected_error: 'Beklenmedik bir hata ile karşılaşıldı!',
			validation: 'Doğrulama başarısız!',
			mustmoveorders: 'Siperişler başka bir bayiye taşınmalı!',
			mustselectdealer: 'En az bir bayi seçilmelidir.',
			delete_error: 'Seçilen bayi silinemedi. Lütfen bayiye bağlı müşterileri farklı bayilere taşıyınız.'
		}
	},
	order: {
		title: {
			list: 'MÜŞTERİLER',
			form: 'MÜŞTERİ BİLGİLERİ',
			package: 'PAKET BİLGİLERİ',
			payment: 'ÖDEME BİLGİLERİ',
			other_package: 'EK PAKETLER'
		},
		action: {
			license_text: 'Paket detaylarında yer alan bilgiler yıllık planlamaya göre biçimlendirilmiştir.',
			payment_type: 'Ödeme Tipi',
			payment_balance: 'ÖN ÖDEME',
			payment_invoice: 'FATURALI ÖDEME',
			payment_period: 'Ödeme Periyotu',
			payment_period_month: 'Aylık',
			payment_period_oneyear: 'Yıllık',
			payment_period_twoyear: '2 Yıllık',
			payment_period_threeyear: '3 Yıllık',
			payment_period_onetime: 'Ek/Aylık',
			payment_period_demo: 'Demo',
			payment_period_15day: '15 Gün',
			payment_period_commitment_12_month: '12 Ay(Taahhüt)',
			payment_period_n_a: 'N/A',
			payment_renewal: 'Otomatik Yenileme',
			notify_distributor: 'Kullanıcı Bilgilerini Distribütöre İlet',
			notify_dealer: 'Kullanıcı Bilgilerini Bayiye İlet',
			notify_order: 'Kullanıcı Bilgilerini Müşteriye İlet',
			savemessage: 'Yeni Müşteri Oluşturma Talebiniz Başarıyla Alınmıştır.',
			saveordermessage: 'Değişiklikler başarıyla kaydedildi.',
			acceptmessage: 'Müşteri Başarıyla Onaylandı',
			rejectmessage: 'Müşteri Talebi Reddedildi',
			addorder: 'MÜŞTERİ OLUŞTUR',
			license_expdate: 'LİSANS SÜRESİ',
			email_info: 'Bu alan aynı zamanda kullanıcının panele girerken kullanacağı kullanıcı adıdır.',
			commitment_period: 'Taahhüt Süresi',
			month_12: '12 Ay',
			open_bill_commitment: 'Taahhütlü ürününüzün gelecek dönem faturasına eklenecektir.'
		},
		modal: {
			title: 'YENİ MÜŞTERİ TALEBİ',
			text_top: 'Müşteri talebinizin özeti aşağıda bulunmaktadır.',
			text_bottom: 'Onay Vermenizin ardından kısa süre içinde sistem yöneticisi müşteri talebinizi onaylayacaktır. Müşteri onayladığında yukarıda özeti ödeme planını <b>kabul etmiş</b> olursunuz.<br/>Talebinizin iletilmesi için <b>Kaydet</b> butonuna tıklayınız.'
		},
		sidebar: {
			license: 'LİSANS SEÇİMİ',
			selected_license: 'SEÇİLİ PAKET'
		},
		license_list: {
			user: 'KULLANICI',
			device: 'CİHAZ SAYISI',
			session: 'OTURUM SAYISI',
			sms: 'SMS',
			pms: 'PMS'
		},
		error: {
			insufficient_balance: 'Yetersiz Bakiye! Lütfen Yeterli Bakiyeniz Olduğuna Emin Olunuz.',
			license_demo_error: 'Demo lisans hakkınız dolmuştur.',
			unexpected_error: 'Beklenmedik bir hata ile karşılaşıldı!'
		},
		license: {
			demo: 'Deneme Paketi',
			silver: 'Silver Paket',
			gold: 'Gold Paket',
			platinum: 'Platinum Paket',
			titanium: 'Titanium Paket',
			diamond: 'Diamond Paket',
			none: 'N/A'
		},
		license_text: {
			demo: '250 Kullanıcı + PMS + Sosyal Paket',
			silver: '250 Kullanıcı + PMS + Sosyal Paket',
			gold: '250 Kullanıcı + PMS + Sosyal Paket',
			platinum: '250 Kullanıcı + PMS + Sosyal Paket',
			titanium: '250 Kullanıcı + PMS + Sosyal Paket',
			diamond: '250 Kullanıcı + PMS + Sosyal Paket',
			none: 'N/A'
		},
		commitment: {
			purchase: 'TAKSİT',
			amount: 'TUTAR',
			period: 'DÖNEM',
			commitment_no: 'TAAHHÜT NO',
			option_status: {
				now: 'Güncel Fatura',
				future: 'Gelecek Dönem Fatura',
				notpaid: 'Ödenmemiş Fatura',
				past: 'Ödenmiş Fatura'
			}
		}
	},
	distributor: {
		title: {
			list: 'DİSTRİBÜTÖRLER',
			add: 'DİSTRİBÜTÖR EKLEME EKRANI',
			edit: 'DİSTRİBÜTÖR DÜZENLEME ALANI',
			other_form: 'DİSTRİBÜTÖR BAKİYE&İNDİRİM'
		},
		action: {
			adddistributor: 'DİSTRİBÜTÖR OLUŞTUR',
			savemessage: 'Distribütör kaydedildi.',
			deletemessage: 'Distribütör Silindi.',
			undefineddistributor: 'Distribütör Bulunamadı!'
		},
		license_list: {
			user: 'KULLANICI',
			device: 'CİHAZ SAYISI',
			session: 'OTURUM SAYISI',
			sms: 'SMS',
			pms: 'PMS'
		},
		error: {
			insufficient_balance: 'Yetersiz Bakiye! Lütfen Yeterli Bakiyeniz Olduğuna Emin Olunuz.',
			mustmovedealers: 'Bayiler başka bir distribütöre taşınmalıdır.'
		}
	},
	bill: {
		list: {
			title: 'ÖDEMELER'
		},
		invoice_text: 'SİPARİŞ NO / FATURA NO',
		payment_date: 'ÖDEME TARİHİ'
	},
	payment: {
		balance: 'ÖN ÖDEME BAKİYE',
		invoice: 'FATURA',
		total: 'TOPLAM',
		none: 'SEÇİLMEDİ'
	},
	order_status: {
		pending: 'ONAY BEKLİYOR',
		cancelled: 'İPTAL EDİLDİ',
		active: 'AKTİF',
		deactive: 'PASİF',
		modify: 'DEĞİŞİKLİK TALEBİ',
		expired: 'SÜRESİ BİTMİŞ'
	},
	order_handle_status: {
		st_0: 'ONAY BEKLİYOR',
		st_1: 'AKTİF',
		st_2: 'PASİF',
		st_3: 'SÜRESİ BİTMİŞ',
		st_4: 'İPTAL EDİLDİ',
		st_5: 'GELECEK DÖNEM/TAAHHÜT',
		st_6: 'HAZIRLANIYOR'
	},
	commitment_status: {
		0: 'ONAY BEKLİYOR',
		1: 'AKTİF',
		2: 'PASİF'
	},
	commitment_handle_status: {
		0: 'ONAY BEKLİYOR',
		1: 'AKTİF',
		2: 'PASİF'
	},
	bill_status: {
		pending: 'ONAY BEKLİYOR',
		approved: 'ONAYLANDI',
		unapproved: 'REDDEDİLDİ',
		payment_expected: 'ÖDEME BEKLENİYOR',
		payment_refunded: 'ÖDEME İADE EDİLDİ',
		cancelled: 'İPTAL EDİLDİ',
		completed: 'TAMAMLANDI',
		commitment: 'GELECEK DÖNEM/TAAHHÜT'
	},
	history_status: {
		order_request: 'YENİ MÜŞTERİ TALEBİ',
		order_modify_request: 'YENİ DEĞİŞİKLİK TALEBİ',
		order_accept: 'MÜŞTERİ TALEBİ ONAYLANDI',
		order_modify_accept: 'DEĞİŞİKLİK TALEBİ ONAYLANDI',
		order_reject: 'MÜŞTERİ TALEBİ REDDEDİLDİ',
		order_modify_reject: 'DEĞİŞİKLİK TALEBİ REDDEDİLDİ',
		bill_approved: 'ÖDEME ONAYLANDI',
		bill_unapproved: 'ÖDEME REDDEDİLDİ',
		profile_updated: 'MÜŞTERİ BİLGİLERİ GÜNCELLENDİ',
		bill_created: 'ÖDEME OLUŞTURULDU',
		bill_pending: 'ÖDEME ONAYI BEKLENİYOR',
		bill_payment_expected: 'ÖDEME/TAHSİLAT BEKLENİYOR',
		bill_payment_refunded: 'ÖDEME İADE EDİLDİ',
		bill_cancelled: 'İPTAL EDİLDİ',
		bill_completed: 'ÖDEME TAMAMLANDI',
		bill_payment_notify: 'ÖDEME BİLDİRİMİ YAPILDI',
		order_cancelled: 'MÜŞTERİ İPTAL EDİLDİ'
	},
	total: {
		sub_total: 'Ara Toplam',
		discount_distributor: 'İndirim',
		tax_vat: 'KDV',
		total: 'Toplam',
		renewal_total: 'Yenileme',
		installment: 'Gelecek Ödeme'
	},
	user: {
		title: {
			list: 'KULLANICILAR',
			add: 'KULLANICI EKLEME EKRANI',
			edit: 'KULLANICI DÜZENLEME EKRANI'
		},
		action: {
			adduser: 'KULLANICI EKLE'
		}
	},
	userprofile: {
		title: 'Profili Düzenle'
	},
	alert: {
		danger: 'Hata!',
		info: 'Bilgi!',
		success: 'Başarılı',
		warning: 'Uyarı'
	},
	error: {
		error_maxlength: 'Karakter sayısını aştınız!',
		error_minlength: 'Yetersiz Karakter Sayısı',
		error_required: 'Doldurulması Zorunlu Alan',
		error_invalidemail: 'Geçersiz E-mail Adresi',
		error_weakpassword: 'Zayıf Parola',
		error_isnumber: 'Sadece sayı giriniz!',
		error_minnumber: 'Bu alan %s sayıları arasında olmalı. Daha büyük bir sayı giriniz.',
		error_maxnumber: 'Bu alan %s sayıları arasında olmalı. Daha küçük bir sayı giriniz.',
		error_match: 'Bu alan eşleşmemektedir.',
		error_invalidurl: 'Geçersiz Url',
		error_invalidalpha: 'Geçersiz Alfabetik Karakter',
		error_invalidmac: 'Geçersiz MAC adresi',
		error_invalidip: 'Geçersiz IP adresi',
		error_invaliddomain: 'Geçersiz domain adresi',
		error_selected: 'Seçilmesi Zorunlu Alan',
		invalid_user: 'Kullanıcı bulunamadı.',
		invalid_username_password: 'Kullanıcı adı veya parola bulunamadı!',
		invalid_password: 'Parola hatalı.',
		invalid_already_email: 'Bu adres ile daha önce kullanıcı oluşturulmuştur!',
		error_invalidphone: 'Geçersiz Telefon numarası',
		token_expired: 'İstek zaman aşımına uğradı.',
		license_expired: 'Lisansınızın süresi sona ermiştir.',
		undefined_url: 'Geçersiz Bağlantı',
		verify_password: 'Girdiğiniz şifre aynı olmalıdır.',
		verify_repassword: 'Lütfen şifrenizi doğrulayın.',
		invalid_status: 'Geçersiz durum',
		error_multi_select: 'En az bir öğe seçilmesi gereklidir.'
	},
	general: {
		selectall: 'Tümünü Seç',
		select: 'Seç',
		on: 'Açık',
		off: 'Kapalı',
		accept: 'Onayla',
		reject: 'Reddet',
		active: 'Aktif',
		deactive: 'Pasif',
		cancel: 'İptal',
		status_1: 'Aktif',
		status_0: 'Pasif',
		successful: 'Başarılı',
		unsuccessful: 'Başarısız',
		approved: 'Onaylandı',
		disapproved: 'Onaylanmadı',
		deleteconfirm: 'Silmek istediğinize emin misiniz?',
		add: 'Ekle',
		save: 'Kaydet',
		edit: 'Düzenle',
		detail: 'Detay',
		delete: 'Sil',
		upgrade: 'Yükselt',
		next: 'Sonraki',
		prev: 'Önceki',
		first: 'İlk',
		last: 'Son',
		all: 'Tümü',
		apply_filter: 'Filtrele',
		back: 'Geri Dön',
		selectplaceholder: 'Seçiniz...',
		choosefile: 'Dosya Seçiniz...',
		male: 'ERKEK',
		female: 'KADIN',
		other: 'DİĞER',
		unknown: 'BİLİNMEYEN',
		none: 'DEĞER YOK',
		status: 'Durum',
		pagination: {
			info: 'Toplam %s kayıttan %s-%s arası kayıtlar görüntüleniyor.',
			nodata: 'Kayıt bulunmamaktadır.'
		},
		advanced_filter: 'Gelişmiş Filtre',
		filterinput: 'Arama...',
		sub_total: 'Ara Toplam',
		total: 'Toplam',
		discount: 'İndirim',
		tax_fee: 'KDV',
		load_more: 'Daha Fazla...',
		move_selected: 'Seçilenleri Taşı',
		update_selected: 'Seçilenleri Güncelle',
		selected_count: 'Toplam Seçili Öğe Sayısı',
		multi_update: 'Toplu Güncelleme',
		order_login: 'Giriş Yap'
	},
	form: {
		distributor: 'Distribütör',
		company: 'Şirket',
		title: 'Yetkili Ünvanı',
		first_name: 'Yetkili Adı',
		last_name: 'Yetkili Soyadı',
		full_name: 'Yetkili',
		email: 'E-Mail',
		phone: 'Telefon',
		mobile_phone: 'GSM',
		address: 'Adres',
		country: 'Ülke',
		city: 'Şehir',
		website: 'Web Sitesi',
		dealer: 'Bayi',
		username: 'Kullanıcı Adı',
		password: 'Şifre',
		repassword: 'Şifre (Tekrar)',
		discount: 'İndirim',
		expiration_date: 'Geçerlilik Tarihi',
		create_date: 'Oluşturulma Tarihi',
		invoice: 'Fatura No',
		payment_type: 'Ödeme Tipi',
		balance: 'Bakiye',
		renewal: 'Otomatik Yenileme',
		reset_password: 'Şifre Sıfırla',
		status: 'Durum',
		invoice_cutting_date: 'Fatura Kesim Tarihi',
		payment_date: 'Ödeme Tarihi',
		detail: 'Detay',
		option: {
			license: 'Lisans Tipi',
			license_demo: 'Demo',
			license_silver: 'Silver',
			license_gold: 'Gold',
			license_platinum: 'Platinum',
			license_titanium: 'Titanium',
			license_diamond: 'Diamond',
			sms: 'İlave SMS',
			sms_100_sms: '100',
			sms_500_sms: '500',
			sms_1000_sms: '1.000',
			sms_5000_sms: '5.000',
			sms_10000_sms: '10.000',
			sms_15000_sms: '15.000',
			session: 'İlave Kredi',
			session_10000_session: '10.000',
			session_20000_session: '20.000',
			session_50000_session: '50.000'
		}
	},
	list: {
		status: 'DURUM',
		order: 'MÜŞTERİ',
		payment_type: 'ÖDEME TÜRÜ',
		invoice_no: 'FATURA NO',
		total_price: 'TOPLAM TUTAR',
		update_time: 'GÜNCELLEME TARİHİ',
		create_time: 'OLUŞTURMA TARİHİ',
		action: 'İŞLEM',
		option_name: 'ÜRÜN ÖZELLİĞİ',
		option_value: 'ÜRÜN DEĞERİ',
		period: 'PERİYOT',
		price: 'BİRİM TUTAR',
		company_name: 'FİRMA ADI',
		dealer: 'BAYİ',
		distributor: 'DİSTRİBUTOR',
		product: 'KULLANILAN ÜRÜN',
		expiration_time: 'LİSANS GEÇERLİLİK TARİHİ',
		first_name: 'ADI',
		title: 'ÜNVANI',
		last_name: 'SOYADI',
		email: 'E-MAIL',
		current_history: 'ÖDEME HAREKETLERİ',
		order_history: 'SİPARİŞ HAREKETLERİ',
		order_option: 'SİPARİŞ ÖZELLİKLERİ',
		bill_option: 'ÜRÜN ÖZELLİKLERİ',
		bill_history: 'ÖDEME HAREKETLERİ',
		bill_action: 'ÖDEME İŞLEMLERİ',
		order_action: 'SİPARİŞ İŞLEMLERİ',
		bill_payment_notify: 'ÖDEME BİLDİRİMİ',
		order_commitment: 'TAAHHÜT'
	},
	notification: {
		title: 'Bildirimler',
		warning: {
			left_sms: 'SMS hakkınız eşik değerine ulaştı.',
			left_session: 'Kullanım süresi hakkınız eşik değerine ulaştı.'
		},
		danger: {
			left_sms: 'SMS hakkınız kritik seviyede.',
			left_session: 'Kullanım süresi hakkınız kritik seviyede.'
		}
	},
	date: {
		month: {
			_01: 'Ocak',
			_02: 'Şubat',
			_03: 'Mart',
			_04: 'Nisan',
			_05: 'Mayıs',
			_06: 'Haziran',
			_07: 'Temmuz',
			_08: 'Ağustos',
			_09: 'Eylül',
			_10: 'Ekim',
			_11: 'Kasım',
			_12: 'Aralık'
		},
		days: '%s. Gün',
		weeks: '%s. Hafta',
		daily: 'Günlük',
		weekly: 'Haftalık',
		monthly: 'Aylık',
		yearly: 'Yıllık',
		current_week: 'Bu Hafta'
	}
};
