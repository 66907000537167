import React from 'react';
import { withRouter } from 'react-router-dom';
import translate from '../../../Services/TranslateService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';
import ValidationService from '../../../Services/ValidationService';
import { Button, FormGroup, Label, Input, Col, CustomInput } from 'reactstrap';
import LoadingService from '../../../Services/LoadingService';
import { inject, observer } from 'mobx-react';

@inject('CommonStore', 'AuthStore')
@observer
class OrderAction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order_data: {
				status: '',
				wirofy_id: null
			},
			data: this.resetForm()
		};
	}

	resetForm() {
		const data = {
			status: {
				type: 'string',
				value: '',
				required: true,
				error: false
			},
			detail: {
				type: 'string',
				value: '',
				required: false,
				error: false
			}
		};
		return data;
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/orders/' + this.props.match.params.id)
				.then(data => {
					const sdata = this.state.order_data;
					sdata.status = data.status;
					sdata.wirofy_id = data.wirofy_id;
					this.setState({
						order_data: sdata
					});
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('order.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		}
	}

	changeHandle(e) {
		const data = this.state.data;
		data[e.target.id].value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		this.setState({ data: data });
	}

	changeKeyValueHandle(key, value) {
		const data = this.state.data;
		data[key].value = value;
		this.setState({ data: data });
	}

	handleSubmit() {
		const loading = new LoadingService().start();
		let data = this.state.data;
		if (ValidationService.wholeValid(data)) {
			const post = {
				status: data.status.value,
				detail: data.detail.value
			};
			APIService.requests
				.put('/orders/' + this.props.match.params.id + '/action', post)
				.then(() => {
					AlertService.add({
						message: translate.getText('order.action.actionsavemessage'),
						level: 'success',
						autoDismiss: true
					});
					this.props.refresh();
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('order.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					this.props.refresh();
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			this.setState({
				data: data
			});
			loading.end();
		}
	}

	errorComp(key) {
		const data = this.state.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	render() {
		if (typeof this.props.match.params.id === 'undefined') {
			return null;
		}
		const status = this.state.order_data.status;
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="ct-content">
						<div className="row">
							<div className="col-6">
								<FormGroup row>
									<Label sm={4} for="status">{translate.getText('form.status')}</Label>
									<Col sm={8}>
										<CustomInput
											value={this.state.data.status.value}
											type="select" id="status" name="status" onChange={e => this.changeHandle(e)}>
											<option value="">{translate.getText('general.select')}</option>
											{
												(status === 'pending' || status === 'modify') &&
												<option value="accept">{translate.getText('general.accept')}</option>
											}
											{
												(status === 'pending' || status === 'modify') &&
												<option value="reject">{translate.getText('general.reject')}</option>
											}
											{
												(status === 'active') &&
												<option value="cancel">{translate.getText('general.cancel')}</option>
											}
											{
												(status === 'active') &&
												<option value="deactive">{translate.getText('general.deactive')}</option>
											}
											{
												(status === 'deactive') &&
												<option value="active">{translate.getText('general.active')}</option>
											}
										</CustomInput>
										{this.errorComp('status')}
									</Col>
								</FormGroup>
							</div>
							<div className="col-12">
								<FormGroup row>
									<Label sm={2} className="ct-form-label" for="detail">{translate.getText('form.detail')}</Label>
									<Col sm={10}>
										<Input value={this.state.data.detail.value} type="textarea" id="detail" name="detail" onChange={e => this.changeHandle(e)}>
										</Input>
										{this.errorComp('detail')}
									</Col>
								</FormGroup>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<div className="ct-content">
						<div className="ct-form-button-group">
							<Button onClick={() => this.handleSubmit()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(OrderAction);

