import React from 'react';
import { withRouter } from 'react-router-dom';
import Chart from 'chart.js';
import translate from '../../../Services/TranslateService';
import './chart.css';
import LoadingService from '../../../Services/LoadingService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';

class Chart2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			color: [
				{
					rgb: 'rgb(255, 99, 132)',
					rgba: 'rgba(255, 99, 132,.2)'
				},
				{
					rgb: 'rgb(54, 162, 235)',
					rgba: 'rgba(54, 162, 235,.2)'
				},
				{
					rgb: 'rgb(153, 102, 255)',
					rgba: 'rgba(153, 102, 255,.2)'
				},
				{
					rgb: 'rgb(255, 159, 64)',
					rgba: 'rgba(255, 159, 64,.2)'
				},
				{
					rgb: 'rgb(201, 203, 207)',
					rgba: 'rgba(201, 203, 207,.2)'
				},
				{
					rgb: 'rgb(255, 205, 86)',
					rgba: 'rgba(255, 205, 86,.2)'
				},
				{
					rgb: 'rgb(75, 192, 192)',
					rgba: 'rgba(75, 192, 192,.2)'
				}
			],
			legends: [],
			hidden_legends: [],
			period_type: 'monthly'
		};
		this.chart2Ref = React.createRef();
	}

	componentDidMount() {
		const config = {
			type: 'bar',
			data: {
				labels: [],
				datasets: []
			},
			options: {
				responsive: true,
				/* legend: {
					position: 'top',
					labels: {
						usePointStyle: true,
						boxWidth: 10
					}
				},*/
				legend: false,
				legendCallback: function (chart) {
					const text = [];
					for (let i = 0; i < chart.data.datasets.length; i++) {
						text[i] = {
							backgroundColor: chart.data.datasets[i].backgroundColor,
							borderColor: chart.data.datasets[i].borderColor,
							label: chart.data.datasets[i].label
						};
					}
					return text;
				},
				hover: {
					mode: 'index'
				},
				tooltips: {
					mode: 'index',
					intersect: false
				},
				scales: {
					xAxes: [{
						stacked: true,
						display: true,
						scaleLabel: {
							display: false,
							labelString: 'Tarih'
						},
						gridLines: {
							display: false
						}
					}],
					yAxes: [{
						stacked: true,
						display: true,
						position: 'right',
						scaleLabel: {
							display: false,
							labelString: 'Değer'
						}
					}]
				},
				title: {
					display: false,
					text: 'Bill Chart'
				}
			}
		};
		this.chart2 = new Chart(this.chart2Ref.current, config);
		this.chart2.aspectRatio = 0;
		this.chart2.maintainAspectRatio = true;

		this.loadChart();
	}

	loadChart(period_type = null) {
		period_type = (period_type !== null) ? period_type : this.state.period_type;
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/reports/license-1', {
				filter: {
					period_type: period_type
				},
				total: true
			})
			.then(response => {
				this.chart2.data.labels = [];
				this.chart2.data.datasets = [];
				response.data.forEach((e, j) => {
					if (response.type === 'daily') {
						const d = e.text.split('-');
						this.chart2.data.labels.push(d[1] + ' ' + translate.getText('date.month._' + d[0]));
					} else if (response.type === 'weekly') {
						if ((response.data.length - 1) === j) {
							this.chart2.data.labels.push(translate.getText('date.current_week'));
						} else {
							const d = e.text.split(' ');
							const d1 = d[0].split('-');
							const d2 = d[1].split('-');
							this.chart2.data.labels.push(d1[1] +
								' ' +
								translate.getText('date.month._' + d1[0]) +
								' - ' +
								d2[1] +
								' ' +
								translate.getText('date.month._' + d2[0]));
						}
					} else if (response.type === 'monthly') {
						this.chart2.data.labels.push(translate.getText('date.month._' + e.text));
					} else if (response.type === 'yearly') {
						this.chart2.data.labels.push(e.text);
					}
					e.data.forEach((k, i) => {
						if (typeof this.chart2.data.datasets[i] === 'undefined') {
							this.chart2.data.datasets.push({
								label: translate.getText('order.license.' + k.text),
								data: [],
								backgroundColor: this.state.color[i].rgb,
								borderColor: this.state.color[i].rgba,
								fill: true,
								pointHoverRadius: 10,
								hidden: (this.state.hidden_legends.indexOf(i) !== -1)
							});
						}
						if (typeof this.chart2.data.datasets[i].data[j] === 'undefined') {
							if (k.text === 'total') {
								k.value -= (k.value * 2);
							}
							this.chart2.data.datasets[i].data.push(k.value);
						}
					});
				});
				this.chart2.update();
				this.setState({
					legends: this.chart2.generateLegend()
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('report.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	hideLegend(e, i) {
		e.target.classList.toggle('unselect-legend');
		const hidden_legends = this.state.hidden_legends;
		const element_index = hidden_legends.indexOf(i);
		if (element_index !== -1) {
			hidden_legends.splice(element_index, 1);
		} else {
			hidden_legends.push(i);
		}
		this.chart2.data.datasets[i].hidden = !this.chart2.data.datasets[i].hidden;
		this.chart2.update();
	}

	changeDateFilter(type) {
		this.setState({
			period_type: type
		});
		this.loadChart(type);
	}

	render() {
		return (
			<div className="ct-chart">
				<div className="top-toolbar">
					<div className="legends">
						<ul className="legends-list">
							{
								(typeof this.chart2 !== 'undefined') &&
								this.state.legends.map((e, i) =>
									<li key={i}
										onClick={(el => this.hideLegend(el, i))}
										className={(this.state.hidden_legends.indexOf(i) !== -1) ? 'unselect-legend' : ''}
										style={{
											backgroundColor: e.backgroundColor,
											borderColor: e.borderColor
										}}>{e.label}</li>
								)
							}
						</ul>
					</div>
					<div className="date-select">
						<ul className="date-select-list">
							<li onClick={() => this.changeDateFilter('daily')} className={(this.state.period_type === 'daily' ? 'active' : '')}>{translate.getText('date.daily')}</li>
							<li onClick={() => this.changeDateFilter('weekly')} className={(this.state.period_type === 'weekly' ? 'active' : '')}>{translate.getText('date.weekly')}</li>
							<li onClick={() => this.changeDateFilter('monthly')} className={(this.state.period_type === 'monthly' ? 'active' : '')}>{translate.getText('date.monthly')}</li>
							<li onClick={() => this.changeDateFilter('yearly')} className={(this.state.period_type === 'yearly' ? 'active' : '')}>{translate.getText('date.yearly')}</li>
						</ul>
					</div>
				</div>
				<canvas ref={this.chart2Ref} className="chart2" />
			</div>
		);
	}
}

export default withRouter(Chart2);
