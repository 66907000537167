import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import translate from '../../Services/TranslateService';
import './home.css';
import Chart1 from './Chart/Chart1';
import Chart2 from './Chart/Chart2';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Chart3 from './Chart/Chart3';
import Chart4 from './Chart/Chart4';
import Chart5 from './Chart/Chart5';
import LoadingService from '../../Services/LoadingService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import Moment from 'react-moment';

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 0,
			histories: []
		};
		this.getHistory();
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	getHistory() {
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/histories/paginate', {
				filter: {},
				page: 1,
				limit: 20,
				sort: {
					id: 'DESC'
				}
			})
			.then(response => {
				this.setState({
					histories: response.data
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('order.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	render() {
		return (
			<div className="home-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content-full">
							<div className="row no-gutters row-gutters">
								<div className="col-md-9">
									<div className="row">
										<div className="col-12">
											<h4>{translate.getText('home.title.activite_graffic')}</h4>
											<div className="ct-tab-style-1">
												<Nav tabs>
													<NavItem>
														<NavLink
															active={this.state.activeTab === 0}
															onClick={() => this.toggleTab(0)}
														>
															{translate.getText('home.tab.bill')}
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink
															active={this.state.activeTab === 1}
															onClick={() => this.toggleTab(1)}
														>
															{translate.getText('home.tab.license')}
														</NavLink>
													</NavItem>
												</Nav>
												<TabContent activeTab={this.state.activeTab}>
													<TabPane tabId={0}>
														{
															this.state.activeTab === 0 &&
															<Chart1 />
														}
													</TabPane>
													<TabPane tabId={1}>
														{
															this.state.activeTab === 1 &&
															<Chart2 />
														}
													</TabPane>
												</TabContent>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<h4>{translate.getText('home.title.recent_histories')}</h4>
									<div className="history-scroll">
										<ul className="ct-timeline ct-minitimeline">
											{
												this.state.histories.map((history, i) =>
													<li className={`event ${history.status}`} key={i}>
														<div className="event-content">
															<div className="title"><Link to={'/order/detail/' + history.order.id}>#{history.order.id}</Link> {translate.getText('history_status.' + history.status)}</div>
															<div className="subtitle">{history.user.first_name} {history.user.last_name} ({history.user.title}) - <Moment className="sm-date" fromNow>{history.create_time}</Moment></div>
															{
																history.detail !== '' &&
																<p>{history.detail}</p>
															}
														</div>
														<div className="event-icon">
															<i className="fa-inverse fas fa-circle"></i>
														</div>
													</li>
												)
											}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="ct-content home-bottom-chart">
							<div className="row">
								<div className="col-md-4">
									<h5>{translate.getText('home.title.license_status')}</h5>
									<Chart3 />
								</div>
								<div className="col-md-4">
									<h5>{translate.getText('home.title.license_type')}</h5>
									<Chart4 />
								</div>
								<div className="col-md-4">
									<h5>{translate.getText('home.title.dealer')}</h5>
									<Chart5 />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Home);
