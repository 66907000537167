import React from 'react';
import { inject, observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import Master from './Master';
import Auth from './Auth';
import Loading from './Components/Loading/Loading';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/js/all.min';
import 'react-phone-number-input/style.css';
import './Assets/css/icons.css';
import './Assets/css/style.css';
import './Assets/css/responsive.css';
import moment from 'moment';
import 'moment/locale/tr';

@inject('AuthStore', 'CommonStore')
@observer
class App extends React.Component {
	constructor(props) {
		super(props);
		const lang = this.props.CommonStore.getlanguage();
		moment.locale(lang === 'tr' ? 'tr' : 'en-GB');
	}
	render() {
		let Layout = '';
		if (this.props.AuthStore.isLogged) {
			Layout = <Master />;
		} else {
			Layout = <Auth />;
		}

		let loadingstatus = true;
		if (Object.keys(this.props.CommonStore.loading).filter(x => this.props.CommonStore.loading[x]).length > 0) {
			loadingstatus = false;
		}
		return (
			<div className="App">
				{Layout}
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnVisibilityChange
					draggable
					pauseOnHover={false}
				/>
				<Loading status={loadingstatus} />
				<div className="ct-version-code">
					VER: {
						// eslint-disable-next-line no-undef
						process.env.REACT_APP_VERSION
					}
				</div>
			</div>
		);
	}
}

export default App;
