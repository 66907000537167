export function getIcon(status) {
	switch (status) {
		case 'order_request':
		case 'order_modify_request':
		case 'bill_pending':
			return 'clock';
		case 'bill_approved':
		case 'bill_completed':
		case 'order_modify_accept':
		case 'order_accept':
			return 'check';
		case 'bill_unapproved':
		case 'bill_cancelled':
		case 'order_reject':
		case 'order_modify_reject':
			return 'times';
		case 'profile_updated':
			return 'pen';
		case 'bill_created':
			return 'file-invoice-dollar';
		case 'bill_payment_expected':
			return 'money-bill';
		case 'bill_payment_refunded':
			return 'undo';
		default:
			return 'dot-circle';
	}
}
