import React from 'react';
import { withRouter } from 'react-router-dom';
import translate from '../../../Services/TranslateService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';
import ValidationService from '../../../Services/ValidationService';
import { Button, FormGroup, Label, Input, CustomInput, Col } from 'reactstrap';
import LoadingService from '../../../Services/LoadingService';
import { inject, observer } from 'mobx-react';
import AutoComplete from '../../../Components/AutoComplete/AutoComplete';

@inject('CommonStore', 'AuthStore')
@observer
class UserForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.defaultForm()
		};
	}

	defaultForm() {
		const data = {
			title: {
				type: 'string',
				minLength: 3,
				maxLength: 100,
				value: '',
				required: true,
				error: false
			},
			first_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: true,
				error: false
			},
			last_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: true,
				error: false
			},
			email: {
				type: 'email',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: true,
				error: false
			},
			password: {
				type: 'string',
				value: '',
				required: false,
				error: false
			},
			repassword: {
				type: 'string',
				value: '',
				required: false,
				error: false
			},
			status: {
				type: 'boolean',
				value: true,
				required: false,
				error: false
			}
		};
		if (this.props.AuthStore.hasAdmin()) {
			data.distributor_id = {
				type: 'integer',
				label: '',
				value: null,
				required: true,
				error: false
			};
			data.admin = {
				type: 'boolean',
				value: false,
				required: false,
				error: false
			};
		}

		return data;
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			const data = this.state.data;
			APIService.requests
				.get('/users/' + this.props.match.params.id)
				.then(response => {
					data.title.value = response.title;
					data.first_name.value = response.first_name;
					data.last_name.value = response.last_name;
					data.email.value = response.email;
					data.status.value = response.status === 1;
					if (this.props.AuthStore.hasAdmin()) {
						data.distributor_id.value = response.distributor.id;
						data.distributor_id.label = response.distributor.company;
						data.admin.value = response.distributor.id === null;
					}
					this.setState({
						data: data
					});
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('user.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			this.setState({ data: this.defaultForm() });
		}
	}

	changeHandle(e) {
		const data = this.state.data;
		data[e.target.id].value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		this.setState({ data: data });
	}

	changeDistributorHandle(value) {
		if (value === null) {
			return;
		}
		const data = this.state.data;
		data.distributor_id.value = value;
		this.setState({ data: data });
	}

	handleSubmit() {
		const loading = new LoadingService().start();
		let data = this.state.data;
		if (ValidationService.wholeValid(data)) {
			let req = APIService.requests;
			const post = {
				title: data.title.value,
				first_name: data.first_name.value,
				last_name: data.last_name.value,
				email: data.email.value,
				password: data.password.value,
				repassword: data.repassword.value,
				status: data.status.value
			};
			if (this.props.AuthStore.hasAdmin()) {
				post.distributor_id = data.distributor_id.value;
				post.admin = data.admin.value;
			}

			if (typeof this.props.match.params.id !== 'undefined') {
				req = req.put('/users/' + this.props.match.params.id, post);
			} else {
				req = req.post('/users', post);
			}

			req.then(() => {
				AlertService.add({
					message: translate.getText('user.action.savemessage'),
					level: 'success',
					autoDismiss: true
				});
				loading.end();
				this.props.history.push('/user/list');
			})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						data = this.state.data;
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('user.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			this.setState({
				data: data
			});
			loading.end();
		}
	}

	handleBack() {
		if (typeof this.props.match.params.id !== 'undefined') {
			this.props.history.push('/user/detail/' + this.props.match.params.id);
		} else {
			this.props.history.push('/user/list');
		}
	}

	errorComp(key) {
		const data = this.state.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	render() {
		return (
			<div className="user-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<h4>{(typeof this.props.match.params.id === 'undefined') ? translate.getText('user.title.add') : translate.getText('user.title.edit')}</h4>
							<div className="row">
								{
									this.props.AuthStore.hasAdmin() &&
									<div className="col-12">
										<FormGroup row>
											<Label sm={3} for="distributor_id">{translate.getText('form.distributor')}</Label>
											<Col sm={3}>
												<CustomInput checked={(this.state.data.admin.value)} type="switch" id="admin" name="admin" onChange={e => this.changeHandle(e)} />
												<small className="text-info">Admin</small>
											</Col>
											<Col sm={6}>
												<AutoComplete
													value={this.state.data.distributor_id.label}
													id="distributor_id"
													name="distributor_id"
													onChange={e => this.changeDistributorHandle(e)}
													disabled={this.state.data.admin.value ? 'disabled' : ''}
													api={{
														url: '/distributors/paginate',
														param: 'company',
														cons_param: {
															status: 1
														}
													}}
												/>
												{this.errorComp('distributor_id')}
											</Col>
										</FormGroup>
									</div>
								}
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="title">{translate.getText('form.title')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.title.value} type="text" id="title" name="title" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('title')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="first_name">{translate.getText('form.first_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.first_name.value} type="text" id="first_name" name="first_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('first_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="last_name">{translate.getText('form.last_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.last_name.value} type="text" id="last_name" name="last_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('last_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="email">{translate.getText('form.email')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.email.value} type="text" id="email" name="email" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('email')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="password">{translate.getText('form.password')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.password.value} type="password" id="password" name="password" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('password')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="repassword">{translate.getText('form.repassword')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.repassword.value} type="password" id="repassword" name="repassword" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('repassword')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="status">{translate.getText('form.status')}</Label>
										<Col sm={9}>
											<CustomInput checked={(this.state.data.status.value)} type="switch" id="status" name="status" onChange={e => this.changeHandle(e)} />
											{this.errorComp('status')}
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className="ct-content">
							<div className="ct-form-button-group">
								<Button onClick={() => this.handleBack()} className="ct-form-button btn-back btn-sm">{translate.getText('general.back')}</Button>
								<Button onClick={() => this.handleSubmit()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(UserForm);

