import React from 'react';
import { withRouter } from 'react-router-dom';
import APIService from '../../Services/APIService';
import translate from '../../Services/TranslateService';
import AlertService from '../../Services/AlertService';
import { inject, observer } from 'mobx-react';
import { Button, InputGroup, Input, ModalHeader, ModalBody, Label, ModalFooter, Modal, Col, FormGroup } from 'reactstrap';
import LoadingService from '../../Services/LoadingService';
import './dealer.css';
import AutoComplete from '../../Components/AutoComplete/AutoComplete';
import ValidationService from '../../Services/ValidationService';

@inject('CommonStore', 'AuthStore')
@observer
class ListDealer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			filter: {
				company: ''
			},
			sortable: {},
			filterChange: false,
			page: 1,
			limit: 15,
			maxPage: 1,
			total: 0,
			pagination: [],
			hasMoreItems: true,
			items: [],
			distributorColor: {},
			dealersToMove: [],
			checkAllDealerToggle: false,
			moveModalToggle: false,
			moveModalBody: [],
			distributors: [],
			selected_distributor_id: 0,
			selected: [],
			updateSelected: {
				data: {
					dealer_id: {
						type: 'integer',
						value: null,
						label: '',
						required: true,
						error: false
					}
				},
				modalToggle: false
			},
			advancedFilter: false
		};
		if (this.props.AuthStore.hasAdmin()) {
			this.state.updateSelected.data.distributor_id = {
				type: 'integer',
				label: '',
				value: null,
				required: true,
				error: false
			};
		}
	}

	changeFilter(e) {
		const filter = this.state.filter;
		filter[e.target.id] = e.target.value;
		this.setState({
			filter: filter,
			filterChange: true,
			items: []
		});
		this.loadDealers(1); // Remove it for more performance
	}

	componentDidMount() {
		this.loadDealers();
	}

	loadDealers(setpage = null, setlimit = null, sortable = null) {
		let page = (setpage !== null) ? setpage : this.state.page;
		const limit = (setlimit !== null) ? setlimit : this.state.limit;
		if (sortable !== null) {
			this.setState({
				sortable: sortable
			});
		} else {
			sortable = this.state.sortable;
		}
		if (this.state.filterChange === true) {
			page = 1;
		}
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/dealers/paginate', {
				filter: this.state.filter,
				page: page,
				limit: limit,
				sort: sortable
			})
			.then(response => {
				let hasMoreItems = false;
				const items = this.state.items;
				if (response.data.length === 0 || response.data.length < limit) {
					hasMoreItems = false;
				} else {
					hasMoreItems = true;
				}
				if (this.props.AuthStore.hasAdmin()) {
					const distributorColor = this.state.distributorColor;
					response.data.map((dealer, i) => {
						let distributorBgColor;
						if (typeof distributorColor[dealer.distributor.id] !== 'undefined') {
							distributorBgColor = distributorColor[dealer.distributor.id];
						} else {
							distributorBgColor = '#' + ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
							distributorColor[dealer.distributor.id] = distributorBgColor;
						}
						const gradient1 = ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
						const gradient2 = ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
						items.push(
							<div className="single-box" key={this.state.items.length}>
								<div className="button-div">
									<button className="btn btn-sm btn-info btn-detail" onClick={() => this.props.history.push('/dealer/detail/' + dealer.id)}><i className="fas fa-info-circle"></i></button>
								</div>
								<div className="style-color" style={{ backgroundImage: 'linear-gradient(to bottom,' + '#' + gradient1 + ',' + '#' + gradient2 + ')' }}></div>
								<div className="single-box-content">
									<div className="title">{dealer.company}</div>
									<div className="sub-title">
										<p>
											{dealer.first_name + ' ' + dealer.last_name}
										</p>
										<p>
											{dealer.phone}
										</p>
										<p>
											{dealer.email}
										</p>
									</div>
									<button className="btn btn-block" style={{ backgroundColor: distributorBgColor }}>
										{dealer.distributor.company}
									</button>
								</div>
								<div className="content-top-right-button checkbox">
									<button className="btn btn-sm" onClick={e => this.changeSelected(dealer.id, e)} ><i className="far fa-square"></i></button>
								</div>
							</div>
						);
					});
					this.setState({
						data: response.data,
						page: response.page,
						limit: response.limit,
						maxPage: response.max_page,
						total: response.total,
						filterChange: false,
						hasMoreItems: hasMoreItems,
						items: items,
						distributorColor: distributorColor
					});
				} else {
					response.data.map(dealer => {
						const gradient1 = ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
						const gradient2 = ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
						items.push(
							<div className="single-box" onClick={() => this.props.history.push('/dealer/detail/' + dealer.id)} key={this.state.items.length}>
								<div className="style-color" style={{ backgroundImage: 'linear-gradient(to bottom,' + '#' + gradient1 + ',' + '#' + gradient2 + ')' }}></div>
								<div className="single-box-content">
									<div className="title">{dealer.company}</div>
									<div className="sub-title">
										<p>
											{dealer.first_name + ' ' + dealer.last_name}
										</p>
										<p>
											{dealer.phone}
										</p>
										<p>
											{dealer.email}
										</p>
									</div>
								</div>
							</div>
						);
					});
					this.setState({
						data: response.data,
						page: response.page,
						limit: response.limit,
						maxPage: response.max_page,
						total: response.total,
						filterChange: false,
						hasMoreItems: hasMoreItems,
						items: items
					});
				}
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('dealer.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	updateSelectedhandleSubmit() {
		const loading = new LoadingService().start();

		const updateSelected = this.state.updateSelected;
		let data = updateSelected.data;
		const post = {
			ids: this.state.selected
		};
		if (this.props.AuthStore.hasAdmin()) {
			post.distributor_id = data.distributor_id.value;
		}
		if (this.state.selected.length < 1) {
			AlertService.add({
				message: translate.getText('error.error_multi_select'),
				level: 'danger',
				autoDismiss: true
			});
			loading.end();
			return;
		}
		if (ValidationService.wholeValid(data)) {
			APIService.requests.put('/dealers/items', post)
				.then(() => {
					AlertService.add({
						message: translate.getText('dealer.action.savemultiupdatemessage'),
						level: 'success',
						autoDismiss: true
					});
					this.props.history.push('/dealer/');
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						updateSelected.data = data;
						this.setState({
							updateSelected: updateSelected
						});
					} else {
						AlertService.add({
							message: translate.getText('dealer.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			updateSelected.data = data;
			this.setState({
				updateSelected: updateSelected
			});
			loading.end();
		}
	}

	changeSelected(id, e) {
		const selected = this.state.selected;
		const index = selected.indexOf(id);
		let el = e.target.closest('.checkbox');
		el = el.children[0].children[0];
		if (index === -1) {
			selected.push(id);
			el.classList.add('fas', 'fa-check-square');
			el.classList.remove('far', 'fa-square');
		} else {
			selected.splice(index, 1);
			el.classList.remove('fas', 'fa-check-square');
			el.classList.add('far', 'fa-square');
		}
		e.target.closest('.checkbox').classList.toggle('checked');
		this.setState({
			selected: selected
		});
	}

	updateSelectederrorComp(key) {
		const data = this.state.updateSelected.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	updateSelectedchangeKeyValueHandle(key, value) {
		const updateSelected = this.state.updateSelected;
		updateSelected.data[key].value = value;
		if (key === 'distributor_id') {
			updateSelected.data.dealer_id.value = 0;
			updateSelected.data.dealer_id.label = '';
		}
		this.setState({ updateSelected: updateSelected });
	}

	render() {
		return (
			<div className="dealer-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<h2>{translate.getText('dealer.title.list')}</h2>
							<div className="ct-row">
								<div className="ct-content-toolbar">
									<FormGroup className="standart-top-filter">
										<InputGroup>
											<Input
												onChange={e => this.changeFilter(e)}
												id="company" name="company" type="text"
												placeholder={translate.getText('general.filterinput')} />
											<Button className="btn-sm btn-success" onClick={() => this.setState({ advancedFilter: !this.state.advancedFilter })}>
												<i className="fa fa-filter"></i> {translate.getText('general.advanced_filter')}
											</Button>
											{
												this.props.AuthStore.hasAdmin() &&
												<Button className="btn-sm btn-success" onClick={() => this.setState({ updateSelected: { ...this.state.updateSelected, modalToggle: !this.state.updateSelected.modalToggle } })}>
													<i className="fa fa-arrows-alt"></i> {translate.getText('general.update_selected')}
												</Button>
											}
											<Button className="btn-sm btn-success" onClick={() => this.props.history.push('/dealer/new')}>
												<i className="fa fa-plus-circle"></i> {translate.getText('dealer.action.adddealer')}
											</Button>
										</InputGroup>
									</FormGroup>
								</div>
								<div className="content-list">
									<div className="ct-content-filter" hidden={!this.state.advancedFilter}>
										burada gelişmiş filtre!
									</div>
								</div>
								<div className="ct-box-list">
									{
										this.state.items
									}
								</div>
								<div className="ct-box-load-more">
									{this.state.hasMoreItems && <button onClick={() => this.loadDealers((this.state.page + 1))} className="btn">{translate.getText('general.load_more')}</button>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					isOpen={this.state.updateSelected.modalToggle}
					toggle={() => this.setState({ updateSelected: { ...this.state.updateSelected, modalToggle: !this.state.updateSelected.modalToggle } })}>
					<ModalHeader>{translate.getText('general.multi_update')}</ModalHeader>
					<ModalBody>
						<div className="row ct-content">
							{
								this.props.AuthStore.hasAdmin() &&
								<div className="col-12">
									<FormGroup row>
										<Label sm={4} for="distributor_id">{translate.getText('form.distributor')}</Label>
										<Col sm={8}>
											<AutoComplete
												value={this.state.updateSelected.data.distributor_id.label}
												id="distributor_id"
												name="distributor_id"
												onChange={value => this.updateSelectedchangeKeyValueHandle('distributor_id', value)}
												api={{
													url: '/distributors/paginate',
													param: 'company',
													cons_param: {
														status: 1
													}
												}}
											/>
											{this.updateSelectederrorComp('distributor_id')}
										</Col>
									</FormGroup>
								</div>
							}
							<div className="col-12">
								<hr></hr>
								<Label className="ct-form-label"><b>{translate.getText('general.selected_count')}: </b></Label>
								{this.state.selected.length}
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className="ct-form-button-group" style={{ width: '100%' }}>
							<Button onClick={() => this.updateSelectedhandleSubmit()} className="ct-form-button btn-submit btn-sm btn-block">{translate.getText('general.save')}</Button>
						</div>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default withRouter(ListDealer);
