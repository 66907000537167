/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { withRouter } from 'react-router-dom';
import translate from '../../Services/TranslateService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import { FormGroup, Col, Collapse, Row } from 'reactstrap';
import LoadingService from '../../Services/LoadingService';
import { inject, observer } from 'mobx-react';

@inject('CommonStore', 'AuthStore')
@observer
class CommitmentTabOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			product: {
				id: null,
				options: []
			},
			activeAccordion: '',
			activeSubAccordion: ''
		};
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			this.reqProduct();
			const loading = new LoadingService().start();
			APIService.requests
				.getByParam('/commitments/paginate', {
					filter: {
						order: this.props.match.params.id
					},
					page: 1,
					limit: 12,
					sort: {
						id: 'DESC'
					}
				})
				.then(response => {
					const data = response.data;
					for (const i in data) {
						if (Object.prototype.hasOwnProperty.call(data, i)) {
							for (const j in data[i].commitment_options) {
								if ((Object.prototype.hasOwnProperty.call(data[i].commitment_options, j))) {
									if (data[i].commitment_options[j].start_at === null) {
										data[i].commitment_options[j].option_status = 'future';
									} else {
										let start_at = new Date(data[i].commitment_options[j].start_at);
										let expiration_at = new Date(data[i].commitment_options[j].expiration_at);
										let today = new Date();
										start_at = start_at.getTime();
										expiration_at = expiration_at.getTime();
										today = today.getTime();
										if (today > start_at && today < expiration_at) {
											data[i].commitment_options[j].option_status = 'now';
										} else if (today < start_at) {
											data[i].commitment_options[j].option_status = 'future';
										} else if (data[i].commitment_options[j].bill.payment === null) {
											data[i].commitment_options[j].option_status = 'notpaid';
										} else {
											data[i].commitment_options[j].option_status = 'past';
										}
									}
								}
							}
						}
					}
					this.setState({ data: data });
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('order.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		}
	}

	reqProduct() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/products/1')
			.then(data => {
				this.setState({ product: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	toggleAccordion(accordion) {
		if (this.state.activeAccordion !== accordion) {
			this.setState({
				activeAccordion: accordion
			});
		} else {
			this.setState({
				activeAccordion: ''
			});
		}
	}

	toggleSubAccordion(accordion) {
		if (this.state.activeSubAccordion !== accordion) {
			this.setState({
				activeSubAccordion: accordion
			});
		} else {
			this.setState({
				activeSubAccordion: ''
			});
		}
	}

	render() {
		const accordion_header_class = {
			commitment_default: 'col-md-12 commitment-accordion',
			now: 'alert-primary',
			future: 'alert-secondary',
			past: 'alert-success',
			not_paid: 'alert-danger'
		};
		return (
			<Row>
				{
					this.state.data.map((o_commitments, i) =>
						<div key={i} className="col-md-12">
							<div onClick={() => this.toggleAccordion(i)} className={`order-accordion ${(o_commitments.status === 1) ? accordion_header_class.past : accordion_header_class.future} ${(i === this.state.activeAccordion) ? 'active' : ''}`} color="primary" id={'toggler' + i}>
								<div className="status" title={translate.getText('commitment_status.' + o_commitments.status)}>
									{(o_commitments.status === 1) ?
										<i className={`fas order-status fa-4x fa-check-circle  ${(o_commitments.status === 1) ? 'active' : 'pending'}`} aria-hidden='true'> </i> :
										<i className={`fas order-status fa-4x fa-exclamation-circle  ${(o_commitments.status === 1) ? 'active' : 'pending'}`} aria-hidden='true'> </i>}
								</div>
								<div className="invoice">
									<h4>{translate.getText('order.commitment.commitment_no')}: <b>{o_commitments.id}</b></h4>
									<p>{o_commitments.create_time}</p>
								</div>
								<div className="product">
									{}
								</div>
								<div className="prices">
									{}
								</div>
								<div className="toggler-button" key={Math.random()}>
									{(i === this.state.activeAccordion) ?
										<i className='fas fa-2x fa-chevron-down' aria-hidden='true'> </i> : <i className='fas fa-2x fa-chevron-left' aria-hidden='true'> </i>
									}
								</div>
							</div>
							<Collapse isOpen={(i === this.state.activeAccordion)} toggler={'toggler' + i}>
								<div className="container">
									<Row>
										<Col sm={12}>
											{
												o_commitments.commitment_options.map((coms, j) =>
													<div key={i + '+' + j} className='col-md-12'>
														<div key={i + '-' + j} onClick={() => this.toggleSubAccordion(i + '-' + j)} className={(i + '-' + j === this.state.activeSubAccordion) ? accordion_header_class.commitment_default + ' active ' + accordion_header_class[coms.option_status] : accordion_header_class.commitment_default + ' ' + accordion_header_class[coms.option_status]} color="primary" id={'toggler' + i + '-' + j}>
															<Row>
																<div className='col-md-3'><i className='fas fa-bars' aria-hidden='true'> </i> {translate.getText('order.commitment.purchase') + ' ' + (j + 1)}</div>
																<div className='col-md-3'>{translate.getText('order.commitment.period')}: {(coms.start_at === null) ? (j + 1) : coms.start_at + ' | ' + coms.expiration_at}</div>
																<div className='col-md-3'>{translate.getText('order.commitment.option_status.' + coms.option_status)}</div>
																<div className='col-md-3'>{translate.getText('order.commitment.amount')}: {coms.bill.total_price_format}</div>
															</Row>
														</div>
														<Collapse key={j} isOpen={(i + '-' + j === this.state.activeSubAccordion)} toggler={'toggler' + i + '-' + j}>
															<Row>
																<div className="col-sm-12" key={j}>
																	<Row>
																		<div className="col-sm-6">
																			<Row>
																				{
																					coms.bill.options.map((bill_option, boi) =>
																						<div className="col-sm-6" key={boi}>
																							{
																								this.state.product.options.map((option, ind) =>
																									// eslint-disable-next-line no-nested-ternary
																									option.name === 'license' &&
																									<div className="col-sm-12" key={ind}>
																										{
																											option.values.map(val =>
																												val.id === bill_option.order_option.product_option_value.id &&
																												<FormGroup row>
																													<Col key={ind} sm={12}>
																														<div key={boi} className="col ct-boxselect-item commitment-card">{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}<span></span>
																															<ul>
																																{
																																	Object.entries(val.properties.commitment_12_month).map((ent, ei) =>
																																		<li key={ei}><span>{translate.getText('order.license_list.' + ent[0])}</span>{ent[1]}</li>
																																	)
																																}
																															</ul>
																														</div>
																													</Col>
																												</FormGroup>
																											)
																										}
																									</div>
																								)
																							}
																							{
																								this.state.product.options.map((option, ind) =>
																									// eslint-disable-next-line no-nested-ternary
																									option.name !== 'license' &&
																									<div className="col-sm-12" key={ind}>
																										{
																											option.values.map(val =>
																												val.id === bill_option.order_option.product_option_value.id &&
																												<FormGroup row>
																													<Col key={ind} sm={12}>
																														<div key={boi} className="col ct-boxselect-item commitment-card">{translate.getText('form.option.' + option.name).toUpperCase()}<span></span>
																															<ul>
																																<li><span>{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}</span></li>
																															</ul>
																														</div>
																													</Col>
																												</FormGroup>
																											)
																										}
																									</div>
																								)
																							}
																						</div>
																					)
																				}
																			</Row>
																		</div>
																		<div className="col-sm-6">
																			<table className="table ct-table-style-2 ct-table ct-table-style-sub text-right">
																				<tbody>
																					<tr>
																						<td className="text-right">{translate.getText('form.payment_type')} :</td>
																						<td className="text-right">{translate.getText('payment.' + coms.bill.payment_type)}</td>
																					</tr>
																					{
																						coms.bill.totals.map((te, ti) =>
																							<tr key={ti}>
																								<td className="text-right">{translate.getText('total.' + te.text)} :</td>
																								<td>{te.value} ₺</td>
																							</tr>
																						)
																					}
																				</tbody>
																			</table>
																		</div>
																	</Row>
																</div>
															</Row>
														</Collapse>
													</div>
												)
											}
										</Col>
									</Row>
								</div>
							</Collapse>
						</div>
					)
				}
			</Row>
		);
	}
}

export default withRouter(CommitmentTabOrder);

