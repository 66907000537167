import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import LoadingService from '../../Services/LoadingService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import translate from '../../Services/TranslateService';
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledTooltip
} from 'reactstrap';
import './order.css';
import Moment from 'react-moment';
import { observer, inject } from 'mobx-react';
import { getIcon } from '../../Components/HistoryIcon';
import OrderAction from './OrderForm/OrderAction';
import CommitmentTabOrder from './CommitmentTabOrder';

@inject('CommonStore', 'AuthStore')
@observer
class DetailOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				distributor: '',
				dealer: '',
				company: '',
				first_name: '',
				last_name: '',
				country: '',
				city: '',
				address: '',
				phone: '',
				mobile_phone: '',
				website: '',
				status: '',
				email: '',
				create_time: '',
				wirofy_id: null,
				renewal: false,
				options: []
			},
			bills: [],
			activeTab: this.props.AuthStore.hasAdmin() ? 4 : 2,
			histories: []
		};
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/orders/' + this.props.match.params.id)
				.then(data => {
					const sdata = {
						dealer: data.dealer.company + ' (' + data.dealer.first_name + ' ' + data.dealer.last_name + ')',
						company: data.company,
						first_name: data.first_name,
						last_name: data.last_name,
						country: data.country.name,
						city: data.city.name,
						address: data.address,
						phone: data.phone,
						mobile_phone: data.mobile_phone,
						website: data.website,
						status: data.status,
						email: data.email,
						wirofy_id: data.wirofy_id,
						create_time: data.create_time,
						renewal: data.renewal,
						options: data.options
					};
					if (this.props.AuthStore.hasAdmin()) {
						sdata.distributor = data.distributor.company;
					}
					this.setState({
						data: sdata
					});
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('order.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		} else {
			this.props.history.push('/order/list');
		}
	}

	getBill() {
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/bills/paginate', {
				filter: {
					order: this.props.match.params.id,
					status: 'all'
				},
				page: 1,
				limit: 12,
				sort: {
					id: 'DESC'
				}
			})
			.then(response => {
				this.setState({ bills: response.data });
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('order.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	getHistory() {
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/histories/paginate', {
				filter: {
					order: this.props.match.params.id
				},
				sort: {
					id: 'DESC'
				}
			})
			.then(response => {
				this.setState({
					histories: response.data
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('order.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			if (tab === 1) {
				this.getBill();
			} else if (tab === 0) {
				this.getHistory();
			}
			this.setState({
				activeTab: tab
			});
		}
	}

	render() {
		const sdata = this.state.data;
		return (
			<div className="ct-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<div className="content-top-right-button">
								<Link className="btn ct-button open btn-edit" to={`/order/edit/${this.props.match.params.id}`}>
									<i className="fa fa-pen btn-icon"></i>
									<div className="btn-text">{translate.getText('general.edit')}</div>
								</Link>
								<Link className="btn ct-button open btn-modify" to={`/order/upgrade/${this.props.match.params.id}`}>
									<i className="fa fa-sync-alt btn-icon"></i>
									<div className="btn-text">{translate.getText('general.upgrade')}</div>
								</Link>
								{
									this.props.AuthStore.hasAdmin() &&
									<Link className="btn ct-button open btn-order-login" to={`/order/order_login/${this.props.match.params.id}`}>
										<i className="fa fa-sign-in-alt btn-icon"></i>
										<div className="btn-text">{translate.getText('general.order_login')}</div>
									</Link>
								}
							</div>
							<div className="content-info ct-form-container-block">
								<div className="head-info">
									<h1>{sdata.company}</h1>
									<h3>{sdata.first_name} {sdata.last_name}</h3>
									<div className={`badge badge-status ${sdata.status}`}>
										{translate.getText('order_status.' + sdata.status)}
									</div>
								</div>
								<div className="icon-info">
									<div className="row">
										{
											this.props.AuthStore.hasAdmin() &&
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-distributor">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-network-wired" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-distributor">
													{translate.getText('form.distributor')}
												</UncontrolledTooltip>
												<label>{sdata.distributor}</label>
											</div>
										}
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-dealer">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-project-diagram" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-dealer">
												{translate.getText('form.dealer')}
											</UncontrolledTooltip>
											<label>{sdata.dealer}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-renewal">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-sync" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-renewal">
												{translate.getText('form.renewal')}
											</UncontrolledTooltip>
											<label>{translate.getText('general.' + (sdata.renewal ? 'on' : 'off'))}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-phone">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-phone" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-phone">
												{translate.getText('form.phone')}
											</UncontrolledTooltip>
											<label>{sdata.phone}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-mobile_phone">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-mobile-alt" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-mobile_phone">
												{translate.getText('form.mobile_phone')}
											</UncontrolledTooltip>
											<label>{sdata.mobile_phone}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-email">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-envelope" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-email">
												{translate.getText('form.email')}
											</UncontrolledTooltip>
											<label>{sdata.email}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-website">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-globe" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-website">
												{translate.getText('form.website')}
											</UncontrolledTooltip>
											<label>{sdata.website}</label>
										</div>
										<div className="col-md-12">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-address">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-map-marker-alt" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-address">
												{translate.getText('form.address')}
											</UncontrolledTooltip>
											<label>{sdata.address}</label>
										</div>
										<div className="col-md-6">
											<div className="badge badge-icon" id="Tooltip-country">
												{sdata.country} / {sdata.city}
											</div>
											<UncontrolledTooltip placement="top" target="Tooltip-country">
												{translate.getText('form.country')} / {translate.getText('form.city')}
											</UncontrolledTooltip>
										</div>
										<div className="col-md-6">
											<div className="badge badge-icon" id="Tooltip-create_date">
												{sdata.create_time}
											</div>
											<UncontrolledTooltip placement="top" target="Tooltip-create_date">
												{translate.getText('form.create_date')}
											</UncontrolledTooltip>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<Nav tabs>
							<NavItem>
								<NavLink
									active={this.state.activeTab === 1}
									onClick={() => this.toggleTab(1)}
								>
									{translate.getText('list.current_history')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={this.state.activeTab === 0}
									onClick={() => this.toggleTab(0)}
								>
									{translate.getText('list.order_history')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={this.state.activeTab === 2}
									onClick={() => this.toggleTab(2)}
								>
									{translate.getText('list.order_option')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={this.state.activeTab === 3}
									onClick={() => this.toggleTab(3)}
								>
									{translate.getText('list.order_commitment')}
								</NavLink>
							</NavItem>
							{
								this.props.AuthStore.hasAdmin() &&
								<NavItem>
									<NavLink
										active={this.state.activeTab === 4}
										onClick={() => this.toggleTab(4)}
									>
										{translate.getText('list.order_action')}
									</NavLink>
								</NavItem>
							}
						</Nav>
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId={0}>
								<div className="row">
									<div className="col-md-12">
										<div className="ct-content">
											<ul className="ct-timeline">
												{
													this.state.histories.map((history, i) =>
														<li className={`event ${history.status}`} key={i}>
															<div className="event-left center-item">
																<div className="event-date">
																	<Moment className="lg-date" format="DD MMMM YYYY, HH:mm">{history.create_time}</Moment>
																	<Moment className="sm-date" fromNow>{history.create_time}</Moment>
																</div>
															</div>
															<div className="event-right">
																<div className="title">{translate.getText('history_status.' + history.status)}</div>
																<div className="subtitle">{history.user.first_name} {history.user.last_name} ({history.user.title})</div>
																{
																	history.detail !== '' &&
																	<p>{history.detail}</p>
																}
															</div>
															<div className="event-icon">
																<i className={`fa-inverse fas fa-${getIcon(history.status)}`}></i>
															</div>
														</li>
													)
												}
											</ul>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1}>
								<div className="row">
									<div className="col-md-12">
										<div className="ct-content">
											<table className="table  ct-table ct-table-style-2">
												<thead>
													<tr>
														<th scope="col">#</th>
														<th scope="col">{translate.getText('list.status')}</th>
														<th scope="col">{translate.getText('list.payment_type')}</th>
														<th scope="col">{translate.getText('list.invoice_no')}</th>
														<th scope="col">{translate.getText('list.total_price')}</th>
														<th scope="col">{translate.getText('list.update_time')}</th>
														<th scope="col">{translate.getText('list.create_time')}</th>
														<th scope="col">{translate.getText('list.action')}</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.bills.map((bill, i) =>
															<tr key={i}>
																<th scope="row">{bill.id}</th>
																<td>
																	<div className={`badge badge-status ${bill.status}`}>
																		{translate.getText('bill_status.' + bill.status)}
																	</div>
																</td>
																<td>{translate.getText('payment.' + bill.payment_type)}</td>
																<td>{bill.invoice}</td>
																<td>{bill.total_price_format} ₺</td>
																<td>{bill.update_time}</td>
																<td>{bill.create_time}</td>
																<td className="ct-table-action">
																	<Link className="btn ct-button btn-info" to={`/bill/detail/${bill.id}`}>
																		<i className="fa fa-info btn-icon"></i>
																		<div className="btn-text">{translate.getText('general.detail')}</div>
																	</Link>
																</td>
															</tr>
														)
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={2}>
								<div className="row">
									<div className="col-md-12">
										<div className="ct-content">
											<table className="table  ct-table ct-table-style-2 text-center">
												<thead>
													<tr>
														<th scope="col" className="text-left">#</th>
														<th scope="col">{translate.getText('list.option_name')}</th>
														<th scope="col">{translate.getText('list.option_value')}</th>
														<th scope="col">{translate.getText('list.period')}</th>
														<th scope="col">{translate.getText('list.expiration_time')}</th>
														<th scope="col">{translate.getText('list.status')}</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.data.options.map((order_option, i) =>
															<tr key={i}>
																<th scope="row" className="text-left">{order_option.id}</th>
																<td>{translate.getText('form.option.' + order_option.product_option.name)}</td>
																<td>{translate.getText(
																	'form.option.' +
																	order_option.product_option.name +
																	'_' +
																	order_option.product_option_value.name
																).toUpperCase()}</td>
																<td>{translate.getText('order.action.payment_period_' + order_option.period)}</td>
																<td>{order_option.expiration_time}</td>
																<td>
																	<div className={`badge badge-status h_status_${order_option.status}`}>
																		{translate.getText('order_handle_status.st_' + order_option.status)}
																	</div>
																</td>
															</tr>
														)
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={3}>
								<CommitmentTabOrder />
							</TabPane>
							{
								this.props.AuthStore.hasAdmin() &&
								<TabPane tabId={4}>
									<OrderAction refresh={() => this.componentDidMount()} />
								</TabPane>
							}
						</TabContent>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DetailOrder);
