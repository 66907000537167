import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import LoadingService from '../../Services/LoadingService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import translate from '../../Services/TranslateService';
import { UncontrolledTooltip } from 'reactstrap';
import Moment from 'react-moment';
import { inject, observer } from 'mobx-react';
import { getIcon } from '../../Components/HistoryIcon';

@inject('CommonStore', 'AuthStore')
@observer
class DetailUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				distributor: '',
				title: '',
				first_name: '',
				last_name: '',
				email: '',
				create_time: '',
				status: false
			},
			histories: {
				data: [],
				filter: {
					user: this.props.match.params.id
				},
				sortable: {
					id: 'DESC'
				},
				filterChange: false,
				page: 1,
				limit: 10,
				maxPage: 1,
				total: 0
			}
		};
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/users/' + this.props.match.params.id)
				.then(data => {
					const sdata = {
						title: data.title,
						first_name: data.first_name,
						last_name: data.last_name,
						email: data.email,
						create_time: data.create_time,
						status: data.status
					};
					if (this.props.AuthStore.hasAdmin()) {
						sdata.distributor = data.distributor.company;
					}
					this.setState({ data: sdata });
					this.fetchHistories();
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('user.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		} else {
			this.props.history.push('/user/list');
		}
	}

	fetchHistories(setpage = null, setlimit = null, sortable = null) {
		let page = (setpage !== null) ? setpage : this.state.histories.page;
		const limit = (setlimit !== null) ? setlimit : this.state.histories.limit;
		sortable = this.state.histories.sortable;
		if (this.state.histories.filterChange === true) {
			page = 1;
		}
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/histories/paginate', {
				filter: this.state.histories.filter,
				page: page,
				limit: limit,
				sort: sortable
			})
			.then(response => {
				const histories = this.state.histories;
				histories.data = response.data;
				histories.page = response.page;
				histories.limit = response.limit;
				histories.maxPage = response.max_page;
				histories.total = response.total;
				histories.filterChange = false;
				this.setState({
					histories: histories
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.Add({
					message: translate.getText('history.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	render() {
		const sdata = this.state.data;
		return (
			<div className="user-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<div className="content-top-right-button">
								<Link className="btn ct-button open btn-edit" to={`/user/edit/${this.props.match.params.id}`}>
									<i className="fa fa-pen btn-icon"></i>
									<div className="btn-text">{translate.getText('general.edit')}</div>
								</Link>
								<Link className="btn ct-button open btn-delete" to={`/user/delete/${this.props.match.params.id}`}>
									<i className="fa fa-trash btn-icon"></i>
									<div className="btn-text">{translate.getText('general.delete')}</div>
								</Link>
							</div>
							<div className="content-info ct-form-container-block">
								<div className="head-info">
									<h1>{sdata.first_name} {sdata.last_name}</h1>
									<h3>{sdata.title}</h3>
									<div className={`badge badge-status status_${(sdata.status ? 1 : 0)}`}>
										{translate.getText('general.status_' + (sdata.status ? 1 : 0))}
									</div>
								</div>
								<div className="icon-info">
									<div className="row">
										{
											this.props.AuthStore.hasAdmin() &&
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-distributor">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-network-wired" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-distributor">
													{translate.getText('form.distributor')}
												</UncontrolledTooltip>
												<label>{sdata.distributor}</label>
											</div>
										}
										<div className="col-md-12">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-email">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-envelope" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-email">
												{translate.getText('form.email')}
											</UncontrolledTooltip>
											<label>{sdata.email}</label>
										</div>
										<div className="col-md-12">
											<div className="badge badge-icon" id="Tooltip-create_date">
												{sdata.create_time}
											</div>
											<UncontrolledTooltip placement="top" target="Tooltip-create_date">
												{translate.getText('form.create_date')}
											</UncontrolledTooltip>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{
						this.state.histories.data.length > 0 &&
						<div className="col-md-12">
							<div className="ct-content">
								<div className="row">
									<div className="col-md-12">
										<div className="ct-content">
											<ul className="ct-timeline">
												{
													this.state.histories.data.map((history, i) =>
														<li className={`event ${history.status}`} key={i}>
															<div className="event-left center-item">
																<div className="event-date">
																	<Moment className="lg-date" format="DD MMMM YYYY, HH:mm">{history.create_time}</Moment>
																	<Moment className="sm-date" fromNow>{history.create_time}</Moment>
																</div>
															</div>
															<div className="event-right">
																<div className="title">{translate.getText('history_status.' + history.status)}</div>
																<div className="subtitle">{history.user.first_name} {history.user.last_name} ({history.user.title})</div>
																{
																	history.detail !== '' &&
																	<p>{history.detail}</p>
																}
															</div>
															<div className="event-icon">
																<i className={`fa-inverse fas fa-${getIcon(history.status)}`}></i>
															</div>
														</li>
													)
												}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default withRouter(DetailUser);
