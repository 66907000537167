import { toast } from 'react-toastify';

class AlertService {
	success = (message, setting = false) => {
		if (setting !== false) {
			setting.message = message;
			setting.level = 'success';
		} else {
			setting = {
				message: message,
				level: 'success'
			};
		}
		this.add(setting);
	}

	info = (message, setting = false) => {
		if (setting !== false) {
			setting.message = message;
			setting.level = 'info';
		} else {
			setting = {
				message: message,
				level: 'info'
			};
		}
		this.add(setting);
	}

	error = (message, setting = false) => {
		if (setting !== false) {
			setting.message = message;
			setting.level = 'error';
		} else {
			setting = {
				message: message,
				level: 'error'
			};
		}
		this.add(setting);
	}

	warning = (message, setting = false) => {
		if (setting !== false) {
			setting.message = message;
		} else {
			setting = {
				message: message,
				level: 'warning'
			};
		}
		this.add(setting);
	}

	add(setting) {
		if (setting.type === 'console') {
			if (typeof setting.level !== 'undefined') {
				console.log(setting.level, setting.message);
			} else {
				console.log(setting.message);
			}
		} else {
			const tSetting = {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			};
			if (typeof setting.position !== 'undefined') {
				tSetting.position = setting.position;
			}
			if (typeof setting.autoDismiss !== 'undefined' && setting.autoDismiss !== true) {
				tSetting.autoClose = setting.autoDismiss;
			}
			switch (setting.level) {
				case 'success':
					toast.success(setting.message, tSetting);
					break;
				case 'info':
					toast.info(setting.message, tSetting);
					break;
				case 'error':
				case 'danger':
					toast.error(setting.message, tSetting);
					break;
				case 'warning':
					toast.warn(setting.message, tSetting);
					break;
				default:
					toast(setting.message, tSetting);
					break;
			}
		}
	}

	remove() {
		toast.dismiss();
	}
}

export default new AlertService();
