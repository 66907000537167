import React from 'react';
import { withRouter } from 'react-router-dom';
import OrderForm from './OrderForm/OrderForm';

class UpgradeOrder extends React.Component {
	render() {
		return (
			<OrderForm/>
		);
	}
}

export default withRouter(UpgradeOrder);
