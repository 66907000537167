import React from 'react';
import translate from '../../Services/TranslateService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import { Input } from 'reactstrap';
import './AutoComplete.css';

class AutoComplete extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			values: [],
			options: [],
			searchText: '',
			type: 'api',
			style: {},
			inputprop: {},
			focusKey: 0,
			disabled: '',
			defaultValue: ''
		};
		if (typeof this.props.id !== 'undefined') {
			this.state.inputprop.id = this.props.id;
		}
		if (typeof this.props.name !== 'undefined') {
			this.state.inputprop.name = this.props.name;
		}
		if (typeof this.props.options !== 'undefined') {
			this.state.values = this.props.options;
			this.state.type = 'local';
		}
		if (typeof this.props.disabled !== 'undefined') {
			this.state.disabled = this.props.disabled;
		}
		this.closeList = this.closeList.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (typeof nextProps.value !== 'undefined' && nextProps.value !== this.state.defaultValue) {
			this.setState({ defaultValue: nextProps.value });
			this.setState({
				options: [],
				focusKey: 0,
				searchText: nextProps.value,
				style: {
					fontWeight: 'bold'
				}
			});
		}
		if (typeof nextProps.disabled !== 'undefined' && nextProps.disabled !== this.state.disabled) {
			this.setState({ disabled: nextProps.disabled });
		}
	}

	componentWillMount() {
		document.addEventListener('click', this.closeList, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.closeList, false);
	}

	closeList(e) {
		if (e.target.parentElement !== null) {
			this.setState({
				options: [],
				focusKey: 0
			});
		}
	}

	handleSearchBox(e) {
		if (this.state.type === 'local') {
			const options = this.state.values.filter(opt => opt.label.indexOf(e.target.value) !== -1);
			this.setState({
				options: options
			});
		} else if (this.state.type === 'api') {
			this.state.options = [];
			const filter = (typeof this.props.api.cons_param !== 'undefined') ? this.props.api.cons_param : {};
			filter[this.props.api.param] = e.target.value;
			APIService.requests
				.getByParam(this.props.api.url, {
					filter: filter,
					page: 1,
					limit: 10
				})
				.then(response => {
					const options = [];
					response.data.forEach(element => {
						options.push({
							id: element.id,
							label: element[this.props.api.param]
						});
					});
					this.setState({
						options: options,
						focusKey: 0
					});
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('autocomplete.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
				});
		}
		if (this.state.options.length < 1) {
			this.props.onChange(null);
		}

		this.setState({
			searchText: e.target.value,
			style: {
				fontWeight: 'normal'
			}
		});
	}

	handleSelectValue(e) {
		this.props.onChange(e.id);
		this.setState({
			options: [],
			focusKey: 0,
			searchText: e.label,
			style: {
				fontWeight: 'bold'
			}
		});
	}

	handleKeyDown(e) {
		const options = this.state.options;
		let focusedValue = this.state.focusKey;
		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault();
				if (focusedValue < options.length - 1) {
					focusedValue++;
					this.setState({
						focusKey: focusedValue
					});
				}
				break;
			case 'ArrowUp':
				e.preventDefault();
				if (focusedValue > 0) {
					focusedValue--;
					this.setState({
						focusKey: focusedValue
					});
				}
				break;
			case 'Enter':
				e.preventDefault();
				this.handleSelectValue(options[focusedValue]);
				break;
			default:
				// console.log(e.key);
				break;
		}
	}

	render() {
		return (
			<div className="ct-autocomplete">
				<Input value={this.state.searchText} type="text" autoComplete="off" disabled={this.state.disabled}
					onChange={e => this.handleSearchBox(e)}
					onClick={e => this.handleSearchBox(e)}
					onKeyDown={e => this.handleKeyDown(e)}
					// onKeyUp={e => this.test(e, 'onKeyUp')}
					style={this.state.style} {...this.state.inputprop} ></Input>
				<ul id="ct-autocomplete-list" className="ct-autocomplete-items" hidden={this.state.options.length < 1}>
					{
						this.state.options.map((e, i) =>
							<li key={i} onClick={() => this.handleSelectValue(e)} className={`${this.state.focusKey === i ? 'on-focus' : ''}`}>{e.label}</li>
						)
					}
				</ul>
			</div>
		);
	}
}

export default AutoComplete;

