import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import LoadingService from '../../Services/LoadingService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import translate from '../../Services/TranslateService';
import { UncontrolledTooltip } from 'reactstrap';
import { inject, observer } from 'mobx-react';

@inject('CommonStore', 'AuthStore')
@observer
class DetailDealer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				company: '',
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				address: '',
				country: '',
				city: '',
				create_time: '',
				distributor: '',
				status: false
			},
			orders: {
				data: [],
				filter: {
					dealer: this.props.match.params.id
				},
				sortable: {
					id: 'DESC'
				},
				filterChange: false,
				page: 1,
				limit: 10,
				maxPage: 1,
				total: 0
			}
		};
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/dealers/' + this.props.match.params.id)
				.then(data => {
					const sdata = {
						company: data.company,
						first_name: data.first_name,
						last_name: data.last_name,
						email: data.email,
						phone: data.phone,
						country: data.country.name,
						city: data.city.name,
						address: data.address,
						create_time: data.create_time,
						status: data.status
					};
					if (this.props.AuthStore.hasAdmin()) {
						sdata.distributor = data.distributor.company;
					}
					this.setState({ data: sdata });
					this.fetchOrders();
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('dealer.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		} else {
			this.props.history.push('/dealer/list');
		}
	}

	fetchOrders(setpage = null, setlimit = null, sortable = null) {
		let page = (setpage !== null) ? setpage : this.state.orders.page;
		const limit = (setlimit !== null) ? setlimit : this.state.orders.limit;
		sortable = this.state.orders.sortable;
		if (this.state.orders.filterChange === true) {
			page = 1;
		}
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/orders/paginate', {
				filter: this.state.orders.filter,
				page: page,
				limit: limit,
				sort: sortable
			})
			.then(response => {
				const orders = this.state.orders;
				orders.data = response.data;
				orders.page = response.page;
				orders.limit = response.limit;
				orders.maxPage = response.max_page;
				orders.total = response.total;
				orders.filterChange = false;
				this.setState({
					orders: orders
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.Add({
					message: translate.getText('order.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	render() {
		const sdata = this.state.data;
		return (
			<div className="dealer-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<div className="content-top-right-button">
								<Link className="btn ct-button open btn-edit" to={`/dealer/edit/${this.props.match.params.id}`}>
									<i className="fa fa-pen btn-icon"></i>
									<div className="btn-text">{translate.getText('general.edit')}</div>
								</Link>
								<Link className="btn ct-button open btn-delete" to={`/dealer/delete/${this.props.match.params.id}`}>
									<i className="fa fa-trash btn-icon"></i>
									<div className="btn-text">{translate.getText('general.delete')}</div>
								</Link>
							</div>
							<div className="content-info ct-form-container-block">
								<div className="head-info">
									<h1>{sdata.company}</h1>
									<h3>{sdata.first_name} {sdata.last_name}</h3>
									<div className={`badge badge-status status_${(sdata.status ? 1 : 0)}`}>
										{translate.getText('general.status_' + (sdata.status ? 1 : 0))}
									</div>
								</div>
								<div className="icon-info">
									<div className="row">
										{
											this.props.AuthStore.hasAdmin() &&
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-distributor">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-network-wired" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-distributor">
													{translate.getText('form.distributor')}
												</UncontrolledTooltip>
												<label>{sdata.distributor}</label>
											</div>
										}
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-phone">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-phone" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-phone">
												{translate.getText('form.phone')}
											</UncontrolledTooltip>
											<label>{sdata.phone}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-email">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-envelope" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-email">
												{translate.getText('form.email')}
											</UncontrolledTooltip>
											<label>{sdata.email}</label>
										</div>
										<div className="col-md-12">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-address">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-map-marker-alt" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-address">
												{translate.getText('form.address')}
											</UncontrolledTooltip>
											<label>{sdata.address}</label>
										</div>
										<div className="col-md-6">
											<div className="badge badge-icon" id="Tooltip-country">
												{sdata.country} / {sdata.city}
											</div>
											<UncontrolledTooltip placement="top" target="Tooltip-country">
												{translate.getText('form.country')} / {translate.getText('form.city')}
											</UncontrolledTooltip>
										</div>
										<div className="col-md-6">
											<div className="badge badge-icon" id="Tooltip-create_date">
												{sdata.create_time}
											</div>
											<UncontrolledTooltip placement="top" target="Tooltip-create_date">
												{translate.getText('form.create_date')}
											</UncontrolledTooltip>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{
						this.state.orders.data.length > 0 &&
						<div className="col-md-12">
							<div className="ct-content">
								<div className="row">
									<div className="col-md-12">
										<div className="ct-content">
											<table className="table  ct-table ct-table-style-2 text-center">
												<thead>
													<tr>
														<th scope="col" className="text-left">#</th>
														<th scope="col">{translate.getText('list.status')}</th>
														<th scope="col">{translate.getText('list.company_name')}</th>
														<th scope="col">{translate.getText('list.product')}</th>
														<th scope="col">{translate.getText('list.expiration_time')}</th>
														<th scope="col">{translate.getText('list.action')}</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.orders.data.map((e, i) =>
															<tr key={i}>
																<th scope="row" className="text-left">{e.id}</th>
																<td>
																	<div className={`badge badge-status ${e.status}`}>
																		{translate.getText('order_status.' + e.status)}
																	</div>
																</td>
																<td className="text-left">
																	<span className="green-title">{e.company}</span>
																	<p>{e.email}</p>
																</td>
																<td>
																	<span className="bold-title">{translate.getText('order.license.' + e.product.license)}</span>
																	<p>{translate.getText('order.license_text.' + e.product.license)}</p>
																</td>
																<td>
																	<span className="bold-title">{(e.expiration_time !== null) ? e.expiration_time : 'N/A'}</span>
																</td>
																<td className="ct-table-action btn-3">
																	<Link className="btn ct-button btn-edit" to={`/order/edit/${e.id}`}>
																		<i className="fa fa-pen btn-icon"></i>
																		<div className="btn-text">{translate.getText('general.edit')}</div>
																	</Link>
																	<Link className="btn ct-button btn-info" to={`/order/detail/${e.id}`}>
																		<i className="fa fa-info btn-icon"></i>
																		<div className="btn-text">{translate.getText('general.detail')}</div>
																	</Link>
																	<Link className="btn ct-button btn-modify" to={`/order/upgrade/${e.id}`}>
																		<i className="fa fa-sync-alt btn-icon"></i>
																		<div className="btn-text">{translate.getText('general.upgrade')}</div>
																	</Link>
																</td>
															</tr>
														)
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default withRouter(DetailDealer);
