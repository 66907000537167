import React from 'react';
import './loading.css';
import { inject, observer } from 'mobx-react';

@inject('CommonStore')
@observer
class Loading extends React.Component {
	constructor(props) { // eslint-disable-line
		super(props);
	}

	render() {
		return (
			<div className={`wr-loading ${!this.props.status ? 'show' : 'hide'}`}>
				<div className="wr-loading-content">
					<img src={ require('../../Assets/images/loading.svg') } alt="Loading" />
				</div>
			</div>
		);
	}
}

export default Loading;


