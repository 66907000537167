import React from 'react';
import './BoxSelect.css';

class BoxSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputprop: {},
			value: null
		};
		if (typeof this.props.id !== 'undefined') {
			this.state.inputprop.id = this.props.id;
		}
		if (typeof this.props.name !== 'undefined') {
			this.state.inputprop.name = this.props.name;
		}
		if (typeof this.props.value !== 'undefined') {
			this.state.value = this.props.value;
		}
	}

	handleSelectValue(e) {
		if (this.state.value === e.props.value && this.props.nonselect === true) {
			this.props.onChange(null);
			this.setState({
				value: null
			});
		} else {
			this.props.onChange(e.props.value);
			this.setState({
				value: e.props.value
			});
		}
	}

	render() {
		return (
			<div className="row ct-boxselect">
				{
					this.props.children.map((e, i) =>
						<div key={i} className={`col ct-boxselect-item${e.props.value === this.state.value ? ' selected' : ''}`} onClick={() => this.handleSelectValue(e)}>
							{e.props.children}
							<span>{e.props.subValue}</span>
							{
								(typeof e.props.list !== 'undefined' && e.props.list.length > 0) &&
								<ul>
									{
										e.props.list.map((el, il) =>
											<li key={il}><span>{el.key}</span>{el.value}</li>
										)
									}
								</ul>
							}
						</div>
					)
				}
			</div>
		);
	}
}

export default BoxSelect;

