import translate from '../../Services/TranslateService';
import AuthStore from '../../Stores/AuthStore';

const navmenuitem = () => {
	const list = [];
	list.push({
		name: translate.getText('navigation.home'),
		url: '/home',
		// icon: 'ct-icon ct-icon-sm ct-home',
		icon: 'ct-icon ct-icon-sm fa fa-columns'
	});
	if (AuthStore.hasAdmin()) {
		list.push({
			name: translate.getText('navigation.distributor'),
			url: '/distributor',
			// icon: 'ct-icon ct-icon-sm ct-distributor',
			icon: 'ct-icon ct-icon-sm fa fa-sitemap'
		});
	}
	list.push({
		name: translate.getText('navigation.dealer'),
		url: '/dealer',
		// icon: 'ct-icon ct-icon-sm ct-distributor'
		icon: 'ct-icon ct-icon-sm fa fa-bezier-curve'
	},
	{
		name: translate.getText('navigation.order'),
		url: '/order',
		// icon: 'ct-icon ct-icon-sm ct-order'
		icon: 'ct-icon ct-icon-sm fa fa-users'
	},
	{
		name: translate.getText('navigation.bill'),
		url: '/bill',
		// icon: 'ct-icon ct-icon-sm ct-bill'
		icon: 'ct-icon ct-icon-sm fa fa-file-invoice-dollar'
	},
	{
		name: translate.getText('navigation.user'),
		url: '/user',
		icon: 'ct-icon ct-icon-sm fa fa-id-badge'
	});
	return {
		items: list
	};
};
export default navmenuitem;

