export default {
	login: {
		input: {
			username: 'Username',
			password: 'Password',
			email: 'E-mail',
			newpassword: 'New password',
			newpassword2: 'Re-enter new password'
		},
		buttonlogin: 'LOGIN',
		rememberme: 'Remember Me',
		forgotpassword: 'Forgot password?',
		sendlink: 'Receive Recover Link',
		forgotmessage: 'Recover link has been sent to your email address.',
		expired_token: 'Recover link has been expired.',
		approve: 'Approve',
		changepasswordmessage: 'Password has been changed successfully.'
	},
	navigation: {
		home: 'HOME',
		dealer: 'DEALERS',
		dealer_list: 'ALL DEALERS',
		order: 'ORDERS',
		order_list: 'ALL ORDERS',
		/* distributor: 'DISTRIBUTORS',
		demo_requests: 'DEMO REQUESTS',
		license_requests: 'LICENSE REQUESTS',
		current_information: 'CURRENT INFO',
		products: 'PRODUCTS',
		settings: 'SETTINGS',*/
		profile: 'Profile',
		logout: 'Log Out'
	},
	dealers: {
		title: {
			list: 'DEALERS',
			add: 'ADD DEALER',
			edit: 'EDIT DEALER'
		},
		form: {
			company: 'Company',
			first_name: 'First Name',
			last_name: 'Last Name',
			email: 'E-Mail',
			phone: 'Phone',
			address: 'Address',
			status: 'Status',
			savemessage: 'Dealer saved successfully.',
			statuschanged: 'Dealer status changed.',
			listfilter: 'Search for company or authorized person',
			adddealer: 'Add Dealer',
			error: {
				unexpected_error: 'An unexpected error has occurred!',
				validation: 'Validation failed!',
				mustmoveorders: 'Orders must be move to another dealer!'
			}
		},
		button: {
			customer_list: 'Customer List',
			distributor: 'Distributor'
		}
	},
	order: {
		list: {
			title: 'PRODUCTS',
			status: 'STATUS',
			company_name: 'COMPANY',
			dealer: 'DEALER',
			product: 'PRODUCT DETAIL',
			expiration_time: 'LICENSE EXPIRATION',
			action: 'OPERATION'
		},
		form: {
			title: 'ORDER CRATE',
			title_package: 'PACKAGE DETAIL',
			savemessage: 'Your Order Created Successfully.',
			addorder: 'ORDER CRATE'
		},
		license_list: {
			user: 'USER',
			device: 'DEVICE',
			session: 'SESSION',
			sms: 'SMS',
			pms: 'PMS'
		},
		error: {},
		license: {
			demo: 'Demo',
			silver: 'Silver Package',
			gold: 'Gold Package',
			platinum: 'Platinum Package',
			titanium: 'Titanium Package',
			diamond: 'Diamond Package'
		},
		license_text: {
			demo: '250 User + PMS + Social Media',
			silver: '250 User + PMS + Social Media',
			gold: '250 User + PMS + Social Media',
			platinum: '250 User + PMS + Social Media',
			titanium: '250 User + PMS + Social Media',
			diamond: '250 User + PMS + Social Media'
		}
	},
	order_status: {
		pending: 'APPROVAL PENDING',
		/* confirmpayment: 'PAYING APPROVAL',
		approved: 'APPROVED',*/
		cancelled: 'CANCELLED',
		success: 'ACTIVE'
	},
	alert: {
		danger: 'Danger!',
		info: 'Info!',
		success: 'Success',
		warning: 'Warning'
	},
	error: {
		error_maxlength: 'Max lenght has been exceeded!',
		error_minlength: 'Unsufficent Character Count',
		error_required: 'Required Field',
		error_invalidemail: 'Invalid Mail Address',
		error_weakpassword: 'Weak Password',
		error_isnumber: 'Only numeric characters allowed',
		error_minnumber: 'This field must be in range %s. Please enter greater number.',
		error_maxnumber: 'This field must be in range %s. Please enter less number.',
		error_match: 'This field does not match.',
		error_invalidurl: 'Invalid Url',
		error_invalidalpha: 'Invalid Alphabetical String',
		error_invalidmac: 'Invalid MAC address',
		error_invalidip: 'Invalid IP address',
		error_invaliddomain: 'Invalid domain address',
		error_selected: 'Required Field',
		invalid_user: 'User not found.',
		invalid_username_password: 'Invalid username or password!',
		invalid_password: 'Invalid password',
		usernamecombnotselected: 'Username combination is required.',
		templatenotselected: 'User template is required.',
		authtypenotselected: 'Auth type is required.',
		error_license_pms: 'Upgrade license to use database integration.',
		invalid_oldpassword: 'In order to change your password, you must enter your old password correctly.',
		token_expired: 'Request expired.',
		license_expired: 'License has been expired.',
		device: {
			device_limit_error: 'You have reached the device limit!'
		}
	},
	general: {
		selectall: 'Select All',
		select: 'Select',
		on: 'On',
		off: 'Off',
		active: 'Active',
		deactive: 'Passive',
		successful: 'Success',
		unsuccessful: 'Unsuccess',
		approved: 'Approved',
		disapproved: 'Disapproved',
		deleteconfirm: 'Please confirm delete.',
		add: 'Add',
		save: 'Save',
		edit: 'Edit',
		detail: 'Details',
		delete: 'Delete',
		next: 'Next',
		prev: 'Previous',
		first: 'First',
		last: 'Last',
		all: 'All',
		apply_filter: 'Filter',
		back: 'Back',
		selectplaceholder: 'Select...',
		choosefile: 'Choose file...',
		male: 'MALE',
		female: 'FEMALE',
		other: 'OTHER',
		unknown: 'UNKNOWN',
		none: 'NONE',
		status: 'Status',
		pagination: {
			info: 'Between %s entries, a total of %s-%s records are listed.',
			nodata: 'No data found.'
		},
		advanced_filter: 'Advanced Filter',
		filterinput: 'Search...'
	},
	form: {
		company: 'Company',
		first_name: 'First Name',
		last_name: 'Last Name',
		email: 'E-Mail',
		phone: 'Phone',
		mobile_phone: 'GSM',
		address: 'Address',
		country: 'Country',
		city: 'City',
		website: 'Web Site',
		dealer: 'Dealer',
		username: 'Username',
		option: {
			license: 'License Type',
			license_demo: 'Demo',
			license_silver: 'Silver',
			license_gold: 'Gold',
			license_platinum: 'Platinum',
			license_titanium: 'Titanium',
			license_diamond: 'Diamond',
			sms: 'Additional SMS',
			sms_100_sms: '100',
			sms_500_sms: '500',
			sms_1000_sms: '1.000',
			sms_5000_sms: '5.000',
			sms_10000_sms: '10.000',
			session: 'Additional Credit',
			session_10000_session: '10.000',
			session_20000_session: '20.000',
			session_50000_session: '50.000'
		}
	},
	notification: {
		title: 'Notifications',
		warning: {
			left_sms: 'SMS usage limit has reached the threshold value.',
			left_session: 'Session usage limit has reached the threshold value.'
		},
		danger: {
			left_sms: 'Remaining SMS usage limits at critical levels.',
			left_session: 'Remaining session usage limits at critical levels.'
		}
	}
};
