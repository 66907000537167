import React, { Component, Suspense } from 'react';
import { Container } from 'reactstrap';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import '@coreui/coreui/dist/css/coreui.css';
import './navigation.css';
import {
	AppAside,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarNav
} from '@coreui/react';
import navigation from './_nav';
import routes from '../../routes';
const DefaultHeader = React.lazy(() => import('./NavigationHeader'));

@inject('AuthStore')
@observer
class Navigation extends Component {
	constructor(props) {
		super(props);
	}


	loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>;
	render() {
		return (
			<div className="app">
				<AppHeader fixed>
					<Suspense fallback={this.loading()}>
						<DefaultHeader />
					</Suspense>
				</AppHeader>
				<div className="app-body">
					<AppSidebar fixed display="lg">
						<AppSidebarHeader />
						<AppSidebarForm />
						<Suspense>
							<AppSidebarNav navConfig={navigation()} {...this.props} />
						</Suspense>
						<AppSidebarFooter />
					</AppSidebar>
					<main className="main">
						<div className="master-content">
							<Container fluid>
								<Suspense >
									<Switch>
										{routes().map((route, idx) => route.component ? (
											<Route
												key={idx}
												path={route.path}
												exact={route.exact}
												name={route.name}
												render={props => (
													<route.component {...props} />
												)} />
										) : (null))}
										<Redirect from="/" to="/home" />
									</Switch>
								</Suspense>
							</Container>
						</div>
					</main>
					<AppAside fixed>
						<Suspense fallback={this.loading()}>
						</Suspense>
					</AppAside>
				</div>
			</div>
		);
	}
}

export default withRouter(Navigation);
