import React from 'react';
import Login from './Pages/Login/Login';
import ForgotPassword from './Pages/Login/ForgotPassword';
import RecoverPassword from './Pages/Login/RecoverPassword';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

class Auth extends React.Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/forgotpassword" component={ForgotPassword} />
					<Route path="/recoverpassword/:token" component={RecoverPassword} />
					<Route exact path="*" render={() => (<Redirect to="/login" />)} />
				</Switch>
			</Router>
		);
	}
}

export default Auth;
