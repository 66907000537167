import React from 'react';
import { withRouter } from 'react-router-dom';
import DistributorForm from './DistributorForm/DistributorForm';

class EditDistributor extends React.Component {
	render() {
		return (
			<DistributorForm/>
		);
	}
}

export default withRouter(EditDistributor);
