import { action, observable } from 'mobx';

class _CommonStore {
	@observable appName = 'CRM';
	@observable appLanguage = this.getlanguage();
	@observable errors = [];
	@observable loading = {};
	@observable commonDataMap = {
	};

	@action changeLanguage(appLanguage){
		this.appLanguage = appLanguage;
	}

	@action getlanguage(){
		const slang = window.localStorage.getItem('language');
		if (slang === null){
			let lang = '';
			const language = navigator.language || navigator.userLanguage;
			if (language === 'tr'){
				lang = 'tr';
			} else if (language.indexOf('tr-') !== -1){
				lang = 'tr';
			} else {
				lang = 'tr';
			}
			/**
			 else if (language === 'en'){
				lang = 'en';
			} else if (language.indexOf('en-') !== -1){
				lang = 'en';
			} else {
				lang = 'en';
			}
			*/
			window.localStorage.setItem('language', lang);
			return lang;
		}
		return slang;
	}

	@action async setLoading(key, val){
		this.loading[key] = val;
	}
}

const CommonStore = new _CommonStore();
export default CommonStore;
