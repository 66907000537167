import React from 'react';
import Chart from 'chart.js';
import translate from '../../../Services/TranslateService';
import './chart.css';
import LoadingService from '../../../Services/LoadingService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';

class Chart4 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			color: [
				{
					rgb: 'rgb(255, 99, 132)',
					rgba: 'rgba(255, 99, 132,.2)'
				},
				{
					rgb: 'rgb(54, 162, 235)',
					rgba: 'rgba(54, 162, 235,.2)'
				},
				{
					rgb: 'rgb(153, 102, 255)',
					rgba: 'rgba(153, 102, 255,.2)'
				},
				{
					rgb: 'rgb(255, 159, 64)',
					rgba: 'rgba(255, 159, 64,.2)'
				},
				{
					rgb: 'rgb(201, 203, 207)',
					rgba: 'rgba(201, 203, 207,.2)'
				},
				{
					rgb: 'rgb(255, 205, 86)',
					rgba: 'rgba(255, 205, 86,.2)'
				},
				{
					rgb: 'rgb(75, 192, 192)',
					rgba: 'rgba(75, 192, 192,.2)'
				}
			],
			legends: []
		};
		this.chart4Ref = React.createRef();
	}

	componentDidMount() {
		const config = {
			type: 'pie',
			data: {
				labels: [],
				datasets: [{
					data: [],
					backgroundColor: [],
					borderColor: [],
					label: ''
				}]
			},
			options: {
				responsive: true,
				legend: false,
				legendCallback: function (chart) {
					const text = [];
					for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
						text[i] = {
							backgroundColor: chart.data.datasets[0].backgroundColor[i],
							borderColor: chart.data.datasets[0].borderColor[i],
							label: chart.data.labels[i]
						};
					}
					return text;
				},
				hover: {
					mode: 'index'
				},
				tooltips: {
					mode: 'index',
					intersect: false
				}
			}
		};
		this.chart4 = new Chart(this.chart4Ref.current, config);
		this.chart4.aspectRatio = 0;
		this.chart4.maintainAspectRatio = true;

		this.loadChart();
	}

	loadChart() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/reports/license-3')
			.then(response => {
				this.chart4.data.labels = [];
				response.forEach((e, j) => {
					this.chart4.data.labels.push(e.text);
					this.chart4.data.datasets[0].data.push(e.value);
					this.chart4.data.datasets[0].backgroundColor.push(this.state.color[j].rgb);
					this.chart4.data.datasets[0].borderColor.push(this.state.color[j].rgba);
				});
				this.chart4.update();
				this.setState({
					legends: this.chart4.generateLegend()
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('report.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	hideLegend(e, i) {
		e.target.classList.toggle('unselect-legend');
		const meta = this.chart4.getDatasetMeta(0);
		meta.data[i].hidden = !meta.data[i].hidden;
		this.chart4.update();
	}

	render() {
		return (
			<div className="ct-chart bg-white">
				<div className="top-toolbar">
					<div className="legends full-legends">
						<ul className="legends-list">
							{
								(typeof this.chart4 !== 'undefined') &&
								this.state.legends.map((e, i) =>
									<li key={i}
										onClick={(el => this.hideLegend(el, i))}
										style={{
											backgroundColor: e.backgroundColor,
											borderColor: e.borderColor
										}}>{translate.getText('order.license.' + e.label)}</li>
								)
							}
						</ul>
					</div>
				</div>
				<canvas ref={this.chart4Ref} className="chart4" />
			</div>
		);
	}
}

export default Chart4;
