import { observable, action, reaction } from 'mobx';
import jwt_decode from 'jwt-decode';

class _AuthStore {
	@observable accessToken = window.localStorage.getItem('accessToken');
	@observable userData = JSON.parse(window.localStorage.getItem('userData'));
	@observable distributorData = JSON.parse(window.localStorage.getItem('distributorData'));
	@observable userId = null;
	@observable isLogged = this.isLoggedIn();

	constructor() {
		reaction(
			() => this.accessToken,
			accessToken => {
				if (accessToken) {
					window.localStorage.setItem('accessToken', accessToken);
				} else {
					window.localStorage.removeItem('accessToken');
				}
			}
		);
	}

	@action setToken(token) {
		window.localStorage.setItem('accessToken', token);
		this.accessToken = token;
	}

	@action getToken() {
		return this.accessToken;
	}

	@action setUser(userData) {
		window.localStorage.setItem('userData', JSON.stringify(userData));
		this.userData = userData;
	}

	@action getUser() {
		return this.userData;
	}

	@action setDistributor(distributorData) {
		window.localStorage.setItem('distributorData', JSON.stringify(distributorData));
		this.distributorData = distributorData;
	}

	@action getDistributor() {
		return this.distributorData;
	}

	@action hasAdmin() {
		if (this.accessToken === null) {
			return false;
		}
		const decodedToken = jwt_decode(this.accessToken);
		return decodedToken.admin;
	}

	/* @action getUserLicense(){
		const decodedToken = jwt_decode(this.accessToken);
		return decodedToken.data.license;
	}*/

	@action isLoggedIn() {
		if (this.accessToken !== '' && this.accessToken !== null && !this.isExpiredToken(this.accessToken)) {
			return true;
		}
		window.localStorage.removeItem('accessToken');
		return false;
	}

	@action isExpiredToken(token) {
		const decodedToken = jwt_decode(token);
		if (typeof decodedToken.user === 'undefined') {
			return true;
		}
		this.userId = decodedToken.user.id;
		const now = new Date().getTime();
		decodedToken.exp *= 1000;
		if (decodedToken.exp < now) {
			return true;
		}
		return false;
	}

	@action setLoginProps(token, user, distributor) {
		this.setToken(token);
		this.setUser(user);
		this.setDistributor(distributor);
		this.isLogged = true;
	}

	@action clearLoginProps() {
		this.setToken('');
		this.setUser({});
		this.setDistributor({});
		this.isLogged = false;
	}
}

const AuthStore = new _AuthStore();
export default AuthStore;
