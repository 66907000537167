// eslint-disable-next-line no-unused-vars
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import AuthStore from './Stores/AuthStore';
import CommonStore from './Stores/CommonStore';
import App from './App';

const stores = {
	AuthStore,
	CommonStore
};
ReactDOM.render(<Provider {...stores}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
