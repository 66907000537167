import React from 'react';
import './chart.css';
import LoadingService from '../../../Services/LoadingService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';
import TranslateService from '../../../Services/TranslateService';

class Chart3 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			percentage_total: 0
		};
	}

	componentDidMount() {
		this.loadChart();
	}

	loadChart() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/reports/license-2')
			.then(response => {
				const data = [];
				const percentage_total = response.total;
				data.push({
					label: 'expired',
					data: response.expired
				});
				data.push({
					label: 'expiring',
					data: response.expiring
				});
				data.push({
					label: 'pending',
					data: response.pending
				});
				data.push({
					label: 'active',
					data: response.active
				});
				data.push({
					label: 'deactive',
					data: response.deactive
				});
				data.push({
					label: 'cancelled',
					data: response.cancelled
				});
				this.setState({
					percentage_total: percentage_total,
					data: data
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: TranslateService.getText('report.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	render() {
		const defaultcolor = ['#24537e', '#3dc5c4', '#b7dd71', '#f0e08a', '#f19134', '#4d4d4d', '#da18cb'];
		const { data, percentage_total } = this.state;
		let k = 0;
		data.forEach((e, i) => {
			if (typeof data[i].color === 'undefined') {
				data[i].color = (typeof defaultcolor[k] === 'undefined') ? '#000' : defaultcolor[k];
				k++;
			}
			data[i].percentage = e.data / percentage_total * 100;
			data[i].percentage = (data[i].percentage > 0) ? data[i].percentage.toFixed(2) : 0;
		});

		return (
			<div className="row">
				{
					data.map((element, i) =>
						<div key={i} className="col-md-12 ct-barchart">
							<div className="ct-barchart-info">
								<div className="text-left">
									<span>
										{TranslateService.getText('home.license_chart.' + element.label)}:
									</span>
								</div>
								<div className="text-right">
									<span>{element.data} (%{element.percentage})</span>
								</div>
							</div>
							<div className="baseline">
								<div className="percentage" style={{
									width: element.percentage + '%',
									backgroundColor: element.color
								}}></div>
							</div>
						</div>
					)
				}
			</div>
		);
	}
}

export default Chart3;
