import React from 'react';
import { withRouter } from 'react-router-dom';
import Chart from 'chart.js';
import translate from '../../../Services/TranslateService';
import './chart.css';
import LoadingService from '../../../Services/LoadingService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';

class Chart5 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			color: [
				{
					rgb: 'rgb(255, 99, 132)',
					rgba: 'rgba(255, 99, 132,.2)'
				},
				{
					rgb: 'rgb(54, 162, 235)',
					rgba: 'rgba(54, 162, 235,.2)'
				},
				{
					rgb: 'rgb(153, 102, 255)',
					rgba: 'rgba(153, 102, 255,.2)'
				},
				{
					rgb: 'rgb(255, 159, 64)',
					rgba: 'rgba(255, 159, 64,.2)'
				},
				{
					rgb: 'rgb(201, 203, 207)',
					rgba: 'rgba(201, 203, 207,.2)'
				},
				{
					rgb: 'rgb(255, 205, 86)',
					rgba: 'rgba(255, 205, 86,.2)'
				},
				{
					rgb: 'rgb(75, 192, 192)',
					rgba: 'rgba(75, 192, 192,.2)'
				}
			],
			legends: []
		};
		this.chart5Ref = React.createRef();
	}

	componentDidMount() {
		const config = {
			type: 'bar',
			data: {
				labels: [],
				datasets: [{
					label: 'Müşteri',
					borderWidth: 1,
					data: []
				}]
			},
			options: {
				responsive: true,
				legend: false,
				hover: {
					mode: 'index'
				},
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: false,
							labelString: 'Bayi'
						},
						gridLines: {
							display: false
						}
					}],
					yAxes: [{
						display: true,
						position: 'right',
						scaleLabel: {
							display: false,
							labelString: 'Değer'
						},
						ticks: {
							beginAtZero: true
						}
					}]
				},
				title: {
					display: false,
					text: 'Dealer Chart'
				},
				elements: {
					rectangle: {
						backgroundColor: this.colorize.bind(this, false),
						borderColor: this.colorize.bind(this, true),
						borderWidth: 1
					}
				}
			}
		};
		this.chart5 = new Chart(this.chart5Ref.current, config);
		this.chart5.aspectRatio = 0;
		this.chart5.maintainAspectRatio = true;

		this.loadChart();
	}

	colorize(opaque, ctx) {
		return opaque ? this.state.color[ctx.dataIndex].rgb : this.state.color[ctx.dataIndex].rgba;
	}

	loadChart() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/reports/dealer-1')
			.then(response => {
				this.chart5.data.labels = [];
				response.forEach(e => {
					this.chart5.data.labels.push(e.text);
					this.chart5.data.datasets[0].data.push(e.value);
				});
				this.chart5.update();
				this.setState({
					legends: this.chart5.generateLegend()
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('report.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	render() {
		return (
			<div className="ct-chart bg-white">
				<canvas ref={this.chart5Ref} className="chart5" />
			</div>
		);
	}
}

export default withRouter(Chart5);
