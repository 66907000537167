import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { InputGroup, CustomInput, Input, FormGroup, Button } from 'reactstrap';
import Moment from 'react-moment';
import APIService from '../../Services/APIService';
import translate from '../../Services/TranslateService';
import AlertService from '../../Services/AlertService';
import LoadingService from '../../Services/LoadingService';

@inject('CommonStore', 'AuthStore')
@observer
class ListUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			filter: {},
			sortable: {},
			filterChange: false,
			page: 1,
			limit: 10,
			maxPage: 1,
			total: 0,
			pagination: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	changeSortable(key, event) {
		Array.from(ReactDOM.findDOMNode(event.target).parentNode.children).forEach(subEvent => {
			subEvent.classList.remove('down');
			subEvent.classList.remove('up');
		});
		const sortable = {};
		if (typeof this.state.sortable[key] !== undefined && this.state.sortable[key] === 'DESC') {
			event.target.classList.add('up');
			sortable[key] = 'ASC';
		} else {
			event.target.classList.add('down');
			sortable[key] = 'DESC';
		}
		this.fetchData(1, null, sortable);
	}

	changeFilter(e) {
		const filter = this.state.filter;
		filter[e.target.id] = e.target.value;
		this.setState({
			filter: filter,
			filterChange: true
		});

		this.fetchData(); // Remove it for more performance
	}

	changePageLimit(i) {
		this.fetchData(1, i);
	}

	changePage(i) {
		this.fetchData(i);
	}

	fetchData(setpage = null, setlimit = null, sortable = null) {
		let page = (setpage !== null) ? setpage : this.state.page;
		const limit = (setlimit !== null) ? setlimit : this.state.limit;
		if (sortable !== null) {
			this.setState({
				sortable: sortable
			});
		} else {
			sortable = this.state.sortable;
		}
		if (this.state.filterChange === true) {
			page = 1;
		}

		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/users/paginate', {
				filter: this.state.filter,
				page: page,
				limit: limit,
				sort: sortable
			})
			.then(response => {
				this.setState({
					data: response.data,
					page: response.page,
					limit: response.limit,
					maxPage: response.max_page,
					total: response.total,
					filterChange: false
				});
				this.pageNumberList();
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.Add({
					message: translate.getText('user.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	pageNumberList() {
		const page = this.state.page;
		const numbers = [];
		for (let i = (page - 5 < 1) ? 1 : page - 5; i < page + 6; i++) {
			if (i > this.state.maxPage) {
				break;
			}
			numbers.push(i);
		}
		this.setState({
			pagination: numbers
		});
	}

	render() {
		return (
			<div className="user-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content oc-left-list">
							<h2>{translate.getText('user.title.list')}</h2>
							<div className="ct-row">
								<div className="ct-content-toolbar">
									<FormGroup className="standart-top-filter">
										<InputGroup>
											<Input
												onChange={e => this.changeFilter(e)}
												id="email" name="email" type="text"
												placeholder={translate.getText('general.filterinput')} />
											<Button className="btn-sm btn-success" onClick={() => this.props.history.push('/user/new')}>
												<i className="fa fa-plus-circle"></i> {translate.getText('user.action.adduser')}
											</Button>
										</InputGroup>
									</FormGroup>
								</div>
								<div className="ct-content-list">
									<table className="table ct-table ct-table-style-1">
										<thead className="ct-thead">
											<tr>
												<th scope="col">#</th>
												<th scope="col" onClick={e => this.changeSortable('status', e)} className="sortable">
													{translate.getText('list.status')}
												</th>
												{
													this.props.AuthStore.hasAdmin() &&
													<th scope="col" onClick={e => this.changeSortable('distributor', e)} className="sortable">
														{translate.getText('list.distributor')}
													</th>
												}
												<th scope="col" onClick={e => this.changeSortable('title', e)} className="sortable">
													{translate.getText('list.title')}
												</th>
												<th scope="col" onClick={e => this.changeSortable('first_name', e)} className="sortable">
													{translate.getText('list.first_name')}
												</th>
												<th scope="col" onClick={e => this.changeSortable('last_name', e)} className="sortable">
													{translate.getText('list.last_name')}
												</th>
												<th scope="col" onClick={e => this.changeSortable('email', e)} className="sortable">
													{translate.getText('list.email')}
												</th>
												<th scope="col" onClick={e => this.changeSortable('update_time', e)} className="sortable">
													{translate.getText('list.update_time')}
												</th>
												<th scope="col" onClick={e => this.changeSortable('create_time', e)} className="sortable">
													{translate.getText('list.create_time')}
												</th>
												<th scope="col">
													{translate.getText('list.action')}
												</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.data.map((e, i) =>
													<tr key={i}>
														<th scope="row">{e.id}</th>
														<td>
															<div className={`badge badge-status status_${e.status}`}>
																{translate.getText('general.' + (e.status === 1 ? 'active' : 'deactive'))}
															</div>
														</td>
														{
															this.props.AuthStore.hasAdmin() &&
															<td className="text-left">
																<div className="badge badge-primary">{e.distributor.company}</div>
															</td>
														}
														<td>
															<span className="bold-title">{e.title}</span>
														</td>
														<td>
															<span className="bold-title">{e.first_name}</span>
														</td>
														<td>
															<span className="bold-title">{e.last_name}</span>
														</td>
														<td>
															<span className="bold-title">{e.email}</span>
														</td>
														<td>
															<span className="bold-title">{e.update_time}</span>
															<p><Moment fromNow>{e.update_time}</Moment></p>
														</td>
														<td>
															<span className="bold-title">{e.create_time}</span>
															<p><Moment fromNow>{e.create_time}</Moment></p>
														</td>
														<td className="ct-table-action btn-2">
															<Link className="btn ct-button btn-edit" to={`/user/edit/${e.id}`}>
																<i className="fa fa-pen btn-icon"></i>
																<div className="btn-text">{translate.getText('general.edit')}</div>
															</Link>
															<Link className="btn ct-button btn-info" to={`/user/detail/${e.id}`}>
																<i className="fa fa-info btn-icon"></i>
																<div className="btn-text">{translate.getText('general.detail')}</div>
															</Link>
														</td>
													</tr>
												)
											}
										</tbody>
									</table>
									<div className="ct-pagination ct-pagination-style-1">
										<div className="pagination-limit">
											<CustomInput id="page-limit"
												value={this.state.limit}
												type="select" onChange={e => this.changePageLimit(e.target.value)}>
												<option value={10}>10</option>
												<option value={20}>20</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</CustomInput>
										</div>
										<div className="pagination-info">
											{(this.state.total === 0) ? translate.getText('general.pagination.nodata') :
												translate.getTextWithReplace('general.pagination.info', this.state.total, ((this.state.page - 1) * this.state.limit), (this.state.page === this.state.maxPage) ? this.state.total : (this.state.page * this.state.limit))}
										</div>
										<div>
											<ul className="pagination">
												<li className={`page-item ${(this.state.page === 1) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage(1)}>{translate.getText('general.first')}</a>
												</li>
												<li className={`page-item ${(this.state.page === 1) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage((this.state.page - 1 < 1) ? 1 : this.state.page - 1)}>{translate.getText('general.prev')}</a>
												</li>
												{
													this.state.pagination.map((e, i) =>
														<li key={i} className={`page-item ${(this.state.page === e) ? 'active' : ''}`}>
															<a className="page-link" onClick={() => this.changePage(e)}>{e}</a>
														</li>
													)
												}
												<li className={`page-item ${(this.state.page === this.state.maxPage) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage((this.state.page + 1 > this.state.maxPage) ? this.state.maxPage : this.state.page + 1)}>{translate.getText('general.next')}</a>
												</li>
												<li className={`page-item ${(this.state.page === this.state.maxPage) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage(this.state.maxPage)}>{translate.getText('general.last')}</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ListUser);
