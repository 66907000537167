import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button, FormGroup, Label, Input, Col } from 'reactstrap';
import translate from '../../Services/TranslateService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import ValidationService from '../../Services/ValidationService';
import LoadingService from '../../Services/LoadingService';

@inject('CommonStore', 'AuthStore')
@observer
class UserProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				title: {
					type: 'string',
					minLength: 3,
					maxLength: 100,
					value: '',
					required: true,
					error: false
				},
				first_name: {
					type: 'string',
					minLength: 3,
					maxLength: 50,
					value: '',
					required: true,
					error: false
				},
				last_name: {
					type: 'string',
					minLength: 3,
					maxLength: 50,
					value: '',
					required: true,
					error: false
				},
				email: {
					type: 'email',
					minLength: 3,
					maxLength: 255,
					value: '',
					required: true,
					error: false
				},
				password: {
					type: 'string',
					value: '',
					required: false,
					error: false
				},
				repassword: {
					type: 'string',
					value: '',
					required: false,
					error: false
				}
			},
			distributor: {}
		};
	}

	componentDidMount() {
		const loading = new LoadingService().start();
		const data = this.state.data;
		APIService.requests
			.get('/user')
			.then(response => {
				data.title.value = response.title;
				data.first_name.value = response.first_name;
				data.last_name.value = response.last_name;
				data.email.value = response.email;
				const distributor = response.distributor;
				this.setState({
					data: data,
					distributor: distributor
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('userprofile.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	changeHandle(e) {
		const data = this.state.data;
		data[e.target.id].value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		this.setState({ data: data });
	}

	handleSubmit() {
		const loading = new LoadingService().start();
		let data = this.state.data;
		if (ValidationService.wholeValid(data)) {
			APIService.requests.put('/user', {
				title: data.title.value,
				first_name: data.first_name.value,
				last_name: data.last_name.value,
				email: data.email.value,
				password: data.password.value,
				repassword: data.repassword.value
			}).then(() => {
				AlertService.add({
					message: translate.getText('password.savemessage'),
					level: 'success',
					autoDismiss: true
				});
				loading.end();
				this.props.history.push('/profile');
			})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						data = this.state.data;
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('userprofile.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			this.setState({
				data: data
			});
		}
		loading.end();
	}

	errorComp(key) {
		const data = this.state.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}
	render() {
		return (
			<div className="userprofile-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<h4>{translate.getText('userprofile.title')}</h4>
							<div className="row">
								{
									!this.props.AuthStore.hasAdmin() &&
									<div className="col-12">
										<FormGroup row>
											<Label sm={3} className="ct-form-label">{translate.getText('form.distributor')}</Label>
											<Label sm={9} className="ct-form-label"><Link to={'/profile/distributor'}>{this.state.distributor.company}</Link></Label>
										</FormGroup>
									</div>
								}
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="title">{translate.getText('form.title')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.title.value} type="text" id="title" name="title" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('title')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="first_name">{translate.getText('form.first_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.first_name.value} type="text" id="first_name" name="first_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('first_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="last_name">{translate.getText('form.last_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.last_name.value} type="text" id="last_name" name="last_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('last_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="email">{translate.getText('form.email')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.email.value} type="text" id="email" name="email" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('email')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="password">{translate.getText('form.password')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.password.value} type="password" id="password" name="password" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('password')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="repassword">{translate.getText('form.repassword')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.repassword.value} type="password" id="repassword" name="repassword" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('repassword')}
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className="ct-content">
							<div className="ct-form-button-group">
								<Button onClick={() => this.handleSubmit()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(UserProfile);

