import React from 'react';
import { Button, FormGroup, Input, Form, InputGroup, InputGroupAddon } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import translate from '../../Services/TranslateService';
import validation from '../../Services/ValidationService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import LoadingService from '../../Services/LoadingService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import './login.css';
@inject('AuthStore', 'CommonStore')
@observer
class RecoverPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			input: {
				password: {
					type: 'string',
					value: '',
					minLength: 6,
					required: true,
					error: false
				},
				password2: {
					type: 'string',
					value: '',
					required: true,
					error: false
				}
			}
		};
	}
	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyPress);
		if (typeof this.props.match.params.token !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.post('/auth/check_token', { token: this.props.match.params.token })
				.then(() => {
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.error(translate.getText('error.login.' + body.error.code), {
						position: 'top-center'
					});
					this.props.history.push('/login');
					loading.end();
				});
		}
	}
	handleKeyPress(e) {
		if (e.key === 'Enter') {
			const loginButton = document.getElementById('login-button');
			loginButton.click();
		}
	}
	handleChange(event) {
		const userData = this.state.input;
		userData[event.target.name].value = event.target.value;
		this.setState({ input: userData });
	}
	handleSubmit() {
		const loading = new LoadingService().start();
		if (validation.wholeValid(this.state.input)) {
			APIService.requests
				.post('/auth/change_password', {
					password: this.state.input.password.value,
					repassword: this.state.input.password2.value,
					token: this.props.match.params.token
				})
				.then(() => {
					AlertService.success(translate.getText('login.changepasswordmessage'), {
						position: 'top-center'
					});
					this.props.history.push('/login');
					loading.end();
				})
				.catch(resError => {
					AlertService.error(translate.getText('error.' + resError.response.body.error.code), {
						position: 'top-center'
					});
					loading.end();
				});
		} else {
			const data = validation.wholeValidRef(this.state.input);
			Object.keys(data).forEach(key => {
				if (data[key].error) {
					AlertService.error(
						translate.getTextWithReplace('login.error.' + key + '.' + data[key].error, data[key].min + '-' + data[key].max),
						{
							position: 'top-center'
						}
					);
				}
			});
			this.setState({
				input: data
			});
			loading.end();
		}
	}
	render() {
		const data = this.state.input;
		return (
			<div className="login-container">
				<div className="auth-form">
					<img className="form-logo img-fluid" src={require('../../Assets/images/LOGO.svg')} alt="" />
					<Form className="input-form">
						<FormGroup className="has-success">
							<InputGroup className="login-email">
								<InputGroupAddon addonType="prepend">
									<span className="input-group-text"><FontAwesomeIcon icon={faUnlockAlt} /></span>
								</InputGroupAddon>
								<Input type="password" name="password"
									className={`login-input ${data.password.error ? 'is-invalid' : ''}`}
									onChange={e => this.handleChange(e)}
									placeholder={translate.getText('login.input.newpassword')}
									value={data.password.value} />
							</InputGroup>
						</FormGroup>
						<FormGroup className="has-success">
							<InputGroup className="login-password">
								<InputGroupAddon addonType="prepend">
									<span className="input-group-text"><FontAwesomeIcon icon={faUnlockAlt} /></span>
								</InputGroupAddon>
								<Input type="password" name="password2"
									className={`login-input ${data.password2.error ? 'is-invalid' : ''}`}
									onChange={e => this.handleChange(e)}
									placeholder={translate.getText('login.input.newpassword2')}
									value={data.password2.value} />
							</InputGroup>
						</FormGroup>
						<Button type="button" id="login-button" className="btn-block btn-login" color="warning" onClick={() => this.handleSubmit()}>{translate.getText('login.approve')}</Button>
					</Form>
				</div>
			</div>
		);
	}
}
export default RecoverPassword;
