import React from 'react';
import { withRouter } from 'react-router-dom';
import UserForm from './UserForm/UserForm';

class EditUser extends React.Component {
	render() {
		return (
			<UserForm/>
		);
	}
}

export default withRouter(EditUser);
