import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import LoadingService from '../../Services/LoadingService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import translate from '../../Services/TranslateService';
import { UncontrolledTooltip } from 'reactstrap';
import './distributor.css';

class DetailDistributor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				company: '',
				first_name: '',
				last_name: '',
				country: '',
				city: '',
				address: '',
				phone: '',
				mobile_phone: '',
				website: '',
				email: '',
				status: false,
				create_time: ''
			},
			dealers: {
				data: [],
				filter: {
					distributor: this.props.match.params.id
				},
				sortable: {
					id: 'DESC'
				},
				page: 1,
				limit: 10,
				maxPage: 1,
				total: 0,
				pagination: [],
				hasMoreItems: true
			},
			users: {
				data: [],
				filter: {
					distributor: this.props.match.params.id
				},
				sortable: {
					id: 'DESC'
				},
				page: 1,
				limit: 10,
				maxPage: 1,
				total: 0,
				pagination: [],
				hasMoreItems: true
			}
		};
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/distributors/' + this.props.match.params.id)
				.then(data => {
					const sdata = {
						company: data.company,
						first_name: data.first_name,
						last_name: data.last_name,
						country: data.country,
						city: data.city,
						address: data.address,
						phone: data.phone,
						mobile_phone: data.mobile_phone,
						website: data.website,
						status: data.status,
						email: data.email,
						create_time: data.create_time
					};
					this.setState({ data: sdata });
					this.loadDealers();
					this.loadUsers();
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('distributor.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		} else {
			this.props.history.push('/distributor/list');
		}
	}

	loadDealers(setpage = null, setlimit = null) {
		const page = (setpage !== null) ? setpage : this.state.dealers.page;
		const limit = (setlimit !== null) ? setlimit : this.state.dealers.limit;
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/dealers/paginate', {
				page: page,
				limit: limit,
				sort: this.state.dealers.sortable,
				filter: this.state.dealers.filter
			})
			.then(response => {
				let hasMoreItems = false;
				const dealers = this.state.dealers;
				if (response.data.length === 0 || response.data.length < limit) {
					hasMoreItems = false;
				} else {
					hasMoreItems = true;
				}
				response.data.map(dealer => {
					const gradient1 = ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
					const gradient2 = ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
					dealers.data.push(
						<div className="single-box" key={this.state.dealers.data.length}>
							<div className="style-color" style={{ backgroundImage: 'linear-gradient(to bottom,' + '#' + gradient1 + ',' + '#' + gradient2 + ')' }}></div>
							<div className="single-box-content">
								<div className="title">{dealer.company}</div>
								<div className="sub-title">
									<p>
										{dealer.first_name + ' ' + dealer.last_name}
									</p>
									<p>
										{dealer.phone}
									</p>
									<p>
										{dealer.email}
									</p>
								</div>
							</div>
						</div>
					);
				});

				dealers.page = response.page;
				dealers.limit = response.limit;
				dealers.maxPage = response.max_page;
				dealers.total = response.total;
				dealers.hasMoreItems = hasMoreItems;
				this.setState({
					dealers: dealers
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('dealer.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	loadUsers(setpage = null, setlimit = null) {
		const page = (setpage !== null) ? setpage : this.state.users.page;
		const limit = (setlimit !== null) ? setlimit : this.state.users.limit;
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/users/paginate', {
				page: page,
				limit: limit,
				sort: this.state.users.sortable,
				filter: this.state.users.filter
			})
			.then(response => {
				let hasMoreItems = false;
				const users = this.state.users;
				if (response.data.length === 0 || response.data.length < limit) {
					hasMoreItems = false;
				} else {
					hasMoreItems = true;
				}
				response.data.map(user => {
					users.data.push(
						<div className="distributor-users" key={this.state.users.data.length}>
							<div style={{ backgroundColor: '#' + ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6) }}></div>
							<div className="dist-user-info">
								<div className="auth-name">
									<p>
										<b>{user.first_name + ' ' + user.last_name}</b> <br></br>
										{user.email}
									</p>
								</div>
							</div>
						</div>
					);
				});

				users.page = response.page;
				users.limit = response.limit;
				users.maxPage = response.max_page;
				users.total = response.total;
				users.hasMoreItems = hasMoreItems;
				this.setState({
					users: users
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('user.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	errorComp(key) {
		const data = this.state.user;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	render() {
		const sdata = this.state.data;
		return (
			<div>
				<div className="ct-container">
					<div className="row">
						<div className="col-md-9 left-col">
							<div className="ct-content">
								<div className="content-top-right-button">
									<Link className="btn ct-button open btn-edit" to={`/distributor/edit/${this.props.match.params.id}`}>
										<i className="fa fa-pen btn-icon"></i>
										<div className="btn-text">{translate.getText('general.edit')}</div>
									</Link>
									<Link className="btn ct-button open btn-delete" to={`/distributor/delete/${this.props.match.params.id}`}>
										<i className="fa fa-trash btn-icon"></i>
										<div className="btn-text">{translate.getText('general.delete')}</div>
									</Link>
								</div>
								<div className="content-info ct-form-container-block">
									<div className="head-info">
										<h1>{sdata.company}</h1>
										<h3>{sdata.first_name} {sdata.last_name}</h3>
										<div className={`badge badge-status status_${(sdata.status ? 1 : 0)}`}>
											{translate.getText('general.status_' + (sdata.status ? 1 : 0))}
										</div>
									</div>
									<div className="icon-info">
										<div className="row">
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-phone">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-phone" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-phone">
													{translate.getText('form.phone')}
												</UncontrolledTooltip>
												<label>{sdata.phone}</label>
											</div>
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-mobile_phone">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-mobile-alt" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-mobile_phone">
													{translate.getText('form.mobile_phone')}
												</UncontrolledTooltip>
												<label>{sdata.mobile_phone}</label>
											</div>
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-email">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-envelope" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-email">
													{translate.getText('form.email')}
												</UncontrolledTooltip>
												<label>{sdata.email}</label>
											</div>
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-website">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-globe" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-website">
													{translate.getText('form.website')}
												</UncontrolledTooltip>
												<label>{sdata.website}</label>
											</div>
											<div className="col-md-12">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-address">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-map-marker-alt" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-address">
													{translate.getText('form.address')}
												</UncontrolledTooltip>
												<label>{sdata.address}</label>
											</div>
											<div className="col-md-6">
												<div className="badge badge-icon" id="Tooltip-country">
													{sdata.country.name} / {sdata.city.name}
												</div>
												<UncontrolledTooltip placement="top" target="Tooltip-country">
													{translate.getText('form.country')} / {translate.getText('form.city')}
												</UncontrolledTooltip>
											</div>
											<div className="col-md-6">
												<div className="badge badge-icon" id="Tooltip-create_date">
													{sdata.create_time}
												</div>
												<UncontrolledTooltip placement="top" target="Tooltip-create_date">
													{translate.getText('form.create_date')}
												</UncontrolledTooltip>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="ct-content">
								<h5>{translate.getText('dealer.title.list')}</h5>
								<div className="ct-box-list">
									{
										this.state.dealers.data
									}
								</div>
							</div>
							<div className="ct-box-load-more">
								{this.state.dealers.hasMoreItems && <button onClick={() => this.loadDealers((this.state.dealers.page + 1))} className="btn">{translate.getText('general.load_more')}</button>}
							</div>
						</div>
						<div className="col-md-3 right-col">
							<div className="ct-content">
								<div className="content-top-right-button">
									<Link className="btn ct-button open btn-add" to={'/user/new'}>
										<i className="fa fa-plus btn-icon"></i>
										<div className="btn-text">{translate.getText('general.add')}</div>
									</Link>
								</div>
								<h5>{translate.getText('user.title.list')}</h5>
								<div>
									{
										this.state.users.data
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DetailDistributor);
