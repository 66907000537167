import React from 'react';
import { withRouter } from 'react-router-dom';
import translate from '../../../Services/TranslateService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';
import ValidationService from '../../../Services/ValidationService';
import { Button, FormGroup, Label, Input, CustomInput, Col, Modal, ModalBody } from 'reactstrap';
import LoadingService from '../../../Services/LoadingService';
import { inject, observer } from 'mobx-react';
import AutoComplete from '../../../Components/AutoComplete/AutoComplete';
import BoxSelect from '../../../Components/BoxSelect/BoxSelect';
import BalanceButtonBox from '../../../Components/Sidebar/BalanceButtonBox';
import '../order.css';
import modalImage from '../../../Assets/images/order-modal-image.jpg';
import PhoneInput from 'react-phone-number-input';

@inject('CommonStore', 'AuthStore')
@observer
class OrderForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.defaultForm(),
			data_option: {},
			data_payment: {
				type: 'balance',
				period: 'oneyear',
				renewal: false
			},
			notification: {
				distributor: false,
				dealer: false,
				order: true
			},
			countries: [],
			cities: [],
			product: {
				id: null,
				options: []
			},
			isSubmit: false,
			step: 1,
			toogleModal: false,
			info: {
				totals: [],
				renewal_totals: [],
				license_expiration_period: 'n_a',
				license_select: false,
				price_period: true,
				open_bill: false
			},
			usernameCheck: (typeof this.props.match.params.id !== 'undefined'),
			isDemo: false
		};
		this.reqCountries();
		this.reqCities();
		this.reqProduct();
	}

	defaultForm() {
		const data = {
			dealer_id: {
				type: 'integer',
				value: null,
				label: '',
				required: true,
				error: false
			},
			company: {
				type: 'string',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: true,
				error: false
			},
			first_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: true,
				error: false
			},
			last_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: true,
				error: false
			},
			country_id: {
				type: 'integer',
				value: 215,
				required: true,
				error: false
			},
			city_id: {
				type: 'integer',
				value: '',
				required: true,
				error: false
			},
			address: {
				type: 'string',
				value: '',
				required: true,
				error: false
			},
			email: {
				type: 'email',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: true,
				error: false
			},
			phone: {
				type: 'phone',
				minLength: 3,
				maxLength: 100,
				value: '',
				required: true,
				error: false
			},
			mobile_phone: {
				type: 'phone',
				minLength: 3,
				maxLength: 100,
				value: '',
				required: false,
				error: false
			},
			website: {
				type: 'url',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: false,
				error: false
			}
		};
		if (this.props.AuthStore.hasAdmin()) {
			data.distributor_id = {
				type: 'integer',
				label: '',
				value: null,
				required: true,
				error: false
			};
		}

		return data;
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/orders/' + this.props.match.params.id)
				.then(data => {
					const sdata = this.defaultForm();
					sdata.dealer_id.value = data.dealer.id;
					sdata.dealer_id.label = data.dealer.company;
					sdata.company.value = data.company;
					sdata.first_name.value = data.first_name;
					sdata.last_name.value = data.last_name;
					sdata.country_id.value = data.country.id;
					sdata.city_id.value = data.city.id;
					sdata.address.value = data.address;
					sdata.email.value = data.email;
					sdata.phone.value = data.phone;
					sdata.mobile_phone.value = data.mobile_phone;
					sdata.website.value = data.website;
					if (this.props.AuthStore.hasAdmin()) {
						sdata.distributor_id.value = data.distributor.id;
						sdata.distributor_id.label = data.distributor.company;
					}
					this.setState({ data: sdata });
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('order.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		} else {
			this.setState({
				data: this.defaultForm()
			});
		}
	}

	reqCountries() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/countries')
			.then(data => {
				this.setState({ countries: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	reqCities() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/cities/' + this.state.data.country_id.value)
			.then(data => {
				this.setState({ cities: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	reqProduct() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/products/1')
			.then(data => {
				this.setState({ product: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	reqInfo(opt = null) {
		const data = this.state.data;
		const post = {
			dealer_id: data.dealer_id.value,
			product_id: this.state.product.id,
			country_id: data.country_id.value,
			city_id: data.city_id.value,
			company: data.company.value,
			first_name: data.first_name.value,
			last_name: data.last_name.value,
			address: data.address.value,
			email: data.email.value,
			phone: data.phone.value,
			mobile_phone: data.mobile_phone.value,
			website: data.website.value,
			options: opt !== null ? opt : this.state.data_option,
			payment: this.state.data_payment,
			notification: this.state.notification
		};
		if (typeof this.props.match.params.id !== 'undefined') {
			post.order_id = this.props.match.params.id;
		}
		if (this.props.AuthStore.hasAdmin()) {
			post.distributor_id = data.distributor_id.value;
		}
		APIService.requests.post('/orders/info', post).then(response => {
			this.setState({
				info: response
			});
		})
			.catch(() => {
			});
	}

	changeHandle(e) {
		const data = this.state.data;
		data[e.target.id].value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		if (e.target.id === 'country_id') {
			data.city_id.value = 0;
		}
		this.setState({ data: data });
	}

	changeEmailHandle(e) {
		if (typeof this.props.match.params.id === 'undefined') {
			const data = this.state.data;
			const e_id = e.target.id;
			data[e_id].value = e.target.value;
			APIService.requests.post('/products/' + this.state.product.id + '/check', {
				email: e.target.value
			}).then(() => {
				data[e_id].error = false;
				this.setState({
					data: data,
					usernameCheck: true
				});
			})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('order.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					this.setState({
						usernameCheck: false
					});
				});
		}
	}

	changeCountryHandle(e) {
		this.changeHandle(e);
		this.reqCities();
	}

	changeKeyValueHandle(key, value) {
		const data = this.state.data;
		data[key].value = value;
		this.setState({ data: data });
	}

	changeOptionHandle(option_id, value) {
		const data_option = this.state.data_option;
		data_option[option_id] = value;
		this.setState({
			data_option: data_option
		});
		this.reqInfo();
	}

	changeLicenseOptionHandle(option, value) {
		const foundLicense = option.values.find(element => element.id === value);
		this.setState({
			isDemo: (typeof foundLicense !== 'undefined' && foundLicense.name === 'demo')
		});
		if (typeof foundLicense !== 'undefined' && foundLicense.name === 'demo') {
			const data_option = {};
			data_option[option.id] = value;
			this.setState({
				data_option: data_option
			});
			this.reqInfo(data_option);
		} else {
			this.changeOptionHandle(option.id, value);
		}
	}

	changePaymentHandle(type, value) {
		const data_payment = this.state.data_payment;
		data_payment[type] = value;
		this.setState({
			data_payment: data_payment
		});
		this.reqInfo();
	}

	changeNotificationHandle(e) {
		const data = this.state.notification;
		data[e.target.id] = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		if (data.order || data.dealer || data.distributor) {
			this.setState({ notification: data });
		}
	}

	optionValid() {
		const data = this.state.data_option;
		let error = false;
		this.state.product.options.forEach(e => {
			if (typeof this.props.match.params.id === 'undefined') {
				if (e.mandatory === 1 && (typeof data[e.id] === 'undefined' || data[e.id] === null)) {
					error = true;
					return;
				}
			}
		});
		return !error;
	}

	licenseListRev(props) {
		const list = [];
		Object.keys(props).forEach(e => {
			if (typeof props[e] === 'boolean') {
				props[e] = (props[e]) ? translate.getText('general.active') : translate.getText('general.deactive');
			}
			list.push({
				key: translate.getText('order.license_list.' + e),
				value: props[e]
			});
		});
		return list;
	}

	formatMoney(price) {
		const currency_symbol = '₺';

		const formattedOutput = new Intl.NumberFormat('tr-TR', {
			style: 'currency',
			currency: 'TRY',
			minimumFractionDigits: 2
		});

		return formattedOutput.format(price).replace(currency_symbol, '');
	}

	handleSubmit() {
		this.setState({ isSubmit: true });
		const loading = new LoadingService().start();
		let data = this.state.data;
		if ((typeof this.props.match.params.id !== 'undefined' || ValidationService.wholeValid(data)) && this.optionValid()) {
			if (typeof this.props.match.params.id === 'undefined') {
				const post = {
					dealer_id: data.dealer_id.value,
					product_id: this.state.product.id,
					country_id: data.country_id.value,
					city_id: data.city_id.value,
					company: data.company.value,
					first_name: data.first_name.value,
					last_name: data.last_name.value,
					address: data.address.value,
					email: data.email.value,
					phone: data.phone.value,
					mobile_phone: data.mobile_phone.value,
					website: data.website.value,
					options: this.state.data_option,
					payment: this.state.data_payment,
					notification: this.state.notification
					// is_demo: this.state.isDemo
				};
				if (this.props.AuthStore.hasAdmin()) {
					post.distributor_id = data.distributor_id.value;
				}
				APIService.requests.post('/orders', post).then(() => {
					AlertService.add({
						message: translate.getText('order.action.savemessage'),
						level: 'success',
						autoDismiss: true
					});
					this.props.history.push('/order/list');
					loading.end();
				})
					.catch(error => {
						const body = error.response.body;
						if (body.error.code === 'validation') {
							Object.keys(body.error.detail).forEach(k => {
								data[k].error = body.error.detail[k];
							});
							this.setState({
								data: data,
								step: 1
							});
						} else {
							AlertService.add({
								message: translate.getText('order.error.' + body.error.code),
								level: 'danger',
								autoDismiss: true
							});
						}
						if (body.order_id !== 'undefined') {
							this.props.history.push('/order/upgrade/' + body.order_id);
						}
						loading.end();
					});
			} else {
				APIService.requests.put('/orders/' + this.props.match.params.id + '/modify', {
					product_id: this.state.product.id,
					options: this.state.data_option,
					payment: this.state.data_payment,
					notification: this.state.notification
					// is_demo: this.state.isDemo
				}).then(() => {
					AlertService.add({
						message: translate.getText('order.action.savemessage'),
						level: 'success',
						autoDismiss: true
					});
					this.props.history.push('/order/detail/' + this.props.match.params.id);
					loading.end();
				})
					.catch(error => {
						const body = error.response.body;
						if (body.error.code === 'validation') {
							Object.keys(body.error.detail).forEach(k => {
								data[k].error = body.error.detail[k];
							});
							this.setState({
								data: data
							});
						} else {
							AlertService.add({
								message: translate.getText('order.error.' + body.error.code),
								level: 'danger',
								autoDismiss: true
							});
						}
						loading.end();
					});
			}
		} else {
			data = ValidationService.wholeValidRef(data);
			this.setState({
				data: data,
				toogleModal: false,
				step: 1
			});
			loading.end();
		}
	}

	handleBack() {
		this.props.history.push('/order/list');
	}

	handleBackStep() {
		this.setState({ step: 1 });
	}

	handleNextStep() {
		this.setState({ isSubmit: true });
		let data = this.state.data;
		if (ValidationService.wholeValid(data) && this.optionValid() && this.state.usernameCheck) {
			this.reqInfo();
			this.setState({ step: 2 });
		} else {
			const email = data.email.error;
			data = ValidationService.wholeValidRef(data);
			if (this.state.usernameCheck === false && data.email.error === false) {
				data.email.error = email;
			}
			this.setState({
				data: data
			});
		}
	}

	handleModal() {
		this.setState({ toogleModal: !this.state.toogleModal });
	}

	errorComp(key) {
		const data = this.state.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	errorOptionComp(option_id) {
		const data = this.state.data_option;
		let option = {};
		this.state.product.options.forEach(e => {
			if (e.id === option_id) {
				option = e;
			}
		});
		if (this.state.isSubmit && option.mandatory === 1 && (typeof data[option_id] === 'undefined' || data[option_id] === null)) {
			return (
				<small className="text-danger">
					{translate.getText('error.error_selected')}
				</small>
			);
		}
		return null;
	}

	render() {
		const disabledInput = (typeof this.props.match.params.id !== 'undefined') ? 'disabled' : '';
		return (
			<div className="order-container order-form-container">
				<div className="row">
					<div className="col-md-9 left-col" hidden={this.state.step !== 1}>
						<div className="ct-content oc-left-form">
							<h2>{translate.getText('order.title.form')}</h2>
							<div className="row">
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="email">{translate.getText('form.email')} / {translate.getText('form.username')}</Label>
										<Col sm={9}>
											<Input disabled={disabledInput} value={this.state.data.email.value} type="text" id="email" name="email" autoComplete="off" onChange={e => this.changeEmailHandle(e)}>
											</Input>
											{this.errorComp('email')}
											{
												<small hidden={this.state.data.email.error !== false} className="text-info">
													{translate.getTextWithReplace('order.action.email_info')}
												</small>
											}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row >
										<Label sm={3} for="company">{translate.getText('form.company')}</Label>
										<Col sm={9}>
											<Input disabled={disabledInput} value={this.state.data.company.value} type="text" id="company" name="company" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('company')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="first_name">{translate.getText('form.first_name')}</Label>
										<Col sm={9}>
											<Input disabled={disabledInput} value={this.state.data.first_name.value} type="text" id="first_name" name="first_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('first_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="last_name">{translate.getText('form.last_name')}</Label>
										<Col sm={9}>
											<Input disabled={disabledInput} value={this.state.data.last_name.value} type="text" id="last_name" name="last_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('last_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="address">{translate.getText('form.address')}</Label>
										<Col sm={9}>
											<Input disabled={disabledInput} value={this.state.data.address.value} type="textarea" id="address" name="address" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('address')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="country_id">{translate.getText('form.country')}</Label>
										<Col sm={6}>
											<CustomInput disabled={disabledInput}
												value={this.state.data.country_id.value}
												autoComplete="off"
												type="select" id="country_id" name="country_id" onChange={e => this.changeCountryHandle(e)}>
												<option value="">{translate.getText('general.select')}</option>
												{
													this.state.countries.map((e, i) =>
														<option key={i} value={e.id}>{e.name}</option>
													)
												}
											</CustomInput>
											{this.errorComp('country_id')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Col sm={2}></Col>
										<Label sm={4} for="city_id">{translate.getText('form.city')}</Label>
										<Col sm={6}>
											<CustomInput disabled={disabledInput}
												value={this.state.data.city_id.value}
												autoComplete="off"
												type="select" id="city_id" name="city_id" onChange={e => this.changeHandle(e)}>
												<option value="">{translate.getText('general.select')}</option>
												{
													this.state.cities.map((e, i) =>
														<option key={i} value={e.id}>{e.name}</option>
													)
												}
											</CustomInput>
											{this.errorComp('city_id')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="phone">{translate.getText('form.phone')}</Label>
										<Col sm={6}>
											<PhoneInput
												disabled={disabledInput === 'disabled'}
												id="phone" name="phone"
												placeholder="+9..."
												value={this.state.data.phone.value}
												onChange={phone => this.changeKeyValueHandle('phone', phone)} />
											{this.errorComp('phone')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Col sm={2}></Col>
										<Label sm={4} for="mobile_phone">{translate.getText('form.mobile_phone')}</Label>
										<Col sm={6}>
											<PhoneInput
												disabled={disabledInput === 'disabled'}
												id="mobile_phone" name="mobile_phone"
												placeholder="+9..."
												value={this.state.data.mobile_phone.value}
												onChange={phone => this.changeKeyValueHandle('mobile_phone', phone)} />
											{this.errorComp('mobile_phone')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="website">{translate.getText('form.website')}</Label>
										<Col sm={6}>
											<Input disabled={disabledInput} value={this.state.data.website.value} type="text" id="website" name="website" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('website')}
										</Col>
									</FormGroup>
								</div>
								{
									this.props.AuthStore.hasAdmin() &&
									<div className="col-6">
										<FormGroup row>
											<Col sm={2}></Col>
											<Label sm={4} for="distributor_id">{translate.getText('form.distributor')}</Label>
											<Col sm={6}>
												<AutoComplete disabled={disabledInput}
													value={this.state.data.distributor_id.label}
													id="distributor_id"
													name="distributor_id"
													onChange={value => this.changeKeyValueHandle('distributor_id', value)}
													api={{
														url: '/distributors/paginate',
														param: 'company',
														cons_param: {
															status: 1
														}
													}}
												/>
												{this.errorComp('distributor_id')}
											</Col>
										</FormGroup>
									</div>
								}
								<div className="col-6">
									<FormGroup row>
										<Col sm={2}></Col>
										<Label sm={4} for="dealer_id">{translate.getText('form.dealer')}</Label>
										<Col sm={6}>
											<AutoComplete disabled={disabledInput}
												value={this.state.data.dealer_id.label}
												id="dealer_id"
												name="dealer_id"
												onChange={value => this.changeKeyValueHandle('dealer_id', value)}
												api={
													this.props.AuthStore.hasAdmin() ? {
														url: '/dealers/paginate',
														param: 'company',
														cons_param: {
															status: 1,
															distributor: this.state.data.distributor_id.value
														}
													} :
														{
															url: '/dealers/paginate',
															param: 'company',
															cons_param: {
																status: 1
															}
														}
												}
											/>
											{this.errorComp('dealer_id')}
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className="ct-content oc-left-license">
							<h2>{translate.getText('order.title.package')}</h2>
							<div className="row">
								{
									this.state.product.options.map((option, i) =>
										option.name === 'license' &&
										<div className="col-12" key={i}>
											<FormGroup row>
												<Label sm={12} for={'option_' + option.id}>{translate.getText('form.option.' + option.name)}</Label>
												<Col sm={12}>
													<BoxSelect
														nonselect={(typeof this.props.match.params.id !== 'undefined')}
														value={this.state.data_option[option.id]}
														id={'option_' + option.id} name={'option_' + option.id}
														onChange={e => this.changeLicenseOptionHandle(option, e)}>
														{
															option.values.map((val, vi) =>
																<option
																	key={vi}
																	value={val.id}
																	list={this.licenseListRev(val.properties[this.state.data_payment.period])}
																>{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}</option>
															)
														}
													</BoxSelect>
													{this.errorOptionComp(option.id)}
												</Col>
											</FormGroup>
										</div>
									)
								}
								<div className="col-12 license-detail-text">
									<span>{translate.getText('order.action.license_text')}</span>
								</div>
							</div>
						</div>
						<div className="ct-content oc-left-button">
							<div className="ct-form-button-group">
								<Button onClick={() => this.handleBack()} className="ct-form-button btn-back btn-sm">{translate.getText('general.back')}</Button>
								<Button onClick={() => this.handleNextStep()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.next')}</Button>
							</div>
						</div>
					</div>
					<div className="col-md-9 left-col" hidden={this.state.step !== 2}>
						<div className="ct-content oc-left-payment">
							<h2 hidden={this.state.isDemo}>{translate.getText('order.title.payment')}</h2>
							{
								(this.state.info.open_bill && !this.state.info.license_select) &&
								<p style={{ textAlign: 'center' }}>{translate.getText('order.action.open_bill_commitment')}</p>
							}
							<div className="row">
								<div className="col-12" hidden={this.state.isDemo || (this.state.info.open_bill && !this.state.info.license_select)}>
									<FormGroup row>
										<Label sm={12}>{translate.getText('order.action.payment_type')}</Label>
										<Col sm={4}></Col>
										<Col sm={4}>
											<BoxSelect
												value={this.state.data_payment.type}
												onChange={e => this.changePaymentHandle('type', e)}>
												<option value="balance">{translate.getText('order.action.payment_balance')}</option>
												<option value="invoice">{translate.getText('order.action.payment_invoice')}</option>
											</BoxSelect>
										</Col>
									</FormGroup>
								</div>
								<div className="col-12" hidden={this.state.isDemo || !this.state.info.price_period}>
									<FormGroup row>
										<Label sm={12}>{translate.getText('order.action.payment_period')}</Label>
										<Col sm={1}></Col>
										<Col sm={10}>
											<BoxSelect
												value={this.state.data_payment.period}
												onChange={e => this.changePaymentHandle('period', e)}>
												<option value="commitment_12_month">{translate.getText('order.action.payment_period_commitment_12_month')}</option>
												<option value="month">{translate.getText('order.action.payment_period_month')}</option>
												<option value="oneyear">{translate.getText('order.action.payment_period_oneyear')}</option>
												<option value="twoyear">{translate.getText('order.action.payment_period_twoyear')}</option>
												<option value="threeyear">{translate.getText('order.action.payment_period_threeyear')}</option>
											</BoxSelect>
										</Col>
									</FormGroup>
								</div>
								<div className="col-12" hidden={this.state.isDemo || (this.state.info.open_bill && !this.state.info.license_select)}>
									<FormGroup row>
										<Label sm={12}>{translate.getText('order.action.payment_renewal')}</Label>
										<Col sm={12}>
											<CustomInput checked={this.state.data_payment.renewal} type="switch" id="renewal" name="renewal" onChange={e => this.changePaymentHandle('renewal', e.target.checked)} />
										</Col>
									</FormGroup>
								</div>
								<div className="col-4">
									<FormGroup row>
										<Label sm={12}>{translate.getText('order.action.notify_distributor')}</Label>
										<Col sm={12}>
											<CustomInput checked={this.state.notification.distributor} type="switch" id="distributor" name="distributor" onChange={e => this.changeNotificationHandle(e)} />
										</Col>
									</FormGroup>
								</div>
								<div className="col-4">
									<FormGroup row>
										<Label sm={12}>{translate.getText('order.action.notify_dealer')}</Label>
										<Col sm={12}>
											<CustomInput checked={this.state.notification.dealer} type="switch" id="dealer" name="dealer" onChange={e => this.changeNotificationHandle(e)} />
										</Col>
									</FormGroup>
								</div>
								<div className="col-4">
									<FormGroup row>
										<Label sm={12}>{translate.getText('order.action.notify_order')}</Label>
										<Col sm={12}>
											<CustomInput checked={this.state.notification.order} type="switch" id="order" name="order" onChange={e => this.changeNotificationHandle(e)} />
										</Col>
									</FormGroup>
								</div>
							</div>
							<h2 hidden={this.state.isDemo}>{translate.getText('order.title.other_package')}</h2>
							<div className="row" hidden={this.state.isDemo}>
								{
									this.state.product.options.map((option, i) =>
										option.name !== 'license' &&
										<div className="col-12" key={i}>
											<FormGroup row>
												<Label sm={12} for={'option_' + option.id}>{translate.getText('form.option.' + option.name)}</Label>
												<Col sm={12}>
													<BoxSelect
														value={this.state.data_option[option.id]}
														id={'option_' + option.id} name={'option_' + option.id}
														nonselect={true}
														onChange={e => this.changeOptionHandle(option.id, e)}>
														{
															option.values.map((val, vi) =>
																<option
																	key={vi}
																	value={val.id}
																	subValue={val.price_format + ' TL'}
																>{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}</option>
															)
														}
													</BoxSelect>
													{this.errorOptionComp(option.id)}
												</Col>
											</FormGroup>
										</div>
									)
								}

							</div>
						</div>
						<div className="ct-content oc-left-button">
							<div className="ct-form-button-group">
								<Button onClick={() => this.handleBackStep()} className="ct-form-button btn-back btn-sm">{translate.getText('general.back')}</Button>
								<Button onClick={() => this.handleModal()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
							</div>
						</div>
					</div>
					<div className="col-md-3 right-col">
						{
							this.props.AuthStore.hasAdmin() &&
							<BalanceButtonBox distributor_id={this.state.data.distributor_id.value} />
						}
						{
							!this.props.AuthStore.hasAdmin() &&
							<BalanceButtonBox />
						}
						<div className="ct-content oc-right-detail">
							<h2>{translate.getText('order.sidebar.license')}</h2>
							{
								this.state.product.options.map((option, i) =>
									option.name === 'license' &&
									option.values.map((val, vi) =>
										this.state.data_option[option.id] === val.id &&
										<ul key={i} className="ct-sidebar-list">
											<li key={vi} className="row">
												<div className="col-md-6">{translate.getText('order.sidebar.selected_license')}</div>
												<div className="col-md-6">:<span>{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}</span></div>
											</li>
											{
												this.licenseListRev(val.properties[this.state.data_payment.period]).map((vall, valli) =>
													<li key={valli} className="row">
														<div className="col-md-6">{vall.key}</div>
														<div className="col-md-6">:<span>{vall.value}</span></div>
													</li>
												)
											}
											<li className="row">
												<div className="col-md-6">{translate.getText('order.action.license_expdate')}</div>
												<div className="col-md-6">:<span>{translate.getText('order.action.payment_period_' + this.state.info.license_expiration_period)}</span></div>
											</li>
										</ul>
									)

								)
							}
							<h2 hidden={this.state.step !== 2 || this.state.isDemo}>{translate.getText('order.title.other_package')}</h2>
							<ul className="ct-sidebar-list" hidden={this.state.step !== 2 || this.state.isDemo}>
								{
									this.state.product.options.map(option =>
										option.name !== 'license' &&
										option.values.map((val, vi) =>
											this.state.data_option[option.id] === val.id &&
											<li key={vi} className="row">
												<div className="col-md-6">{translate.getText('form.option.' + option.name)}</div>
												<div className="col-md-6">:
													<span>
														{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}
														({val.price_format + ' TL'})
													</span>
												</div>
											</li>
										)

									)
								}
							</ul>
							<h2 hidden={this.state.step !== 2 || this.state.isDemo}>{translate.getText('order.title.payment')}</h2>
							{
								this.state.data_payment.period !== 'commitment_12_month' &&
								<ul className="ct-sidebar-list" hidden={(this.state.step !== 2 || this.state.isDemo)}>
									<li className="row">
										<div className="col-md-6">{translate.getText('order.action.payment_type')}</div>
										<div className="col-md-6">:
											<span>{translate.getText('order.action.payment_' + this.state.data_payment.type)}</span>
										</div>
									</li>
									<li className="row">
										<div className="col-md-6">{translate.getText('order.action.payment_period')}</div>
										<div className="col-md-6">:
											<span>{translate.getText('order.action.payment_period_' + this.state.data_payment.period)}</span>
										</div>
									</li>
									<li className="row">
										<div className="col-md-6">{translate.getText('order.action.payment_renewal')}</div>
										<div className="col-md-6">:
											<span>{(this.state.data_payment.renewal) ? translate.getText('general.on') : translate.getText('general.off')}</span>
										</div>
									</li>
									{
										this.state.info.totals.map((e, i) =>
											<li className="row" key={i}>
												<div className="col-md-6">{translate.getText('total.' + e.text)}</div>
												<div className="col-md-6">:
													<span>{this.formatMoney(e.value)} ₺</span>
												</div>
											</li>
										)
									}
									{
										this.state.data_payment.renewal &&
										this.state.info.renewal_totals.map((e, i) =>
											e.text === 'total' &&
											<li className="row" key={i}>
												<div className="col-md-6">{translate.getText('total.renewal_' + e.text)}</div>
												<div className="col-md-6">:
													<span>{this.formatMoney(e.value)} ₺</span>
												</div>
											</li>
										)
									}
								</ul>

							}
							{
								this.state.data_payment.period === 'commitment_12_month' &&
								<ul className="ct-sidebar-list" hidden={(this.state.step !== 2 || this.state.isDemo)}>
									<li className="row">
										<div className="col-md-6">{translate.getText('order.action.commitment_period')}</div>
										<div className="col-md-6">:
											<span>{translate.getText('order.action.month_12')}</span>
										</div>
									</li>
									<li className="row">
										<div className="col-md-6">{translate.getText('order.action.payment_period')}</div>
										<div className="col-md-6">:
											<span>{translate.getText('order.action.payment_period_month')}</span>
										</div>
									</li>
									<li className="row">
										<div className="col-md-6">{translate.getText('order.action.payment_renewal')}</div>
										<div className="col-md-6">:
											<span>{(this.state.data_payment.renewal) ? translate.getText('general.on') : translate.getText('general.off')}</span>
										</div>
									</li>
									<li className="row">
										<div className="col-md-6">{translate.getText('order.action.payment_type')}</div>
										<div className="col-md-6">:
											<span>{translate.getText('order.action.payment_' + this.state.data_payment.type)}</span>
										</div>
									</li>
									{
										this.state.info.totals.map((e, i) =>
											<li className="row" key={i}>
												<div className="col-md-6">{translate.getText('total.' + e.text)}</div>
												<div className="col-md-6">:
													<span>{this.formatMoney(e.value)} ₺</span>
												</div>
											</li>
										)
									}
									{
										this.state.info.renewal_totals.map((e, i) =>
											e.text === 'total' &&
											<li className="row" key={i}>
												<div className="col-md-6">{translate.getText('total.installment')}</div>
												<div className="col-md-6">:
													<span>{this.formatMoney(e.value)} ₺</span>
												</div>
											</li>
										)
									}
								</ul>
							}
						</div>
					</div>
				</div>
				<Modal isOpen={this.state.toogleModal} toggle={() => this.handleModal()} className="oc-order-modal">
					<ModalBody>
						<div className="modal-left">
							<img src={modalImage} alt="Logo" />
						</div>
						<div className="modal-right">
							<h4>{translate.getText('order.modal.title')}</h4>
							<p>{translate.getText('order.modal.text_top')}</p>
							<ul className="ct-modal-list">
								<li className="row">
									<div className="col-md-4">{translate.getText('form.company')}</div>
									<div className="col-md-8"><span>:{this.state.data.company.value}</span></div>
								</li>
								<li className="row">
									<div className="col-md-4">{translate.getText('form.full_name')}</div>
									<div className="col-md-8"><span>:{this.state.data.first_name.value} {this.state.data.last_name.value}</span></div>
								</li>
								<li className="row">
									<div className="col-md-4">{translate.getText('form.username')}</div>
									<div className="col-md-8"><span>:{this.state.data.email.value}</span></div>
								</li>
								{
									this.state.product.options.map((option, i) =>
										option.name === 'license' &&
										option.values.map(val =>
											this.state.data_option[option.id] === val.id &&
											<li key={i} className="row">
												<div className="col-md-4">{translate.getText('order.sidebar.selected_license')}</div>
												<div className="col-md-8"><span>:{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}</span></div>
											</li>
										)
									)
								}
								{
									this.state.product.options.map(option =>
										option.name === 'license' &&
										option.values.map(val =>
											this.state.data_option[option.id] === val.id &&
											this.licenseListRev(val.properties[this.state.data_payment.period]).map((vall, valli) =>
												<li key={valli} className="row">
													<div className="col-md-4">{vall.key}</div>
													<div className="col-md-8"><span>:{vall.value}</span></div>
												</li>
											)
										)
									)
								}
								<li className="row">
									<div className="col-md-4">{translate.getText('order.action.license_expdate')}</div>
									<div className="col-md-8">:<span>{translate.getText('order.action.payment_period_' + this.state.info.license_expiration_period)}</span></div>
								</li>
								{
									!this.state.isDemo &&
									this.state.product.options.map((option, i) =>
										option.name !== 'license' &&
										option.values.map(val =>
											this.state.data_option[option.id] === val.id &&
											<li key={i} className="row">
												<div className="col-md-4">{translate.getText('form.option.' + option.name)}</div>
												<div className="col-md-8">
													<span>
														:{translate.getText('form.option.' + option.name + '_' + val.name).toUpperCase()}
														({val.price_format + ' TL'})
													</span>
												</div>
											</li>
										)
									)
								}

								<li className="row" hidden={this.state.isDemo}>
									<div className="col-md-4">{translate.getText('order.action.payment_type')}</div>
									<div className="col-md-8">
										<span>:{translate.getText('order.action.payment_' + this.state.data_payment.type)}</span>
									</div>
								</li>
								<li className="row" hidden={this.state.isDemo}>
									<div className="col-md-4">{translate.getText('order.action.payment_period')}</div>
									<div className="col-md-8">
										<span>:{translate.getText('order.action.payment_period_' + this.state.data_payment.period)}</span>
									</div>
								</li>
								<li className="row" hidden={this.state.isDemo}>
									<div className="col-md-4">{translate.getText('order.action.payment_renewal')}</div>
									<div className="col-md-8">
										<span>:{(this.state.data_payment.renewal) ? translate.getText('general.on') : translate.getText('general.off')}</span>
									</div>
								</li>
								{
									this.state.info.totals.map((e, i) =>
										<li className="row" key={i}>
											<div className="col-md-6">{translate.getText('total.' + e.text)}</div>
											<div className="col-md-6">:
												<span>{this.formatMoney(e.value)} ₺</span>
											</div>
										</li>
									)
								}
							</ul>
							<p className="ct-modal-bottomtext">{translate.getText('order.modal.text_bottom')}</p>
							<div className="ct-form-button-group">
								<Button onClick={() => this.handleModal()} className="ct-form-button btn-back btn-sm">{translate.getText('general.back')}</Button>
								<Button onClick={() => this.handleSubmit()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

export default withRouter(OrderForm);

