import React from 'react';
import { withRouter } from 'react-router-dom';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import translate from '../../Services/TranslateService';
import LoadingService from '../../Services/LoadingService';

class DeleteDistributor extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
	}

	componentDidMount(){
		if (typeof this.props.match.params.id !== 'undefined'){
			const loading = new LoadingService().start();
			APIService.requests
				.del('/distributors/' + this.props.match.params.id)
				.then(() => {
					AlertService.add({
						message: translate.getText('distributor.action.deletemessage'),
						level: 'success',
						autoDismiss: true
					});
					this.props.history.push('/distributor/list');
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('distributor.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					this.props.history.push('/distributor/detail/' + this.props.match.params.id);
					loading.end();
				});
		} else {
			AlertService.add({
				message: translate.getText('error.undefined_url'),
				level: 'danger',
				autoDismiss: true
			});
			this.props.history.push('/distributor/list');
		}
	}

	render() {
		return null;
	}
}

export default withRouter(DeleteDistributor);
