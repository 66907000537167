import React from 'react';
import { Button } from 'reactstrap';
import translate from '../../Services/TranslateService';
import APIService from '../../Services/APIService';
import LoadingService from '../../Services/LoadingService';
import './sidebar.css';
import { inject, observer } from 'mobx-react';

@inject('AuthStore')
@observer
class BalanceButtonBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			distributor_id: this.props.distributor_id,
			balance: this.formatMoney(0),
			invoice: this.formatMoney(0)
		};
		if (!this.props.AuthStore.hasAdmin()) {
			this.state.distributor_id = this.props.AuthStore.getDistributor().id;
		}
		this.reqBalance();
		this.reqInvoice();
	}

	componentWillReceiveProps(nextProps) {
		if (typeof nextProps.distributor_id !== 'undefined' && nextProps.distributor_id !== this.state.distributor_id) {
			this.state.distributor_id = nextProps.distributor_id;
			this.reqBalance();
			this.reqInvoice();
		}
	}

	reqBalance() {
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/payment/balance', {
				filter: {
					distributor: this.state.distributor_id
				}
			})
			.then(data => {
				this.setState({ balance: this.formatMoney(data.balance) });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	reqInvoice() {
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/payment/invoice-price', {
				filter: {
					distributor: this.state.distributor_id
				}
			})
			.then(data => {
				this.setState({ invoice: this.formatMoney(data.total) });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	formatMoney(price) {
		const currency_symbol = '₺';

		const formattedOutput = new Intl.NumberFormat('tr-TR', {
			style: 'currency',
			currency: 'TRY',
			minimumFractionDigits: 2
		});

		return formattedOutput.format(price).replace(currency_symbol, '');
	}

	render() {
		const balance = this.state.balance.split(',');
		const invoice = this.state.invoice.split(',');
		return (
			<div className="ct-content sidebar-balance-box-content">
				<div className="row sidebar-balance-box">
					<div className="col-sm-6 sbb-buttonbox">
						<span>{translate.getText('payment.balance')}</span>
						<Button block className="balance">{balance[0]},<span>{balance[1]} ₺</span></Button>
					</div>
					<div className="col-sm-6 sbb-buttonbox">
						<span>{translate.getText('payment.invoice')}</span>
						<Button block className="invoice">{invoice[0]},<span>{invoice[1]} ₺</span></Button>
					</div>
				</div>
			</div>
		);
	}
}

export default BalanceButtonBox;

