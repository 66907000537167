import React from 'react';
import { withRouter } from 'react-router-dom';
import DealerForm from './DealerForm/DealerForm';

class EditDealer extends React.Component {
	render() {
		return (
			<DealerForm/>
		);
	}
}

export default withRouter(EditDealer);
