import React from 'react';
import { Button, Form, FormGroup, Label, Input, InputGroupAddon, InputGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import translate from '../../Services/TranslateService';
import validation from '../../Services/ValidationService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import LoadingService from '../../Services/LoadingService';
import './login.css';

@observer
@inject('AuthStore', 'CommonStore')
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			input: {
				email: {
					type: 'email',
					minLength: 3,
					maxLength: 255,
					value: '',
					required: true,
					error: false
				},
				password: {
					type: 'string',
					value: '',
					required: true,
					error: false
				},
				rememberme: {
					type: 'checkbox',
					value: false,
					error: false
				}
			},
			errorMessage: ''
		};
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyPress);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyPress);
	}

	handleChange(event) {
		const userData = this.state.input;
		userData[event.target.name].value = event.target.type === 'checkbox' || event.target.type === 'radio' ? event.target.checked : event.target.value;
		this.setState({ input: userData });
	}

	handleSubmit() {
		const loading = new LoadingService().start();
		if (validation.wholeValid(this.state.input)) {
			APIService.requests
				.post('/auth/access_token', {
					email: this.state.input.email.value,
					password: this.state.input.password.value,
					rememberme: this.state.input.rememberme.value
				})
				.then(response => {
					this.props.AuthStore.setLoginProps(response.token, response.user, response.distributor);
					this.props.history.push('/home');
					loading.end();
				})
				.catch(resError => {
					AlertService.error(translate.getText('error.' + resError.response.body.error.code), {
						position: 'top-center'
					});
					loading.end();
				});
		} else {
			const data = validation.wholeValidRef(this.state.input);
			Object.keys(data).forEach(key => {
				if (data[key].error) {
					AlertService.error(
						translate.getTextWithReplace('login.error.' + key + '.' + data[key].error, data[key].min + '-' + data[key].max),
						{
							position: 'top-center'
						}
					);
				}
			});
			this.setState({
				input: data
			});
			loading.end();
		}
	}
	handleKeyPress(e) {
		if (e.key === 'Enter') {
			const loginButton = document.getElementById('login-button');
			loginButton.click();
		}
	}
	render() {
		const data = this.state.input;
		return (
			<div className="login-container">
				<div className="auth-form">
					<img className="form-logo img-fluid" src={require('../../Assets/images/LOGO.svg')} alt="" />
					<Form className="input-form">
						<FormGroup className="has-success">
							<InputGroup className={`login-email ${data.email.error ? 'is-invalid' : ''}`}>
								<InputGroupAddon addonType="prepend">
									<span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
								</InputGroupAddon>
								<Input type="email" name="email"
									className={`login-input ${data.email.error ? 'is-invalid' : ''}`}
									onChange={e => this.handleChange(e)}
									placeholder={translate.getText('login.input.email')}
									value={data.email.value} />
							</InputGroup>
						</FormGroup>
						<FormGroup>
							<InputGroup className={`login-password ${data.password.error ? 'is-invalid' : ''}`}>
								<InputGroupAddon addonType="prepend">
									<span className="input-group-text"><FontAwesomeIcon icon={faUnlockAlt} /></span>
								</InputGroupAddon>
								<Input type="password" name="password"
									className={`login-input ${data.password.error ? 'is-invalid' : ''}`}
									onChange={e => this.handleChange(e)}
									placeholder={translate.getText('login.input.password')}
									value={data.password.value} />
							</InputGroup>
						</FormGroup>
						<Button type="button" id="login-button" className="btn-block btn-login" color="warning" onClick={() => this.handleSubmit()}>{translate.getText('login.buttonlogin')}</Button>
						<FormGroup className="ct-checkbox">
							<Input type="checkbox" id="rememberme" name="rememberme" className="login-checkbox" onChange={e => this.handleChange(e)} value="1" />
							<Label htmlFor="rememberme">{translate.getText('login.rememberme')}</Label>
						</FormGroup>
						<FormGroup className="forgotpassword">
							<Link to="/forgotpassword">{translate.getText('login.forgotpassword')}</Link>
						</FormGroup>
					</Form>
				</div>
			</div>
		);
	}
}

export default Login;
