// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Redirect } from 'react-router-dom';
import translate from './Services/TranslateService';
import AuthStore from './Stores/AuthStore';

import Home from './Pages/Home/Home';
import Logout from './Pages/Logout/Logout';

import UserProfile from './Pages/UserProfile/UserProfile';
import UserDistributor from './Pages/UserProfile/UserDistributor';

import ListDistributor from './Pages/Distributor/ListDistributor';
import DetailDistributor from './Pages/Distributor/DetailDistributor';
import DeleteDistributor from './Pages/Distributor/DeleteDistributor';
import AddDistributor from './Pages/Distributor/AddDistributor';
import EditDistributor from './Pages/Distributor/EditDistributor';

import ListDealer from './Pages/Dealers/ListDealer';
import AddDealer from './Pages/Dealers/AddDealer';
import EditDealer from './Pages/Dealers/EditDealer';
import DeleteDealer from './Pages/Dealers/DeleteDealer';
import DetailDealer from './Pages/Dealers/DetailDealer';

import ListUser from './Pages/User/ListUser';
import AddUser from './Pages/User/AddUser';
import EditUser from './Pages/User/EditUser';
import DetailUser from './Pages/User/DetailUser';
import DeleteUser from './Pages/User/DeleteUser';

import ListOrder from './Pages/Order/ListOrder';
import AddOrder from './Pages/Order/AddOrder';
import EditOrder from './Pages/Order/EditOrder';
import UpgradeOrder from './Pages/Order/UpgradeOrder';
import DetailOrder from './Pages/Order/DetailOrder';
import LoginOrder from './Pages/Order/LoginOrder';

import ListBill from './Pages/Bill/ListBill';
import DetailBill from './Pages/Bill/DetailBill';

const routes = () => {
	const list = [
		{ path: '/', exact: true, name: translate.getText('navigation.home'), component: () => (<Redirect to="/home" />) },
		{ path: '/home', name: translate.getText('navigation.home'), component: Home },
		{ path: '/profile/distributor', name: translate.getText('navigation.distributor_profile'), component: UserDistributor },
		{ path: '/profile', name: translate.getText('navigation.profile'), component: UserProfile },
		{ path: '/logout', name: translate.getText('navigation.logout'), component: Logout },

		{ path: '/dealer', exact: true, name: translate.getText('navigation.dealer'), component: () => (<Redirect to="/dealer/list" />) },
		{ path: '/dealer/list', exact: true, name: translate.getText('navigation.dealer_list'), component: ListDealer },
		{ path: '/dealer/new', name: translate.getText('general.add'), component: AddDealer },
		{ path: '/dealer/edit/:id', name: translate.getText('general.edit'), component: EditDealer },
		{ path: '/dealer/delete/:id', name: translate.getText('general.delete'), component: DeleteDealer },
		{ path: '/dealer/detail/:id', name: translate.getText('general.detail'), component: DetailDealer },
		{ path: '/dealer/*', exact: true, component: () => (<Redirect to="/dealer/list" />) },

		{ path: '/user', exact: true, name: translate.getText('navigation.user'), component: () => (<Redirect to="/user/list" />) },
		{ path: '/user/list', exact: true, name: translate.getText('navigation.user_list'), component: ListUser },
		{ path: '/user/new', name: translate.getText('general.add'), component: AddUser },
		{ path: '/user/detail/:id', name: translate.getText('general.detail'), component: DetailUser },
		{ path: '/user/edit/:id', name: translate.getText('general.edit'), component: EditUser },
		{ path: '/user/delete/:id', name: translate.getText('general.delete'), component: DeleteUser },
		{ path: '/user/*', exact: true, component: () => (<Redirect to="/user/list" />) },

		{ path: '/order', exact: true, name: translate.getText('navigation.order'), component: () => (<Redirect to="/order/list" />) },
		{ path: '/order/list', exact: true, name: translate.getText('navigation.order_list'), component: ListOrder },
		{ path: '/order/detail/:id', name: translate.getText('general.detail'), component: DetailOrder },
		{ path: '/order/new', exact: false, name: translate.getText('general.add'), component: AddOrder },
		{ path: '/order/edit/:id', exact: false, name: translate.getText('general.edit'), component: EditOrder },
		{ path: '/order/upgrade/:id', exact: false, name: translate.getText('general.edit'), component: UpgradeOrder },
		{ path: '/order/order_login/:id', exact: false, name: translate.getText('general.order_login'), component: LoginOrder },
		{ path: '/order/*', exact: true, component: () => (<Redirect to="/order/list" />) },

		{ path: '/bill', exact: true, name: translate.getText('navigation.bill'), component: () => (<Redirect to="/bill/list" />) },
		{ path: '/bill/list', exact: true, name: translate.getText('navigation.bill_list'), component: ListBill },
		{ path: '/bill/detail/:id', name: translate.getText('general.detail'), component: DetailBill },
		{ path: '/bill/*', exact: true, component: () => (<Redirect to="/bill/list" />) }
	];

	if (AuthStore.hasAdmin()) {
		list.push(
			{ path: '/distributor', exact: true, name: translate.getText('navigation.distributor'), component: () => (<Redirect to="/distributor/list" />) },
			{ path: '/distributor/list', name: translate.getText('navigation.distributor_list'), component: ListDistributor },
			{ path: '/distributor/detail/:id', name: translate.getText('navigation.distributor_detail'), component: DetailDistributor },
			{ path: '/distributor/new', name: translate.getText('general.add'), component: AddDistributor },
			{ path: '/distributor/edit/:id', name: translate.getText('general.edit'), component: EditDistributor },
			{ path: '/distributor/delete/:id', name: translate.getText('general.delete'), component: DeleteDistributor },
			{ path: '/distributor/*', exact: true, component: () => (<Redirect to="/distributor/list" />) }
		);
	}

	return list;
};
export default routes;
