import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
@inject('AuthStore')
@observer
class Logout extends React.Component {
	constructor(props){
		super(props);
		this.props.AuthStore.clearLoginProps();
		this.props.history.push('/login');
	}

	render(){
		return null;
	}
}

export default withRouter(Logout);
