import CommonStore from '../Stores/CommonStore';

class LoadingService {
	start(){
		const key = '_' + Math.random().toString(36).substr(2, 9);
		this.key = key;
		CommonStore.setLoading(this.key, true);
		return this;
	}
	end(){
		if (this.key){
			CommonStore.setLoading(this.key, false);
		}
	}
}

export default LoadingService;
