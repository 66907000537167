import React from 'react';
import { withRouter } from 'react-router-dom';
import { UncontrolledTooltip, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import LoadingService from '../../Services/LoadingService';
import APIService from '../../Services/APIService';
import AlertService from '../../Services/AlertService';
import translate from '../../Services/TranslateService';
import './bill.css';
import Moment from 'react-moment';
import { inject, observer } from 'mobx-react';
import BillAction from './Form/BillAction';
import { getIcon } from '../../Components/HistoryIcon';
import BillPaymentNotify from './Form/BillPaymentNotify';

@inject('CommonStore', 'AuthStore')
@observer
class DetailBill extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				distributor: '',
				order: {
					company: '',
					first_name: '',
					last_name: '',
					phone: '',
					mobile_phone: '',
					country: '',
					city: '',
					address: '',
					email: '',
					website: ''
				},
				total_price: '',
				payment_type: '',
				invoice: '',
				status: '',
				create_time: '',
				update_time: ''
			},
			options: [],
			totals: [],
			histories: [],
			activeTab: this.props.AuthStore.hasAdmin() ? 2 : 1
		};
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/bills/' + this.props.match.params.id)
				.then(data => {
					const sdata = this.state.data;
					sdata.order.company = data.order.company;
					sdata.order.first_name = data.order.first_name;
					sdata.order.last_name = data.order.last_name;
					sdata.order.phone = data.order.phone;
					sdata.order.mobile_phone = data.order.mobile_phone;
					sdata.order.country = data.order.country;
					sdata.order.city = data.order.city;
					sdata.order.address = data.order.address;
					sdata.order.email = data.order.email;
					sdata.order.website = data.order.website;
					sdata.total_price = data.total_price_format;
					sdata.payment_type = data.payment_type;
					sdata.payment_date = data.payment_date;
					sdata.invoice = data.invoice;
					sdata.invoice_cutting_date = data.invoice_cutting_date;
					sdata.status = data.status;
					sdata.create_time = data.create_time;
					sdata.update_time = data.update_time;
					if (this.props.AuthStore.hasAdmin()) {
						sdata.distributor = data.distributor.company;
					}
					this.setState({
						data: sdata,
						options: data.options,
						totals: data.totals
					});
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('bill.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		} else {
			this.props.history.push('/bill/list');
		}
	}

	getHistory() {
		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/histories/paginate', {
				filter: {
					bill: this.props.match.params.id
				},
				sort: {
					id: 'DESC'
				}
			})
			.then(response => {
				this.setState({
					histories: response.data
				});
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.add({
					message: translate.getText('history.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			if (tab === 1) {
				this.getHistory();
			}
			this.setState({
				activeTab: tab
			});
		}
	}

	errorComp(key) {
		const data = this.state.action.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	render() {
		const sdata = this.state.data;
		return (
			<div className="ct-container bill-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<div className="content-info ct-form-container-block">
								<div className="head-info">
									<h1>{sdata.order.company}</h1>
									<h3>{sdata.order.first_name} {sdata.order.last_name}</h3>
									<div className={`badge badge-status ${sdata.status}`}>
										{translate.getText('bill_status.' + sdata.status)}
									</div>
								</div>
								<div className="icon-info">
									<div className="row">
										{
											this.props.AuthStore.hasAdmin() &&
											<div className="col-md-6">
												<span className="fa-layers fa-lg fa-fw" id="Tooltip-distributor">
													<i className="fas fa-circle"></i>
													<i className="fa-inverse fas fa-network-wired" data-fa-transform="shrink-8"></i>
												</span>
												<UncontrolledTooltip placement="top" target="Tooltip-distributor">
													{translate.getText('form.distributor')}
												</UncontrolledTooltip>
												<label>{sdata.distributor}</label>
											</div>
										}
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-phone">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-phone" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-phone">
												{translate.getText('form.phone')}
											</UncontrolledTooltip>
											<label>{sdata.order.phone}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-mobile_phone">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-mobile-alt" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-mobile_phone">
												{translate.getText('form.mobile_phone')}
											</UncontrolledTooltip>
											<label>{sdata.order.mobile_phone}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-email">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-envelope" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-email">
												{translate.getText('form.email')}
											</UncontrolledTooltip>
											<label>{sdata.order.email}</label>
										</div>
										<div className="col-md-6">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-website">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-globe" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-website">
												{translate.getText('form.website')}
											</UncontrolledTooltip>
											<label>{sdata.order.website}</label>
										</div>
										<div className="col-md-12">
											<span className="fa-layers fa-lg fa-fw" id="Tooltip-address">
												<i className="fas fa-circle"></i>
												<i className="fa-inverse fas fa-map-marker-alt" data-fa-transform="shrink-8"></i>
											</span>
											<UncontrolledTooltip placement="top" target="Tooltip-address">
												{translate.getText('form.address')}
											</UncontrolledTooltip>
											<label>{sdata.order.address}</label>
										</div>
										<div className="col-md-6">
											<div className="badge badge-icon" id="Tooltip-country">
												{sdata.order.country} / {sdata.order.city}
											</div>
											<UncontrolledTooltip placement="top" target="Tooltip-country">
												{translate.getText('form.country')} / {translate.getText('form.city')}
											</UncontrolledTooltip>
										</div>
										<div className="col-md-6">
											<div className="badge badge-icon" id="Tooltip-create_date">
												{sdata.create_time}
											</div>
											<UncontrolledTooltip placement="top" target="Tooltip-create_date">
												{translate.getText('form.create_date')}
											</UncontrolledTooltip>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<Nav tabs>
							<NavItem>
								<NavLink
									active={this.state.activeTab === 0}
									onClick={() => this.toggleTab(0)}
								>
									{translate.getText('list.bill_option')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									active={this.state.activeTab === 1}
									onClick={() => this.toggleTab(1)}
								>
									{translate.getText('list.bill_history')}
								</NavLink>
							</NavItem>
							{
								this.props.AuthStore.hasAdmin() &&
								<NavItem>
									<NavLink
										active={this.state.activeTab === 2}
										onClick={() => this.toggleTab(2)}
									>
										{translate.getText('list.bill_action')}
									</NavLink>
								</NavItem>
							}
							{
								this.state.data.payment_type === 'none' &&
								<NavItem>
									<NavLink
										active={this.state.activeTab === 3}
										onClick={() => this.toggleTab(3)}
									>
										{translate.getText('list.bill_payment_notify')}
									</NavLink>
								</NavItem>
							}
						</Nav>
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId={0}>
								<div className="row">
									<div className="col-md-12">
										<div className="ct-content">
											<div className="invoice-text">
												<span>{translate.getText('bill.invoice_text')}:</span> {this.props.match.params.id} / {(sdata.invoice === '') ? '-' : sdata.invoice + '(' + sdata.invoice_cutting_date + ')'}
											</div>
											{
												sdata.payment_date !== null &&
												<div className="invoice-text">
													<span>{translate.getText('bill.payment_date')}:</span> {sdata.payment_date}
												</div>
											}
											<table className="table  ct-table ct-table-style-2 text-center">
												<thead>
													<tr>
														<th scope="col" className="text-left">#</th>
														<th scope="col">{translate.getText('list.option_name')}</th>
														<th scope="col">{translate.getText('list.option_value')}</th>
														<th scope="col">{translate.getText('list.period')}</th>
														<th scope="col">{translate.getText('list.price')}</th>
														<th scope="col">{translate.getText('list.total_price')}</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.options.map((billoption, i) =>
															<tr key={i}>
																<th scope="row" className="text-left">{billoption.id}</th>
																<td>{translate.getText('form.option.' + billoption.order_option.product_option.name)}</td>
																<td>{translate.getText(
																	'form.option.' +
																	billoption.order_option.product_option.name +
																	'_' +
																	billoption.order_option.product_option_value.name
																).toUpperCase()}</td>
																<td>{translate.getText('order.action.payment_period_' + billoption.period)}</td>
																<td>{billoption.price_format} ₺</td>
																<td>{billoption.total_price_format} ₺</td>
															</tr>
														)
													}
													<tr style={{ backgroundColor: '#fff' }}>
														<td colSpan="4"></td>
														<td colSpan="2" style={{ padding: '0' }}>
															<table className="table  ct-table ct-table-style-sub text-right">
																<tbody>
																	<tr>
																		<td className="text-right">{translate.getText('form.payment_type')} :</td>
																		<td className="text-right">{translate.getText('payment.' + sdata.payment_type)}</td>
																	</tr>
																	{
																		this.state.totals.map((te, ti) =>
																			<tr key={ti}>
																				<td className="text-right">{translate.getText('total.' + te.text)} :</td>
																				<td>{te.value} ₺</td>
																			</tr>
																		)
																	}
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId={1}>
								<div className="row">
									<div className="col-md-12">
										<div className="ct-content">
											<ul className="ct-timeline">
												{
													this.state.histories.map((history, i) =>
														<li className={`event ${history.status}`} key={i}>
															<div className="event-left center-item">
																<div className="event-date">
																	<Moment className="lg-date" format="DD MMMM YYYY, HH:mm">{history.create_time}</Moment>
																	<Moment className="sm-date" fromNow>{history.create_time}</Moment>
																</div>
															</div>
															<div className="event-right">
																<div className="title">{translate.getText('history_status.' + history.status)}</div>
																<div className="subtitle">{history.user.first_name} {history.user.last_name} ({history.user.title})</div>
																{
																	history.detail !== '' &&
																	<p>{history.detail}</p>
																}
															</div>
															<div className="event-icon">
																<i className={`fa-inverse fas fa-${getIcon(history.status)}`}></i>
															</div>
														</li>
													)
												}
											</ul>
										</div>
									</div>
								</div>
							</TabPane>
							{
								this.props.AuthStore.hasAdmin() &&
								<TabPane tabId={2}>
									<BillAction refresh={() => this.componentDidMount()}/>
								</TabPane>
							}
							{
								this.state.data.payment_type === 'none' &&
								<TabPane tabId={3}>
									<BillPaymentNotify refresh={() => this.componentDidMount()}/>
								</TabPane>
							}
						</TabContent>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DetailBill);
