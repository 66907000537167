import tr from './i18n/tr';
import en from './i18n/en';
import CommonStore from '../Stores/CommonStore';
import Parser from 'html-react-parser';

class TranslateService {
	getText(text){
		this.Language = CommonStore.appLanguage;
		if (this.Language === 'tr'){
			text = this.value(tr, text);
		} else if (this.Language === 'en'){
			text = this.value(en, text);
		}
		text = Parser(text);
		return text;
	}
	getTextWithReplace(text, ...params){
		text = this.getText(text);
		for (let i = 0; i < params.length; i++){
			text = text.replace('%s', params[i]);
		}
		return text;
	}
	value(layer, path) {
		let i = 0;
		const spath = path.split('.');
		for (; i < spath.length; i++){
			if (layer[spath[i]] === undefined){
				console.log('There is no match in keys: ' + path);
				return path;
			}
			layer = layer[spath[i]];
		}

		return layer;
	}
}

export default new TranslateService();
