import React from 'react';
import { withRouter } from 'react-router-dom';
import translate from '../../../Services/TranslateService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';
import ValidationService from '../../../Services/ValidationService';
import { Button, FormGroup, Label, Input, Col, CustomInput } from 'reactstrap';
import LoadingService from '../../../Services/LoadingService';
import { inject, observer } from 'mobx-react';

@inject('CommonStore', 'AuthStore')
@observer
class BillPaymentNotify extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.resetForm()
		};
	}

	resetForm() {
		const data = {
			payment_type: {
				type: 'string',
				value: '',
				required: true,
				error: false
			},
			detail: {
				type: 'string',
				value: '',
				required: false,
				error: false
			}
		};
		return data;
	}

	/* componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/bills/' + this.props.match.params.id)
				.then(data => {
					const sdata = this.state.data;
					sdata.status.value = data.status;
					sdata.invoice_no.value = data.invoice;
					sdata.invoice_cutting_date.value = (data.invoice_cutting_date === null) ? new Date() : data.invoice_cutting_date;
					sdata.invoice_cutting_date.hasNone = (data.invoice_cutting_date === null);
					sdata.payment_date.value = (data.payment_date === null) ? new Date() : data.payment_date;
					sdata.payment_date.hasNone = (data.payment_date === null);

					this.setState({
						data: sdata
					});
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('bill.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		}
	}*/

	changeHandle(e) {
		const data = this.state.data;
		data[e.target.id].value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		this.setState({ data: data });
	}

	changeKeyValueHandle(key, value) {
		const data = this.state.data;
		data[key].value = value;
		this.setState({ data: data });
	}

	handleSubmit() {
		const loading = new LoadingService().start();
		let data = this.state.data;
		if (ValidationService.wholeValid(data)) {
			const post = {
				payment_type: data.payment_type.value,
				detail: data.detail.value
			};
			APIService.requests
				.put('/bills/' + this.props.match.params.id + '/notify', post)
				.then(() => {
					AlertService.add({
						message: translate.getText('bill.action.actionsavemessage'),
						level: 'success',
						autoDismiss: true
					});
					this.props.refresh();
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('bill.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
						this.props.refresh();
					}
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			this.setState({
				data: data
			});
			loading.end();
		}
	}

	errorComp(key) {
		const data = this.state.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	render() {
		if (typeof this.props.match.params.id === 'undefined') {
			return null;
		}
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="ct-content">
						<div className="row">
							<div className="col-6">
								<FormGroup row>
									<Label sm={4} for="payment_type">{translate.getText('form.payment_type')}</Label>
									<Col sm={8}>
										<CustomInput
											value={this.state.data.payment_type.value}
											type="select" id="payment_type" name="payment_type" onChange={e => this.changeHandle(e)}>
											<option value="">{translate.getText('general.select')}</option>
											<option value="balance">{translate.getText('payment.balance')}</option>
											<option value="invoice">{translate.getText('payment.invoice')}</option>
										</CustomInput>
										{this.errorComp('payment_type')}
									</Col>
								</FormGroup>
							</div>
							<div className="col-12">
								<FormGroup row>
									<Label sm={2} className="ct-form-label" for="detail">{translate.getText('form.detail')}</Label>
									<Col sm={10}>
										<Input value={this.state.data.detail.value} type="textarea" id="detail" name="detail" onChange={e => this.changeHandle(e)}>
										</Input>
										{this.errorComp('detail')}
									</Col>
								</FormGroup>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<div className="ct-content">
						<div className="ct-form-button-group">
							<Button onClick={() => this.handleSubmit()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(BillPaymentNotify);

