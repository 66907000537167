import React from 'react';
import { withRouter } from 'react-router-dom';
import translate from '../../../Services/TranslateService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';
import ValidationService from '../../../Services/ValidationService';
import { Button, FormGroup, Label, Input, Col, CustomInput } from 'reactstrap';
import LoadingService from '../../../Services/LoadingService';
import { inject, observer } from 'mobx-react';
import AutoComplete from '../../../Components/AutoComplete/AutoComplete';
import PhoneInput from 'react-phone-number-input';

@inject('CommonStore', 'AuthStore')
@observer
class DealerForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.resetForm(),
			countries: [],
			cities: []
		};
		this.reqCountries();
	}

	resetForm() {
		const data = {
			company: {
				type: 'string',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: true,
				error: false
			},
			first_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: true,
				error: false
			},
			last_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: true,
				error: false
			},
			country_id: {
				type: 'integer',
				value: 215,
				required: true,
				error: false
			},
			city_id: {
				type: 'integer',
				value: '',
				required: true,
				error: false
			},
			email: {
				type: 'email',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: true,
				error: false
			},
			phone: {
				type: 'phone',
				minLength: 3,
				maxLength: 100,
				value: '',
				required: true,
				error: false
			},
			address: {
				type: 'string',
				value: '',
				required: true,
				error: false
			},
			status: {
				type: 'boolean',
				value: true,
				required: false,
				error: false
			}
		};
		if (this.props.AuthStore.hasAdmin()) {
			data.distributor_id = {
				type: 'integer',
				label: '',
				value: null,
				required: true,
				error: false
			};
		}

		return data;
	}

	reqCountries() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/countries')
			.then(data => {
				this.setState({ countries: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	reqCities() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/cities/' + this.state.data.country_id.value)
			.then(data => {
				this.setState({ cities: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/dealers/' + this.props.match.params.id)
				.then(data => {
					const sdata = this.resetForm();
					sdata.company.value = data.company;
					sdata.first_name.value = data.first_name;
					sdata.last_name.value = data.last_name;
					sdata.email.value = data.email;
					sdata.phone.value = data.phone;
					sdata.country_id.value = data.country.id;
					sdata.city_id.value = data.city.id;
					sdata.address.value = data.address;
					sdata.status.value = data.status === 1;
					if (this.props.AuthStore.hasAdmin()) {
						sdata.distributor_id.value = data.distributor.id;
						sdata.distributor_id.label = data.distributor.company;
					}
					this.setState({ data: sdata });
					this.reqCities();
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						const data = this.state.data;
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('dealer.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			this.setState({ data: this.resetForm() });
			this.reqCities();
		}
	}

	changeHandle(e) {
		const data = this.state.data;
		data[e.target.id].value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		this.setState({ data: data });
	}

	changeKeyValueHandle(key, value) {
		const data = this.state.data;
		data[key].value = value;
		this.setState({ data: data });
	}

	changeCountryHandle(e) {
		this.changeHandle(e);
		this.reqCities();
	}

	handleSubmit() {
		const loading = new LoadingService().start();
		let data = this.state.data;
		if (ValidationService.wholeValid(data)) {
			let req = APIService.requests;
			const post = {
				company: data.company.value,
				first_name: data.first_name.value,
				last_name: data.last_name.value,
				email: data.email.value,
				phone: data.phone.value,
				country_id: data.country_id.value,
				city_id: data.city_id.value,
				address: data.address.value,
				status: data.status.value
			};
			if (this.props.AuthStore.hasAdmin()) {
				post.distributor_id = data.distributor_id.value;
			}

			if (typeof this.props.match.params.id !== 'undefined') {
				req = req.put('/dealers/' + this.props.match.params.id, post);
			} else {
				req = req.post('/dealers', post);
			}

			req.then(() => {
				AlertService.add({
					message: translate.getText('dealer.action.savemessage'),
					level: 'success',
					autoDismiss: true
				});
				loading.end();
				this.props.history.push('/dealer/list');
			})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('dealer.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			this.setState({
				data: data
			});
			loading.end();
		}
	}

	handleBack() {
		if (typeof this.props.match.params.id !== 'undefined') {
			this.props.history.push('/dealer/detail/' + this.props.match.params.id);
		} else {
			this.props.history.push('/dealer/list');
		}
	}

	errorComp(key) {
		const data = this.state.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	render() {
		return (
			<div className="dealer-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<h4>{(typeof this.props.match.params.id === 'undefined') ? translate.getText('dealer.title.add') : translate.getText('dealer.title.edit')}</h4>
							<div className="row">
								{
									this.props.AuthStore.hasAdmin() &&
									<div className="col-12">
										<FormGroup row>
											<Label sm={3} for="distributor_id">{translate.getText('form.dealer')}</Label>
											<Col sm={9}>
												<AutoComplete
													value={this.state.data.distributor_id.label}
													id="distributor_id"
													name="distributor_id"
													onChange={e => this.changeKeyValueHandle('distributor_id', e)}
													api={{
														url: '/distributors/paginate',
														param: 'company',
														cons_param: {
															status: 1
														}
													}}
												/>
												{this.errorComp('distributor_id')}
											</Col>
										</FormGroup>
									</div>
								}
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="company">{translate.getText('form.company')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.company.value} type="text" id="company" name="company" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('company')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="first_name">{translate.getText('form.first_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.first_name.value} type="text" id="first_name" name="first_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('first_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="last_name">{translate.getText('form.last_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.last_name.value} type="text" id="last_name" name="last_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('last_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="email">{translate.getText('form.email')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.email.value} type="text" id="email" name="email" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('email')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="phone">{translate.getText('form.phone')}</Label>
										<Col sm={9}>
											<PhoneInput
												id="phone" name="phone"
												placeholder="+9..."
												value={this.state.data.phone.value}
												onChange={phone => this.changeKeyValueHandle('phone', phone)} />
											{this.errorComp('phone')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="country_id">{translate.getText('form.country')}</Label>
										<Col sm={6}>
											<CustomInput
												value={this.state.data.country_id.value}
												autoComplete="off"
												type="select" id="country_id" name="country_id" onChange={e => this.changeCountryHandle(e)}>
												<option value="">{translate.getText('general.select')}</option>
												{
													this.state.countries.map((e, i) =>
														<option key={i} value={e.id}>{e.name}</option>
													)
												}
											</CustomInput>
											{this.errorComp('country_id')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Col sm={2}></Col>
										<Label sm={4} for="city_id">{translate.getText('form.city')}</Label>
										<Col sm={6}>
											<CustomInput
												value={this.state.data.city_id.value}
												autoComplete="off"
												type="select" id="city_id" name="city_id" onChange={e => this.changeHandle(e)}>
												<option value="">{translate.getText('general.select')}</option>
												{
													this.state.cities.map((e, i) =>
														<option key={i} value={e.id}>{e.name}</option>
													)
												}
											</CustomInput>
											{this.errorComp('city_id')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="address">{translate.getText('form.address')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.address.value} type="textarea" id="address" name="address" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('address')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="status">{translate.getText('form.status')}</Label>
										<Col sm={9}>
											<CustomInput checked={(this.state.data.status.value)} type="switch" id="status" name="status" onChange={e => this.changeHandle(e)} />
											{this.errorComp('status')}
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className="ct-content">
							<div className="ct-form-button-group">
								<Button onClick={() => this.handleBack()} className="ct-form-button btn-back btn-sm">{translate.getText('general.back')}</Button>
								<Button onClick={() => this.handleSubmit()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DealerForm);

