import React from 'react';
import { withRouter } from 'react-router-dom';
import translate from '../../../Services/TranslateService';
import APIService from '../../../Services/APIService';
import AlertService from '../../../Services/AlertService';
import ValidationService from '../../../Services/ValidationService';
import { Button, FormGroup, Label, CustomInput, Input, Col } from 'reactstrap';
import LoadingService from '../../../Services/LoadingService';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import PhoneInput from 'react-phone-number-input';

class DistributorForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.defaultForm(),
			countries: [],
			cities: []
		};
		this.reqCountries();
	}

	defaultForm() {
		const newDate = new Date();
		newDate.setHours(0);
		newDate.setMinutes(0);
		newDate.setHours(newDate.getHours() + 24);
		const expirationDate = newDate.getFullYear() + '-' + this.doubleNumber(newDate.getMonth() + 1) + '-' + this.doubleNumber(newDate.getDate()) + ' ' + this.doubleNumber(newDate.getHours()) + ':' + this.doubleNumber(newDate.getMinutes());
		return {
			company: {
				type: 'string',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: true,
				error: false
			},
			first_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: false,
				error: false
			},
			last_name: {
				type: 'string',
				minLength: 3,
				maxLength: 50,
				value: '',
				required: false,
				error: false
			},
			country_id: {
				type: 'integer',
				value: 215,
				required: true,
				error: false
			},
			city_id: {
				type: 'integer',
				value: '',
				required: true,
				error: false
			},
			address: {
				type: 'string',
				value: '',
				required: true,
				error: false
			},
			email: {
				type: 'email',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: true,
				error: false
			},
			phone: {
				type: 'phone',
				minLength: 3,
				maxLength: 100,
				value: '',
				required: true,
				error: false
			},
			mobile_phone: {
				type: 'phone',
				minLength: 3,
				maxLength: 100,
				value: '',
				required: false,
				error: false
			},
			website: {
				type: 'url',
				minLength: 3,
				maxLength: 255,
				value: '',
				required: false,
				error: false
			},
			status: {
				type: 'boolean',
				value: true,
				required: false,
				error: false
			},
			balance: {
				type: 'decimal',
				value: 0,
				required: true,
				error: false
			},
			discount: {
				type: 'decimal',
				value: 0,
				required: true,
				error: false
			},
			discount_expiration_date: {
				type: 'date',
				value: expirationDate,
				required: true,
				error: false
			}
		};
	}

	reqCountries() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/countries')
			.then(data => {
				this.setState({ countries: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	reqCities() {
		const loading = new LoadingService().start();
		APIService.requests
			.get('/cities/' + this.state.data.country_id.value)
			.then(data => {
				this.setState({ cities: data });
				loading.end();
			})
			.catch(() => {
				loading.end();
			});
	}

	componentDidMount() {
		if (typeof this.props.match.params.id !== 'undefined') {
			const loading = new LoadingService().start();
			APIService.requests
				.get('/distributors/' + this.props.match.params.id)
				.then(data => {
					const sdata = this.state.data;
					sdata.company.value = data.company;
					sdata.first_name.value = data.first_name;
					sdata.last_name.value = data.last_name;
					sdata.country_id.value = data.country.id;
					sdata.city_id.value = data.city.id;
					sdata.address.value = data.address;
					sdata.email.value = data.email;
					sdata.phone.value = data.phone;
					sdata.mobile_phone.value = data.mobile_phone;
					sdata.website.value = data.website;
					sdata.status.value = data.status === 1;
					sdata.balance.value = data.balance;
					sdata.discount.value = data.discount.value;
					sdata.discount_expiration_date.value = data.discount.expiration_time;
					this.setState({ data: sdata });
					this.reqCities();
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					AlertService.add({
						message: translate.getText('distributor.error.' + body.error.code),
						level: 'danger',
						autoDismiss: true
					});
					loading.end();
				});
		} else {
			this.setState({ data: this.defaultForm() });
			this.reqCities();
		}
	}

	changeHandle(e) {
		const data = this.state.data;
		data[e.target.id].value = e.target.type === 'checkbox' || e.target.type === 'radio' ? e.target.checked : e.target.value;
		if (e.target.id === 'country_id') {
			data.city_id.value = 0;
		}
		this.setState({ data: data });
	}

	changeKeyValueHandle(key, value) {
		const data = this.state.data;
		data[key].value = value;
		this.setState({ data: data });
	}

	changeDateHandle(value) {
		const data = this.state.data;
		const expirationDate = value.getFullYear() + '-'
			+ this.doubleNumber(value.getMonth() + 1) + '-'
			+ this.doubleNumber(value.getDate()) + ' '
			+ this.doubleNumber(value.getHours()) + ':'
			+ this.doubleNumber(value.getMinutes()) + ':'
			+ this.doubleNumber(value.getSeconds());

		data.discount_expiration_date.value = expirationDate;
		this.setState({ data: data });
	}

	doubleNumber(n) {
		return n > 9 ? '' + n : '0' + n;
	}

	changeCountryHandle(e) {
		this.changeHandle(e);
		this.reqCities();
	}

	handleSubmit() {
		const loading = new LoadingService().start();
		let data = this.state.data;
		if (ValidationService.wholeValid(data)) {
			let req = APIService.requests;
			const post = {
				country_id: data.country_id.value,
				city_id: data.city_id.value,
				company: data.company.value,
				first_name: data.first_name.value,
				last_name: data.last_name.value,
				address: data.address.value,
				email: data.email.value,
				phone: data.phone.value,
				mobile_phone: data.mobile_phone.value,
				website: data.website.value,
				status: data.status.value,
				discount: {
					value: data.discount.value,
					expiration_time: data.discount_expiration_date.value
				},
				balance: data.balance.value
			};

			if (typeof this.props.match.params.id !== 'undefined') {
				req = req.put('/distributors/' + this.props.match.params.id, post);
			} else {
				req = req.post('/distributors', post);
			}

			req.then(() => {
				AlertService.add({
					message: translate.getText('distributor.action.savemessage'),
					level: 'success',
					autoDismiss: true
				});
				loading.end();
				this.props.history.push('/distributor/list');
			})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						this.setState({
							data: data
						});
					} else {
						AlertService.add({
							message: translate.getText('distributor.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			this.setState({
				data: data
			});
			loading.end();
		}
	}

	handleBack() {
		if (typeof this.props.match.params.id !== 'undefined') {
			this.props.history.push('/distributor/detail/' + this.props.match.params.id);
		} else {
			this.props.history.push('/distributor/list');
		}
	}

	errorComp(key) {
		const data = this.state.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	render() {
		return (
			<div className="dealer-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content">
							<h4>{(typeof this.props.match.params.id === 'undefined') ? translate.getText('distributor.title.add') : translate.getText('distributor.title.edit')}</h4>
							<div className="row">
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="company">{translate.getText('form.company')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.company.value} type="text" id="company" name="company" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('company')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} className="ct-form-label" for="email">{translate.getText('form.email')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.email.value} type="text" id="email" name="email" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('email')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="first_name">{translate.getText('form.first_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.first_name.value} type="text" id="first_name" name="first_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('first_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="last_name">{translate.getText('form.last_name')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.last_name.value} type="text" id="last_name" name="last_name" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('last_name')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="address">{translate.getText('form.address')}</Label>
										<Col sm={9}>
											<Input value={this.state.data.address.value} type="textarea" id="address" name="address" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('address')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="country_id">{translate.getText('form.country')}</Label>
										<Col sm={6}>
											<CustomInput
												value={this.state.data.country_id.value}
												autoComplete="off"
												type="select" id="country_id" name="country_id" onChange={e => this.changeCountryHandle(e)}>
												<option value="">{translate.getText('general.select')}</option>
												{
													this.state.countries.map((e, i) =>
														<option key={i} value={e.id}>{e.name}</option>
													)
												}
											</CustomInput>
											{this.errorComp('country_id')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Col sm={2}></Col>
										<Label sm={4} for="city_id">{translate.getText('form.city')}</Label>
										<Col sm={6}>
											<CustomInput
												value={this.state.data.city_id.value}
												autoComplete="off"
												type="select" id="city_id" name="city_id" onChange={e => this.changeHandle(e)}>
												<option value="">{translate.getText('general.select')}</option>
												{
													this.state.cities.map((e, i) =>
														<option key={i} value={e.id}>{e.name}</option>
													)
												}
											</CustomInput>
											{this.errorComp('city_id')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="phone">{translate.getText('form.phone')}</Label>
										<Col sm={6}>
											<PhoneInput
												id="phone" name="phone"
												placeholder="+9..."
												value={this.state.data.phone.value}
												onChange={phone => this.changeKeyValueHandle('phone', phone)} />
											{this.errorComp('phone')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Col sm={2}></Col>
										<Label sm={4} for="mobile_phone">{translate.getText('form.mobile_phone')}</Label>
										<Col sm={6}>
											<PhoneInput
												id="mobile_phone" name="mobile_phone"
												placeholder="+9..."
												value={this.state.data.mobile_phone.value}
												onChange={phone => this.changeKeyValueHandle('mobile_phone', phone)} />
											{this.errorComp('mobile_phone')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="website">{translate.getText('form.website')}</Label>
										<Col sm={6}>
											<Input value={this.state.data.website.value} type="text" id="website" name="website" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('website')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="status">{translate.getText('form.status')}</Label>
										<Col sm={9}>
											<CustomInput checked={(this.state.data.status.value)} type="switch" id="status" name="status" onChange={e => this.changeHandle(e)} />
											{this.errorComp('status')}
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className="ct-content">
							<h4>{translate.getText('distributor.title.other_form')}</h4>
							<div className="row">
								<div className="col-12">
									<FormGroup row>
										<Label sm={3} for="balance">{translate.getText('form.balance')}</Label>
										<Col sm={3}>
											<Input value={this.state.data.balance.value} type="number" min="0" step="any" id="balance" name="balance" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('balance')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Label sm={6} for="discount">{translate.getText('form.discount')}</Label>
										<Col sm={6}>
											<Input value={this.state.data.discount.value} type="number" min="0" step="any" id="discount" name="discount" onChange={e => this.changeHandle(e)}>
											</Input>
											{this.errorComp('discount')}
										</Col>
									</FormGroup>
								</div>
								<div className="col-6">
									<FormGroup row>
										<Col sm={2}></Col>
										<Label sm={4} for="discount_expiration_date">{translate.getText('form.expiration_date')}</Label>
										<Col sm={6}>
											<DateTimePicker
												value={new Date(this.state.data.discount_expiration_date.value)}
												onChange={e => this.changeDateHandle(e)}
												format="y-MM-dd HH:mm:ss"
												name="discount_expiration_date"
												id="discount_expiration_date"
												clearIcon={null}
											/>
											{this.errorComp('discount_expiration_date')}
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className="ct-content">
							<div className="ct-form-button-group">
								<Button onClick={() => this.handleBack()} className="ct-form-button btn-back btn-sm">{translate.getText('general.back')}</Button>
								<Button onClick={() => this.handleSubmit()} className="ct-form-button btn-submit btn-sm">{translate.getText('general.save')}</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(DistributorForm);

