import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button, InputGroup, CustomInput, Input, FormGroup, Modal, ModalHeader, ModalBody, Label, Col, ModalFooter } from 'reactstrap';
import Moment from 'react-moment';
import APIService from '../../Services/APIService';
import translate from '../../Services/TranslateService';
import AlertService from '../../Services/AlertService';
import LoadingService from '../../Services/LoadingService';
import AutoComplete from '../../Components/AutoComplete/AutoComplete';
import ValidationService from '../../Services/ValidationService';

@inject('CommonStore', 'AuthStore')
@observer
class ListOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			filter: {},
			sortable: {},
			filterChange: false,
			advantedFilter: false,
			page: 1,
			limit: 10,
			maxPage: 1,
			total: 0,
			pagination: [],
			selected: [],
			updateSelected: {
				data: {
					dealer_id: {
						type: 'integer',
						value: null,
						label: '',
						required: true,
						error: false
					}
				},
				modalToggle: false
			}
		};

		if (this.props.AuthStore.hasAdmin()) {
			this.state.updateSelected.data.distributor_id = {
				type: 'integer',
				label: '',
				value: null,
				required: true,
				error: false
			};
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	changeSortable(key, event) {
		Array.from(ReactDOM.findDOMNode(event.target).parentNode.children).forEach(subEvent => {
			subEvent.classList.remove('down');
			subEvent.classList.remove('up');
		});
		const sortable = {};
		if (typeof this.state.sortable[key] !== undefined && this.state.sortable[key] === 'DESC') {
			event.target.classList.add('up');
			sortable[key] = 'ASC';
		} else {
			event.target.classList.add('down');
			sortable[key] = 'DESC';
		}
		this.fetchData(1, null, sortable);
	}

	changeFilter(e) {
		const filter = this.state.filter;
		filter[e.target.id] = e.target.value;
		this.setState({
			filter: filter,
			filterChange: true
		});

		this.fetchData(); // Remove it for more performance
	}

	changePageLimit(i) {
		this.fetchData(1, i);
	}

	changePage(i) {
		this.fetchData(i);
	}

	fetchData(setpage = null, setlimit = null, sortable = null) {
		let page = (setpage !== null) ? setpage : this.state.page;
		const limit = (setlimit !== null) ? setlimit : this.state.limit;
		if (sortable !== null) {
			this.setState({
				sortable: sortable
			});
		} else {
			sortable = this.state.sortable;
		}
		if (this.state.filterChange === true) {
			page = 1;
		}

		const loading = new LoadingService().start();
		APIService.requests
			.getByParam('/orders/paginate', {
				filter: this.state.filter,
				page: page,
				limit: limit,
				sort: sortable
			})
			.then(response => {
				this.setState({
					data: response.data,
					page: response.page,
					limit: response.limit,
					maxPage: response.max_page,
					total: response.total,
					filterChange: false
				});
				this.pageNumberList();
				loading.end();
			})
			.catch(error => {
				const body = error.response.body;
				AlertService.Add({
					message: translate.getText('order.error.' + body.error.code),
					level: 'danger',
					autoDismiss: true
				});
				loading.end();
			});
	}

	pageNumberList() {
		const page = this.state.page;
		const numbers = [];
		for (let i = (page - 5 < 1) ? 1 : page - 5; i < page + 6; i++) {
			if (i > this.state.maxPage) {
				break;
			}
			numbers.push(i);
		}
		this.setState({
			pagination: numbers
		});
	}

	changeSelected(id) {
		const selected = this.state.selected;
		const index = selected.indexOf(id);
		if (index !== -1) {
			selected.splice(index, 1);
		} else {
			selected.push(id);
		}

		this.setState({
			selected: selected
		});
	}

	updateSelectederrorComp(key) {
		const data = this.state.updateSelected.data;
		return (
			<small hidden={data[key].error === false} className="text-danger">
				{`${data[key].error !== false ? translate.getTextWithReplace('error.' + data[key].error, data[key].min + '-' + data[key].max) : ''}`}
			</small>
		);
	}

	updateSelectedchangeKeyValueHandle(key, value) {
		const updateSelected = this.state.updateSelected;
		updateSelected.data[key].value = value;
		if (key === 'distributor_id') {
			updateSelected.data.dealer_id.value = null;
			updateSelected.data.dealer_id.label = '';
		}
		this.setState({ updateSelected: updateSelected });
	}

	updateSelectedhandleSubmit() {
		const loading = new LoadingService().start();
		const updateSelected = this.state.updateSelected;
		let data = updateSelected.data;
		const post = {
			ids: this.state.selected,
			dealer_id: data.dealer_id.value
		};
		if (this.props.AuthStore.hasAdmin()) {
			post.distributor_id = data.distributor_id.value;
		}
		if (this.state.selected.length < 1) {
			AlertService.add({
				message: translate.getText('error.error_multi_select'),
				level: 'danger',
				autoDismiss: true
			});
			loading.end();
			return;
		}
		if (ValidationService.wholeValid(data)) {
			APIService.requests.put('/orders/items', post)
				.then(() => {
					AlertService.add({
						message: translate.getText('order.action.saveordermessage'),
						level: 'success',
						autoDismiss: true
					});
					this.props.history.push('/order/');
					loading.end();
				})
				.catch(error => {
					const body = error.response.body;
					if (body.error.code === 'validation') {
						Object.keys(body.error.detail).forEach(k => {
							data[k].error = body.error.detail[k];
						});
						updateSelected.data = data;
						this.setState({
							updateSelected: updateSelected
						});
					} else {
						AlertService.add({
							message: translate.getText('order.error.' + body.error.code),
							level: 'danger',
							autoDismiss: true
						});
					}
					loading.end();
				});
		} else {
			data = ValidationService.wholeValidRef(data);
			updateSelected.data = data;
			this.setState({
				updateSelected: updateSelected
			});
			loading.end();
		}
	}

	render() {
		const selected = this.state.selected;
		return (
			<div className="order-container">
				<div className="row">
					<div className="col-md-12">
						<div className="ct-content oc-left-list">
							<h2>{translate.getText('order.title.list')}</h2>
							<div className="ct-row">
								<div className="ct-content-toolbar">
									<FormGroup className="standart-top-filter">
										<InputGroup>
											<Input
												onChange={e => this.changeFilter(e)}
												id="company" name="company" type="text"
												placeholder={translate.getText('general.filterinput')} />
											<Button className="btn-sm btn-success" onClick={() => this.setState({ advantedFilter: !this.state.advantedFilter })}>
												<i className="fa fa-filter"></i> {translate.getText('general.advanced_filter')}
											</Button>
											<Button className="btn-sm btn-success" onClick={() => this.setState({ updateSelected: { ...this.state.updateSelected, modalToggle: !this.state.updateSelected.modalToggle } })}>
												<i className="fa fa-arrows-alt"></i> {translate.getText('general.update_selected')}
											</Button>
											<Button className="btn-sm btn-success" onClick={() => this.props.history.push('/order/new')}>
												<i className="fa fa-plus-circle"></i> {translate.getText('order.action.addorder')}
											</Button>
										</InputGroup>
									</FormGroup>
								</div>
								<div className="ct-content-list">
									<div className="ct-content-filter" hidden={!this.state.advantedFilter}>
										burada gelişmiş filtre!
									</div>
									<table className="table ct-table ct-table-style-1">
										<thead className="ct-thead">
											<tr>
												<th scope="col">ID</th>
												<th scope="col" onClick={e => this.changeSortable('status', e)} className="sortable">
													{translate.getText('list.status')}
												</th>
												<th scope="col" onClick={e => this.changeSortable('company', e)} className="sortable">
													{translate.getText('list.company_name')}
												</th>
												{
													this.props.AuthStore.hasAdmin() &&
													<th scope="col" onClick={e => this.changeSortable('distributor', e)} className="sortable">
														{translate.getText('list.distributor')}
													</th>
												}
												<th scope="col" onClick={e => this.changeSortable('dealer', e)} className="sortable">
													{translate.getText('list.dealer')}
												</th>
												<th scope="col">
													{translate.getText('list.product')}
												</th>
												<th scope="col" onClick={e => this.changeSortable('expiration_time', e)} className="sortable">
													{translate.getText('list.expiration_time')}
												</th>
												<th scope="col" className="ct-table-action">
													{translate.getText('list.action')}
												</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.data.map((e, i) =>
													<tr key={i}
														onClick={() => this.changeSelected(e.id)}
														className={`${(selected.indexOf(e.id) !== -1) ? 'selected' : 'non-selected'}`}>
														<th scope="row">{e.id}</th>
														<td>
															<div className={`badge badge-status ${e.status}`}>
																{translate.getText('order_status.' + e.status)}
															</div>
														</td>
														<td className="text-left">
															<span className="green-title">{e.company}</span>
															<p>{e.first_name}</p>
															<p>{e.last_name}</p>
															<p>{e.phone}</p>
															<p>{e.email}</p>
														</td>
														{
															this.props.AuthStore.hasAdmin() &&
															<td>
																<div className={'badge badge-primary'}>
																	{e.distributor.company}
																</div>
															</td>
														}
														<td>
															<div className={'badge badge-primary'}>
																{e.dealer.company}
															</div>
														</td>
														<td>
															<span className="bold-title">{translate.getText('order.license.' + e.product.license)}</span>
															<p>{translate.getText('order.license_text.' + e.product.license)}</p>
														</td>
														<td>
															<span className="bold-title">{(e.expiration_time !== null) ? e.expiration_time : 'N/A'}</span>
															{
																e.expiration_time !== null &&
																<p><Moment fromNow>{e.expiration_time}</Moment></p>
															}
														</td>
														<td className="ct-table-action btn-4">
															<Link className="btn ct-button btn-edit" to={`/order/edit/${e.id}`}>
																<i className="fa fa-pen btn-icon"></i>
																<div className="btn-text">{translate.getText('general.edit')}</div>
															</Link>
															<Link className="btn ct-button btn-info" to={`/order/detail/${e.id}`}>
																<i className="fa fa-info btn-icon"></i>
																<div className="btn-text">{translate.getText('general.detail')}</div>
															</Link>
															<Link className="btn ct-button btn-modify" to={`/order/upgrade/${e.id}`}>
																<i className="fa fa-sync-alt btn-icon"></i>
																<div className="btn-text">{translate.getText('general.upgrade')}</div>
															</Link>
															{
																this.props.AuthStore.hasAdmin() &&
																<Link className="btn ct-button btn-order-login" to={`/order/order_login/${e.id}`}>
																	<i className="fa fa-sign-in-alt btn-icon"></i>
																	<div className="btn-text">{translate.getText('general.order_login')}</div>
																</Link>
															}
														</td>
													</tr>
												)
											}
										</tbody>
									</table>
									<div className="ct-pagination ct-pagination-style-1">
										<div className="pagination-limit">
											<CustomInput id="page-limit"
												value={this.state.limit}
												type="select" onChange={e => this.changePageLimit(e.target.value)}>
												<option value={10}>10</option>
												<option value={20}>20</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</CustomInput>
										</div>
										<div className="pagination-info">
											{(this.state.total === 0) ? translate.getText('general.pagination.nodata') :
												translate.getTextWithReplace('general.pagination.info', this.state.total, ((this.state.page - 1) * this.state.limit), (this.state.page === this.state.maxPage) ? this.state.total : (this.state.page * this.state.limit))}
										</div>
										<div>
											<ul className="pagination">
												<li className={`page-item ${(this.state.page === 1) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage(1)}>{translate.getText('general.first')}</a>
												</li>
												<li className={`page-item ${(this.state.page === 1) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage((this.state.page - 1 < 1) ? 1 : this.state.page - 1)}>{translate.getText('general.prev')}</a>
												</li>
												{
													this.state.pagination.map((e, i) =>
														<li key={i} className={`page-item ${(this.state.page === e) ? 'active' : ''}`}>
															<a className="page-link" onClick={() => this.changePage(e)}>{e}</a>
														</li>
													)
												}
												<li className={`page-item ${(this.state.page === this.state.maxPage) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage((this.state.page + 1 > this.state.maxPage) ? this.state.maxPage : this.state.page + 1)}>{translate.getText('general.next')}</a>
												</li>
												<li className={`page-item ${(this.state.page === this.state.maxPage) ? 'disabled' : ''}`}>
													<a className="page-link" onClick={() => this.changePage(this.state.maxPage)}>{translate.getText('general.last')}</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					isOpen={this.state.updateSelected.modalToggle}
					toggle={() => this.setState({ updateSelected: { ...this.state.updateSelected, modalToggle: !this.state.updateSelected.modalToggle } })}>
					<ModalHeader>{translate.getText('general.multi_update')}</ModalHeader>
					<ModalBody>
						<div className="row ct-content">
							{
								this.props.AuthStore.hasAdmin() &&
								<div className="col-6">
									<FormGroup row>
										<Label sm={4} for="distributor_id">{translate.getText('form.distributor')}</Label>
										<Col sm={8}>
											<AutoComplete
												value={this.state.updateSelected.data.distributor_id.label}
												id="distributor_id"
												name="distributor_id"
												onChange={value => this.updateSelectedchangeKeyValueHandle('distributor_id', value)}
												api={{
													url: '/distributors/paginate',
													param: 'company',
													cons_param: {
														status: 1
													}
												}}
											/>
											{this.updateSelectederrorComp('distributor_id')}
										</Col>
									</FormGroup>
								</div>
							}
							<div className="col-6">
								<FormGroup row>
									<Label sm={4} for="dealer_id">{translate.getText('form.dealer')}</Label>
									<Col sm={8}>
										<AutoComplete
											value={this.state.updateSelected.data.dealer_id.label}
											id="dealer_id"
											name="dealer_id"
											onChange={value => this.updateSelectedchangeKeyValueHandle('dealer_id', value)}
											api={
												this.props.AuthStore.hasAdmin() ? {
													url: '/dealers/paginate',
													param: 'company',
													cons_param: {
														status: 1,
														distributor: this.state.updateSelected.data.distributor_id.value
													}
												} :
													{
														url: '/dealers/paginate',
														param: 'company',
														cons_param: {
															status: 1
														}
													}
											}
										/>
										{this.updateSelectederrorComp('dealer_id')}
									</Col>
								</FormGroup>
							</div>
							<div className="col-12">
								<hr></hr>
								<Label className="ct-form-label"><b>{translate.getText('general.selected_count')}: </b></Label>
								{this.state.selected.length}
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className="ct-form-button-group" style={{ width: '100%' }}>
							<Button onClick={() => this.updateSelectedhandleSubmit()} className="ct-form-button btn-submit btn-sm btn-block">{translate.getText('general.save')}</Button>
						</div>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default withRouter(ListOrder);
