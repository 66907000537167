import React from 'react';
import Navigation from './Components/Navigation/Navigation';
import { BrowserRouter as Router } from 'react-router-dom';

class Master extends React.Component {
	constructor(props) {
		super(props);
		this.handleButton = this.handleButton.bind(this);
	}
	handleButton() {
		this.props.AuthStore.clearLoginProps();
	}
	render() {
		return (
			<Router>
				<div className="master-layout">
					<Navigation />
				</div>
			</Router>
		);
	}
}

export default Master;
